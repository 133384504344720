import { useAppSelector } from 'hooks/reduxHooks';
import { Configuration } from 'modules/GlobalReducer';
import { getConfiguration } from 'modules/GlobalSelectors';
import { getUserInfo } from 'modules/auth/AuthSelectors';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface ConfiguredRouteProps {
  children: ReactNode;
  conditionField: string;
  isToSatisfy: boolean;
}

const ConfiguredRoute: React.FC<ConfiguredRouteProps> = ({
  children,
  conditionField,
  isToSatisfy,
}) => {
  const {
    data: configuration,
    loading: isConfigurationLoading,
  }: Configuration = useAppSelector(getConfiguration);
  const user = useAppSelector(getUserInfo);
  const navigate = useNavigate();

  let condition = false;

  if (configuration) {
    condition = isToSatisfy
      ? configuration[conditionField]
      : !configuration[conditionField];
  }

  const isHidden = isConfigurationLoading || condition;

  const teamQuery = user?.teams?.length
    ? `?team:in=${user.teams.join(',')}`
    : '';
  const newPath = `/experiment${teamQuery}`;

  if (isHidden) {
    navigate(newPath);
    return null;
  }

  return <>{children}</>;
};

export default ConfiguredRoute;
