import { useAppSelector } from 'hooks/reduxHooks';
import { getUserInfo } from 'modules/auth/AuthSelectors';
import { Configuration } from 'modules/GlobalReducer';
import { getConfiguration } from 'modules/GlobalSelectors';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  conditionField: string;
  isToSatisfy: boolean;
}

const ConfiguredItem: React.FC<Props> = ({
  children,
  conditionField,
  isToSatisfy,
}) => {
  const {
    data: configuration,
    loading: isConfigurationLoading,
  }: Configuration = useAppSelector(getConfiguration);
  const user = useAppSelector(getUserInfo);

  let condition = false;
  let isHidden = true;

  if (configuration) {
    condition = isToSatisfy
      ? configuration[conditionField]
      : !configuration[conditionField];
  }

  const isToShowOrgSettingsIcon = conditionField === 'showOrgSettings';

  if (isToShowOrgSettingsIcon) {
    const permission =
      user?.permissions?.includes('admin') ||
      user?.permissions?.includes('owner');
    isHidden = isConfigurationLoading || condition || !permission;
  } else {
    isHidden = isConfigurationLoading || condition;
  }

  if (isHidden) {
    return null;
  }

  return <>{children}</>;
};

export default ConfiguredItem;
