export const mockData = {
  total_count: 10,
  start_row: 0,
  page_size: 10,
  rows: [
    {
      file_id: 39,
      file_name: 'QA_performance_plate_xfers_50.xlsm',
      worksheet_name: 'Day 1 using R2dio (familiar)',
      db_id: 14822,
      name: 'qa 210125 HTS d 9 dest-H9',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H9',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14179,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 39,
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      worksheet_name: 'Day 1 using R2dio (familiar)',
      db_id: 14821,
      name: 'qa 210125 HTS d 9 dest-H8',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H8',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14178,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 39,
      file_name: 'QA_performance_plate_xfers_50.xlsm',
      worksheet_name: 'Day 1 using R2dio (familiar)',
      db_id: 14820,
      name: 'qa 210125 HTS d 9 dest-H7',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H7',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14177,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 39,
      file_name: 'QA_performance_plate_xfers_50.xlsm',
      worksheet_name: 'Day 1 using R2dio (hybrid)',
      db_id: 14819,
      name: 'qa 210125 HTS d 9 dest-H6',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H6',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14176,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 39,
      file_name: 'QA_performance_plate_xfers_50.xlsm',
      worksheet_name: 'Day 1 using R2dio (hybrid)',
      db_id: 14818,
      name: 'qa 210125 HTS d 9 dest-H5',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H5',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14175,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 40,
      file_name: 'QA_performance_plate_xfers_999.xlsm',
      worksheet_name: 'Day 1 using R2dio (familiar)',
      db_id: 14817,
      name: 'qa 210125 HTS d 9 dest-H4',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H4',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14174,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 40,
      file_name: 'QA_performance_plate_xfers_999.xlsm',
      worksheet_name: 'Day 1 using R2dio (hybrid)',
      db_id: 14816,
      name: 'qa 210125 HTS d 9 dest-H3',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H3',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14173,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 40,
      file_name: 'QA_performance_plate_xfers_999.xlsm',
      worksheet_name: 'Day 1 using R2dio (hybrid)',
      db_id: 14815,
      name: 'qa 210125 HTS d 9 dest-H2',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H2',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14172,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 41,
      file_name: 'QA_performance_plate_xfers_888.xlsm',
      worksheet_name: 'Day 1 using R2dio (familiar)',
      db_id: 14814,
      name: 'qa 210125 HTS d 9 dest-H2',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H2',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14172,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
    {
      file_id: 41,
      file_name: 'QA_performance_plate_xfers_888.xlsm',
      worksheet_name: 'Day 1 using R2dio (familiar)',
      db_id: 14813,
      name: 'qa 210125 HTS d 9 dest-H2',
      type: 'Whole Cell Broth',
      code: 'CB',
      maker: 'Tom Treynor (R2TT)',
      made_datetime: '2022-01-25 11:51:18.300000',
      site: 'Berkeley',
      current_file_name: 'QA_performance_plate_xfers_50.xlsm',
      control_type: null,
      description: null,
      parent_name: 'qa 210125 HTS d 9 dest-H2',
      parent_type: 'Fermentation',
      parent_code: 'FMT',
      parent_operator: 'Tom Treynor (R2TT)',
      parent_start_datetime: '2022-01-25 11:51:18.300000',
      parent_end_datetime: null,
      parent_duration__min: null,
      parent_site: 'Berkeley',
      parent_experiment: 'expt1',
      parent_team: 'bob',
      parent_file_name: 'QA_performance_plate_xfers_50.xlsm',
      parent_description: null,
      parent_version: null,
      parent_instrument: null,
      type_id: 26,
      maker_id: 2,
      site_id: 3,
      current_file_id: 39,
      control_type_id: null,
      parent_db_id: 14172,
      parent_type_id: 3,
      parent_operator_id: 2,
      parent_site_id: 3,
      parent_experiment_id: 17,
      parent_team_id: 7,
      parent_file_id: 39,
      parent_instrument_id: null,
      recorded_by_id: 3,
    },
  ],
  lastRow: 10,
  count_only: false,
  shouldUpdateState: true,
};
