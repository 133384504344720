export const FETCH_ORIENTING_TABLE_START = 'FETCH_ORIENTING_TABLE_START';
export const FETCH_ORIENTING_TABLE_SUCCESS = 'FETCH_ORIENTING_TABLE_SUCCESS';
export const FETCH_ORIENTING_TABLE_ERROR = 'FETCH_ORIENTING_TABLE_ERROR';

export const FETCH_RELATED_TABLE_START = 'FETCH_RELATED_TABLE_START';
export const FETCH_RELATED_TABLE_SUCCESS = 'FETCH_RELATED_TABLE_SUCCESS';
export const FETCH_RELATED_TABLE_ERROR = 'FETCH_RELATED_TABLE_ERROR';

export const SET_RELATED_TABLE_SELECTED_NODES =
  'SET_RELATED_TABLE_SELECTED_NODES';
