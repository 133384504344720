import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'sites';

const initialState = {
  sites: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const SitesReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_SITES_START: {
      return R.mergeDeepRight(state, { sites: { loading: true } });
    }
    case actionTypes.FETCH_SITES_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          sites: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        sites: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_SITES_ERROR: {
      return R.mergeDeepRight(state, { sites: { error: payload } });
    }

    case actionTypes.SET_SITES_TABLE_FILTER: {
      return R.mergeRight(state, {
        sites: { ...state.sites, filterModel: payload },
      });
    }
    case actionTypes.SET_SITES_TABLE_SORT: {
      return R.mergeRight(state, {
        sites: { ...state.sites, sortModel: payload },
      });
    }
    case actionTypes.SET_SITES_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        sites: { showCancel: !state.sites.showCancel },
      });
    }

    case actionTypes.SET_SITES_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        sites: { selectedNodes: payload },
      });
    }
    case actionTypes.CLEAR_SITES_TABLE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default SitesReducer;
