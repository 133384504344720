import UndoIcon from '@mui/icons-material/Undo';
import { IconButton, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks/reduxHooks';
import { updateChartSettings } from 'modules/visualize/VisualizeActions';
import {
  DOESettingsType,
  VisualizePanelsTypeEnum,
} from 'modules/visualize/VisualizeReducer';
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import { ModelName } from '../../../../../utils/enum';
import { availableDOEAxisChoices } from '../../../utils';
import AxesSettings from './AxesSettings';
import ChartSettings from './ChartSettings';
import LayoutSettings from './LayoutSettings';
import PointsSettings from './PointsSettings';

interface DOESettingsProps {
  settingsInfo: DOESettingsType;
  initialSettings: DOESettingsType;
  panelType: VisualizePanelsTypeEnum;
  chartId: string;
  modelName: ModelName;
  children?: React.ReactNode;
  showCancel: boolean;
}

export enum CenterLineOptions {
  Mean = 'mean',
  Median = 'median',
  None = 'none',
}

export enum DOESettingsAccordionEnum {
  Chart = 'chart',
  Layout = 'layout',
  Axes = 'axes',
  Points = 'points',
}

export enum SizingOptions {
  Fit = 'Fit to screen',
  Fixed = 'Fixed',
}

const DOEColorByChoices = [
  {
    name: 'pro_type',
    label: 'pro_type',
  },
];

const DOESettings: React.FC<DOESettingsProps> = ({
  settingsInfo,
  initialSettings,
  panelType,
  chartId,
  modelName,
}) => {
  const [expandedAccordion, setExpandedAccordion] =
    useState<DOESettingsAccordionEnum | null>(DOESettingsAccordionEnum.Chart);

  const [shouldUpdateSettings, setShouldUpdateSettings] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const {
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<DOESettingsType>({
    defaultValues: settingsInfo,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const formValues = useWatch({
    control,
  });

  const showResetButton = useMemo(
    () => !R.equals(initialSettings, formValues),
    [initialSettings, formValues],
  );
  const isSettingsWasChanged = useMemo(
    () => !R.equals(settingsInfo, formValues),
    [settingsInfo, formValues],
  );
  useEffect(() => {
    if ((isSettingsWasChanged && isValid) || shouldUpdateSettings) {
      const timeoutId = setTimeout(
        () =>
          dispatch(
            updateChartSettings({
              chartId,
              panelType,
              updatedSettings: formValues,
              modelName,
            }),
          ),
        200,
      );
      return () => clearTimeout(timeoutId);
    }
  }, [
    isSettingsWasChanged,
    chartId,
    dispatch,
    modelName,
    panelType,
    formValues,
    shouldUpdateSettings,
    isValid,
  ]);

  return (
    <div>
      <SectionTitle>
        Settings
        {showResetButton && (
          <IconButton
            disableRipple
            onClick={() => {
              reset(initialSettings);
              setShouldUpdateSettings(true);
            }}
          >
            <UndoIcon />
          </IconButton>
        )}
      </SectionTitle>

      <ChartSettings
        isExpanded={expandedAccordion === DOESettingsAccordionEnum.Chart}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? DOESettingsAccordionEnum.Chart : null,
          );
        }}
        control={control}
        columnChoices={availableDOEAxisChoices}
        setValue={setValue}
        formValues={formValues as DOESettingsType}
      />

      <PointsSettings
        isExpanded={expandedAccordion === DOESettingsAccordionEnum.Points}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? DOESettingsAccordionEnum.Points : null,
          );
        }}
        control={control}
        colorByChoices={DOEColorByChoices}
        labelByChoices={initialSettings.labelByChoices}
        setValue={setValue}
        formValues={formValues as DOESettingsType}
      />

      <AxesSettings
        isExpanded={expandedAccordion === DOESettingsAccordionEnum.Axes}
        onChange={(e, expanded) => {
          setExpandedAccordion(expanded ? DOESettingsAccordionEnum.Axes : null);
        }}
        register={register}
        errors={errors}
      />

      <LayoutSettings
        isExpanded={expandedAccordion === DOESettingsAccordionEnum.Layout}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? DOESettingsAccordionEnum.Layout : null,
          );
        }}
        setValue={setValue}
        register={register}
        errors={errors}
        initialSettings={initialSettings}
        formValues={formValues as DOESettingsType}
        trigger={trigger}
      />
    </div>
  );
};

const SectionTitle = styled(Typography)`
  font-size: 16px;
  color: ${colors.primary};
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    padding: 0;
  }
`;

export default DOESettings;
