import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'experiments';

const initialState = {
  experiments: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
    isFilterInitialized: false,
  },
};

const ExperimentsReducer = (
  state = initialState,
  action: { type: string; payload: Record<string, unknown> },
) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_EXPERIMENTS_START: {
      return R.mergeDeepRight(state, { experiments: { loading: true } });
    }
    case actionTypes.FETCH_EXPERIMENTS_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          experiments: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        experiments: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_EXPERIMENTS_ERROR: {
      return R.mergeDeepRight(state, { experiments: { error: payload } });
    }

    case actionTypes.SET_EXPERIMENTS_TABLE_FILTER: {
      return R.mergeRight(state, {
        experiments: { ...state.experiments, filterModel: payload },
      });
    }
    case actionTypes.SET_EXPERIMENTS_TABLE_SORT: {
      return R.mergeRight(state, {
        experiments: { ...state.experiments, sortModel: payload },
      });
    }
    case actionTypes.SET_EXPERIMENTS_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        experiments: { showCancel: !state.experiments.showCancel },
      });
    }
    case actionTypes.SET_EXPERIMENTS_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        experiments: { selectedNodes: payload },
      });
    }
    case actionTypes.SET_EXPERIMENTS_IS_FILTER_INITIALIZED: {
      return R.mergeDeepRight(state, {
        experiments: { isFilterInitialized: payload },
      });
    }
    case actionTypes.CLEAR_EXPERIMENTS_TABLE_FILTER: {
      return R.mergeRight(initialState, {
        experiments: { ...initialState.experiments, filterModel: payload },
      });
    }
    default:
      return state;
  }
};

export default ExperimentsReducer;
