import colors from 'theme/patterns/colors';
import { LegendValueEnum } from '../models/scatterChartModels';

export const SCATTER_CHART_MIN_WIDTH = 400;
export const SCATTER_CHART_MIN_HEIGHT = 300;

export const SCATTER_LEGEND_ITEMS = [
  {
    value: LegendValueEnum.Measurement,
    type: 'circle',
    id: LegendValueEnum.Measurement,
    color: colors.primary,
  },
  {
    value: LegendValueEnum.UCL,
    type: 'line',
    id: LegendValueEnum.UCL,
    color: colors.red,
  },
  {
    value: LegendValueEnum.MEAN,
    type: 'line',
    id: LegendValueEnum.MEAN,
    color: colors.green,
  },
  {
    value: LegendValueEnum.LCL,
    type: 'line',
    id: LegendValueEnum.LCL,
    color: colors.red,
  },
  {
    value: LegendValueEnum.Outlier,
    type: 'circle',
    id: LegendValueEnum.Outlier,
    color: colors.red,
  },
];

export enum ScatterGroupsEnum {
  Zone9 = 'Zone9',
  BeyondLimits = 'Beyond limits',
  Default = 'Measurement',
}
