import loadable from '@loadable/component';
import { useAppSelector } from 'hooks/reduxHooks';

import { getModalEventData } from 'modules/GlobalSelectors';
import { EventName } from 'utils/enum';

const Cancel = loadable(() => import('modules/cancel/Cancel'));
const Reactivate = loadable(() => import('modules/cancel/Reactivate'));
const DownloadOriginalFile = loadable(
  () => import('modules/originalFile/DownloadOriginalFile'),
);
const DownloadCurrentFile = loadable(
  () => import('modules/originalFile/DownloadCurrentFile'),
);
const PreviewFile = loadable(() => import('modules/originalFile/PreviewFile'));

const ModalEventContainer = () => {
  const { eventName } = useAppSelector(getModalEventData);

  switch (eventName) {
    case EventName.Inactivate: {
      return <Cancel />;
    }
    case EventName.Reactivate: {
      return <Reactivate />;
    }
    case EventName.DownloadOriginalFile: {
      return <DownloadOriginalFile />;
    }
    case EventName.DownloadCurrentFile: {
      return <DownloadCurrentFile />;
    }
    case EventName.PreviewFile: {
      return <PreviewFile />;
    }

    default:
      return null;
  }
};

export default ModalEventContainer;
