import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { SelectWithModelChoices } from 'components/FormFields';
import { ScatterSettingsType } from 'modules/visualize/VisualizeReducer';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import { CenterLineOptions } from '.';
import { ScatterChartAxesBasesType } from '../models/scatterChartModels';
import Accordion from './Accordion';

interface ChartSettingsProps {
  isExpanded: boolean;
  control: Control<ScatterSettingsType>;
  xAxisChoices: ScatterChartAxesBasesType['xAxis'];
  yAxisChoices: ScatterChartAxesBasesType['yAxis'];
  setValue: UseFormSetValue<ScatterSettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  formValues: Partial<ScatterSettingsType>;
  children?: React.ReactNode;
}

const ChartSettings: React.FC<ChartSettingsProps> = ({
  isExpanded,
  control,
  xAxisChoices,
  yAxisChoices,
  formValues,
  onChange,
  setValue,
}) => {
  return (
    <Accordion
      panelSummary="Chart"
      panelDetails={
        <div>
          <AxisOptionsWrapper>
            <SelectWithModelChoices
              choices={xAxisChoices}
              fieldName="xAxis"
              label="X-axis"
              control={control}
              withoutIcon
              disableClearable
            />
            <SelectWithModelChoices
              choices={yAxisChoices}
              fieldName="yAxis"
              label="Y-axis"
              control={control}
              withoutIcon
              disableClearable
            />
          </AxisOptionsWrapper>
          <div>
            <RadioGroupWrapper>
              <FormControl>
                <CustomFormLabel>Center Line</CustomFormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={CenterLineOptions.Mean}
                  row
                  value={formValues.centerLine}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, value) =>
                    setValue('centerLine', value as CenterLineOptions, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                >
                  <CustomFormControlLabel
                    value={CenterLineOptions.Mean}
                    control={<Radio />}
                    label={CenterLineOptions.Mean}
                  />
                  <CustomFormControlLabel
                    value={CenterLineOptions.Median}
                    control={<Radio />}
                    label={CenterLineOptions.Median}
                  />
                  <CustomFormControlLabel
                    value={CenterLineOptions.None}
                    control={<Radio />}
                    label={CenterLineOptions.None}
                  />
                </RadioGroup>
              </FormControl>
            </RadioGroupWrapper>
          </div>
          <SwitchOptionsWrapper>
            <FormGroup>
              <CustomFormControlLabel
                $withGap
                disabled={true}
                control={<Switch size="small" />}
                label="Proportional Spacing"
                value={formValues.proportionalSpacing}
                checked={formValues.proportionalSpacing}
                onChange={(event, value) => {
                  setValue('proportionalSpacing', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
              <CustomFormControlLabel
                $withGap
                control={<Switch size="small" />}
                label="Show Control Limits"
                onChange={(event, checked) =>
                  setValue('showControlLimits', checked, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                checked={formValues.showControlLimits}
                value={formValues.showControlLimits}
              />
              <CustomFormControlLabel
                $withGap
                control={<Switch size="small" />}
                label="HIghlight Outliers"
                onChange={(event, checked) =>
                  setValue('highlightOutliers', checked, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                value={formValues.highlightOutliers}
                checked={formValues.highlightOutliers}
              />
              <CustomFormControlLabel
                $withGap
                control={<Switch size="small" />}
                label="Highlight Trends"
                onChange={(event, checked) =>
                  setValue('highlightTrends', checked, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                value={formValues.highlightTrends}
                checked={formValues.highlightTrends}
              />
            </FormGroup>
          </SwitchOptionsWrapper>
        </div>
      }
      expanded={isExpanded}
      onChange={onChange}
    />
  );
};

const AxisOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & div:first-child {
    margin-right: 5px;
  }

  .MuiFilledInput-root {
    padding-top: 12px;
    padding-right: 25px !important;
  }

  .MuiInputLabel-root {
    top: -2px;
  }

  .MuiFilledInput-input {
    padding-bottom: 4px !important;
  }
`;

const CustomFormLabel = styled(FormLabel)`
  font-size: 12px;
  color: ${colors.black};
  font-weight: 500;
`;

const SwitchOptionsWrapper = styled.div`
  margin: 10px 0;

  .MuiFormGroup-root {
    padding-left: 4px;
  }
`;

const RadioGroupWrapper = styled.div`
  .MuiFormGroup-root {
    padding-left: 4px;
    flex-wrap: nowrap;
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)<{ $withGap?: boolean }>`
  margin-right: 15px;
  margin-top: ${({ $withGap }) => ($withGap ? '3px' : 0)};
  margin-bottom: ${({ $withGap }) => ($withGap ? '3px' : 0)};

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiRadio-root:hover {
    padding: 5px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

export default ChartSettings;
