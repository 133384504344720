import { IconProps } from './types';

const MpmChildIcon = ({
  color = '#000',
  size = { width: 24, height: 24 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons/ic/mpm-child</title>
    <g
      id="icons/ic/mpm-child"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.87"
    >
      <path
        d="M12,6 C14.2208633,6 16.159907,7.20661355 17.1973296,9.00003939 L17.1973296,9.00003939 L15.7966578,8.99993372 C14.9104285,7.87991159 13.5391357,7.16129032 12,7.16129032 C9.32765444,7.16129032 7.16129032,9.32765444 7.16129032,12 C7.16129032,14.6723456 9.32765444,16.8387097 12,16.8387097 C13.5386786,16.8387097 14.9096139,16.1205152 15.7958681,15.0010641 L15.7958681,15.0010641 L17.1967496,15.000963 C16.1591844,16.793836 14.2204495,18 12,18 C8.6862915,18 6,15.3137085 6,12 C6,8.6862915 8.6862915,6 12,6 Z M20,8 L24,12 L20,16 L20,13 L10,13 L10,11 L20,11 L20,8 Z"
        id="Combined-Shape"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default MpmChildIcon;
