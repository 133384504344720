import { REHYDRATE } from 'redux-persist';
import { all, fork, put, take, takeLatest } from 'redux-saga/effects';

import { appBootstrap } from './app.actions';

function* appBootstrapSaga() {
  yield put(appBootstrap());
}

function* appAuthenticatedSaga() {
  while (true) {
    yield take([REHYDRATE]);

    // Authentication handler
    // yield put(appAuthenticated());
  }
}

function* appSagas() {
  yield all([
    takeLatest(REHYDRATE, appBootstrapSaga),
    fork(appAuthenticatedSaga),
  ]);
}

export default appSagas;
