import { IconProps } from './types';

const MeasurementTypesIcon = ({
  color = '#000',
  size = { width: 22, height: 23 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 22 23"
    version="1.1"
  >
    <g
      id="Main"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.87"
    >
      <g id="Nav" transform="translate(-339.000000, -504.000000)" fill={color}>
        <g
          id="Navs/Main-Expanded-Copy"
          transform="translate(323.000000, 66.000000)"
        >
          <g id="Group-3">
            <g id="Group" transform="translate(0.000000, 8.000000)">
              <g
                id="Lists/Default/Default/★-+-Text-Copy-11"
                transform="translate(0.000000, 417.500000)"
              >
                <g
                  id="icons/ic/grade-default"
                  transform="translate(16.000000, 13.000000)"
                >
                  <path
                    d="M22,2 L22,16 C22,19.2383969 19.4344251,21.8775718 16.2249383,21.9958615 L16,22 L3,22 L3,20 L16,20 C18.1421954,20 19.8910789,18.3160315 19.9951047,16.1996403 L20,16 L20,2 L22,2 Z M15,0 C16.6568542,0 18,1.34314575 18,3 L18,3 L18,15 C18,16.6568542 16.6568542,18 15,18 L15,18 L3,18 C1.34314575,18 0,16.6568542 0,15 L0,15 L0,3 C0,1.34314575 1.34314575,0 3,0 L3,0 Z M15,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,3 L2,15 C2,15.5522847 2.44771525,16 3,16 L3,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,15 L16,3 C16,2.44771525 15.5522847,2 15,2 L15,2 Z M14,12 L14,14 L12,14 L12,12 L14,12 Z M10,8 L10,10 L8,10 L8,8 L10,8 Z M6,8 L6,10 L4,10 L4,8 L6,8 Z M14,8 L14,10 L12,10 L12,8 L14,8 Z"
                    id="measurement-types"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MeasurementTypesIcon;
