import storage from 'redux-persist/lib/storage';

const ReduxPersistConfig = {
  active: true,
  reducerVersion: '1',
  storeConfig: {
    storage,
    key: 'primary',
    whitelist: ['auth'],
  },
};

export default ReduxPersistConfig;
