import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { DOESettingsType } from 'modules/visualize/VisualizeReducer';
import React from 'react';
import {
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import {
  chartNumericFieldValidator,
  chartTitleValidator,
} from 'utils/fieldValidators';
import { SizingOptions } from '.';
import Accordion from './Accordion';

interface LayoutSettingsProps {
  isExpanded: boolean;
  register: UseFormRegister<DOESettingsType>;
  setValue: UseFormSetValue<DOESettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  trigger: UseFormTrigger<DOESettingsType>;
  initialSettings: DOESettingsType;
  errors: Partial<FieldErrorsImpl<DOESettingsType>>;
  formValues: Partial<DOESettingsType>;
  children?: React.ReactNode;
}

const LayoutSettings: React.FC<LayoutSettingsProps> = ({
  onChange,
  setValue,
  register,
  trigger,
  isExpanded,
  errors,
  formValues,
  initialSettings,
}) => {
  const isDimensionsDisabled = formValues.sizingType === SizingOptions.Fit;

  const showErrorButton =
    (errors.chartTitle || errors.chartWidth || errors.chartHeight) &&
    !isExpanded;

  return (
    <Accordion
      panelSummary={
        <PanelSummary>
          Layout & Title
          {showErrorButton ? <ErrorOutlineIcon color="error" /> : null}
        </PanelSummary>
      }
      panelDetails={
        <div>
          <div>
            <CustomTextField
              id="chartTitle"
              variant="filled"
              margin="dense"
              label="Title"
              fullWidth
              helperText={errors.chartTitle && errors.chartTitle.message}
              error={!!errors.chartTitle}
              {...register('chartTitle', {
                ...chartTitleValidator(),
                setValueAs: (v) => v.trim(),
              })}
            />

            <SwitchOptionsWrapper>
              <FormGroup>
                <CustomFormControlLabel
                  control={<Switch size="small" />}
                  label="Show title"
                  onChange={(event, checked) => setValue('showTitle', checked)}
                  checked={formValues.showTitle}
                />
              </FormGroup>
            </SwitchOptionsWrapper>

            <RadioGroupWrapper
              style={{
                marginTop: 15,
              }}
            >
              <FormControl>
                <CustomFormLabel>Sizing</CustomFormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={SizingOptions.Fit}
                  row
                  onChange={(e, value) => {
                    const isFitToScreenSize = value === SizingOptions.Fit;

                    if (errors.chartWidth && isFitToScreenSize) {
                      setValue('chartWidth', initialSettings.chartWidth);
                      trigger('chartWidth');
                    }

                    if (errors.chartHeight && isFitToScreenSize) {
                      setValue('chartHeight', initialSettings.chartHeight);
                      trigger('chartHeight');
                    }

                    setValue('sizingType', value as SizingOptions);
                  }}
                  value={formValues.sizingType}
                >
                  <CustomFormControlLabel
                    value={SizingOptions.Fit}
                    control={<Radio />}
                    label={SizingOptions.Fit}
                  />
                  <CustomFormControlLabel
                    value={SizingOptions.Fixed}
                    control={<Radio />}
                    label={SizingOptions.Fixed}
                  />
                </RadioGroup>
              </FormControl>
            </RadioGroupWrapper>
            <SizeInputsWrapper>
              <CustomTextField
                id="chartWidth"
                variant="filled"
                margin="dense"
                label="Width (px)"
                fullWidth
                style={{
                  marginRight: 10,
                }}
                disabled={isDimensionsDisabled}
                error={!!errors.chartWidth?.message}
                helperText={errors.chartWidth?.message}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('chartWidth', chartNumericFieldValidator())}
              />
              <CustomTextField
                id="chartHeight"
                variant="filled"
                margin="dense"
                label="Height (px)"
                fullWidth
                disabled={isDimensionsDisabled}
                error={!!errors.chartHeight?.message}
                helperText={errors.chartHeight?.message}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('chartHeight', chartNumericFieldValidator())}
              />
            </SizeInputsWrapper>
          </div>
        </div>
      }
      expanded={isExpanded}
      onChange={onChange}
    />
  );
};

const SizeInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const CustomTextField = styled(TextField)`
  margin: 0;

  .MuiFormLabel-root {
    top: -2px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 6px;
  }

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0;
    margin-right: 0;
  }
`;
const CustomFormLabel = styled(FormLabel)`
  font-size: 12px;
  color: ${colors.black};
  font-weight: 500;
`;

const SwitchOptionsWrapper = styled.div`
  margin: 10px 0;

  .MuiFormGroup-root {
    padding-left: 4px;
  }
`;

const RadioGroupWrapper = styled.div`
  .MuiFormGroup-root {
    padding-left: 4px;
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)<{ $withGap?: boolean }>`
  margin-right: 15px;
  margin-top: ${({ $withGap }) => ($withGap ? '3px' : 0)};
  margin-bottom: ${({ $withGap }) => ($withGap ? '3px' : 0)};

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiRadio-root:hover {
    padding: 5px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

const PanelSummary = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & svg {
    margin: 0 8px;
  }
`;

export default LayoutSettings;
