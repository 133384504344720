import { IconProps } from './types';

const ScatterChartForMatrix = ({
  color = '#000',
  size = { width: 49, height: 47 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 49 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: '7px', marginBottom: '7px' }}
  >
    <line x1="1" y1="-1" x2="0.999998" y2="47" stroke={color} strokeWidth="2" />
    <line x1="48" y1="46" y2="46" stroke={color} strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 22C48 20.895 47.105 20 46 20C44.895 20 44 20.895 44 22C44 23.105 44.895 24 46 24C47.105 24 48 23.105 48 22Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14C20 12.895 19.105 12 18 12C16.895 12 16 12.895 16 14C16 15.105 16.895 16 18 16C19.105 16 20 15.105 20 14Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41 10C41 8.895 40.105 8 39 8C37.895 8 37 8.895 37 10C37 11.105 37.895 12 39 12C40.105 12 41 11.105 41 10Z"
      fill={color}
    />
    <path
      d="M3.29536 42.3925L48.3558 9.40344"
      stroke={color}
      strokeWidth="2"
      strokeDasharray="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 28C13 26.895 12.105 26 11 26C9.895 26 9 26.895 9 28C9 29.105 9.895 30 11 30C12.105 30 13 29.105 13 28Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 29C34 27.895 33.105 27 32 27C30.895 27 30 27.895 30 29C30 30.105 30.895 31 32 31C33.105 31 34 30.105 34 29Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 34C7 32.895 6.105 32 5 32C3.895 32 3 32.895 3 34C3 35.105 3.895 36 5 36C6.105 36 7 35.105 7 34Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 37C27 35.895 26.105 35 25 35C23.895 35 23 35.895 23 37C23 38.105 23.895 39 25 39C26.105 39 27 38.105 27 37Z"
      fill={color}
    />
  </svg>
);

export default ScatterChartForMatrix;
