import {
  BubbleDataPoint,
  Chart,
  ChartData,
  ChartOptions,
  ChartTypeRegistry,
  LegendItem,
  Point,
} from 'chart.js';
import colors from 'theme/patterns/colors';
import { availableDOEAxisChoices } from '../../utils';
import { CenterLineOptions } from './DOESettings';
import { DOE_CHART_AXES_COLORS } from './constants/DOEChartConstants';
import {
  AxisGroupInterface,
  DOEChartDataGroupsResult,
  XAxisType,
} from './model/DOEChart.interfaces';
import { DOEChartDot, DOEChartResponse } from './model/DOEChartResponse';
import { LegendValueEnum } from './model/DOELegendModels';

type Datasets = ChartData<
  'scatter',
  (number | Point | null)[],
  unknown
>['datasets'];

export type GetDOEChartDataArgs = {
  initialData: DOEChartResponse['dots'];
  settings: {
    chartId?: string;
    chartPadding?: {
      left: number;
      right: number;
      bottom: number;
      top: number;
    };
    showTitle: boolean;
    showLegend: boolean;
    showGroupLegend: boolean;
    showMeanDiamonds: boolean;
    xAxis: XAxisType[];
    yMinAxis: number;
    yMaxAxis: number;
    centerLineAll: CenterLineOptions;
    labelBy: { name: string; label: string }[];
    centerLineGroups: CenterLineOptions;
  };
  mainGroup?: Record<string, DOEChartDot[]> | null;
  axesGroups?: AxisGroupInterface[];
};

export type InitialData = {
  data: {
    mat_name: string;
    pro_operators: {
      pro_operator: string;
      pro_names: {
        pro_name: string;
        y: number[];
        diamond: {
          average: number;
          averageDelta: number;
        };
      }[];
    }[];
  }[];
  mat_name_line: number;
  pro_operator_line: number;
  mean: number;
};

export const getChartLinesDataset = (initialData: InitialData) => {
  const res = initialData.data.reduce(
    (acc, val) => {
      const proOperators = val.pro_operators;

      proOperators.forEach((proOperator) => {
        const proNames = proOperator.pro_names;

        proNames.forEach((proName) => {
          const name = `${val.mat_name} ${proOperator.pro_operator} ${proName.pro_name}`;
          proName.y.forEach((y) => {
            const obj = {
              y,
            };
            if (name in acc) {
              acc = { ...acc, [name]: [...acc[name], obj] };
            } else {
              acc = { ...acc, [name]: [obj] };
            }
          });
        });
      });
      return acc;
    },
    {} as Record<string, { y: number }[]>,
  );
  return res;
};

export const getChartDiamondsDataset = (initialData: InitialData) => {
  const res = initialData.data.reduce(
    (acc, val) => {
      const proOperators = val.pro_operators;

      proOperators.forEach((proOperator) => {
        const proNames = proOperator.pro_names;

        proNames.forEach((proName) => {
          const name = `${val.mat_name} ${proOperator.pro_operator} ${proName.pro_name}`;
          const { average, averageDelta } = proName.diamond;
          const coords = [
            {
              y: average - averageDelta,
              x: 0,
            },
            {
              y: average,
              x: -0.25,
            },
            {
              y: average,
              x: 0.25,
            },
            {
              y: average + averageDelta,
              x: 0,
            },
            {
              y: average,
              x: -0.25,
            },
            {
              y: average,
              x: 0.25,
            },
            {
              y: average - averageDelta,
              x: 0,
            },
          ];
          coords.forEach((y) => {
            const obj = {
              y: y.y,
              x: y.x,
            };
            if (name in acc) {
              acc = { ...acc, [name]: [...acc[name], obj] };
            } else {
              acc = { ...acc, [name]: [obj] };
            }
          });
        });
      });
      return acc;
    },
    {} as Record<string, { x: number; y: number }[]>,
  );
  return res;
};

export const getDOEChartDatasetInfo = ({
  initialData,
  settings,
  mainGroup,
  axesGroups,
}: GetDOEChartDataArgs) => {
  const datasets: ChartData<'scatter', (number | Point | null)[], unknown> = {
    datasets: [],
  };

  if (!initialData || initialData.length === 0) {
    return datasets;
  }

  const showCenterLineAll = settings.centerLineAll !== CenterLineOptions.None;
  const showCenterLineGroups =
    settings.centerLineGroups !== CenterLineOptions.None;

  if (showCenterLineAll && mainGroup) {
    const allValuesData: number[] = [];

    for (const key of Object.keys(mainGroup ?? {})) {
      allValuesData.push(...(mainGroup ?? {})[key].map((x) => x.value));
    }
    const isCenterLineAllMean =
      settings.centerLineAll === CenterLineOptions.Mean;

    const yAxisValue = isCenterLineAllMean
      ? calculateMeanValue(allValuesData)
      : calculateMedianValue(allValuesData);

    datasets.datasets.push({
      label: isCenterLineAllMean ? 'Mean' : 'Median',
      data: [...allValuesData].map((y, index) => ({
        y: yAxisValue,
        x: index === 0 ? -99999999 : 99999999,
      })),
      showLine: true,
      borderColor: colors.green,
      borderWidth: 1,
      backgroundColor: '#455a64',
    });
  }

  datasets.datasets.push(
    ...(mainGroup
      ? Object.keys(mainGroup).map((key, index) => {
          const currentData = mainGroup[key];

          return {
            label: key,
            data: currentData.map((data) => ({
              ...data,
              y: data.value,
              x: index + 0.5,
              id: data.db_id,
            })),
            showLine: true,
            borderColor: '#87949b',
            borderWidth: 1,
            backgroundColor: '#455a64',
            radius: 3,
          } as Datasets[0];
        })
      : []),
  );

  if (showCenterLineGroups && axesGroups?.length) {
    const isCenterLineGroupsMean =
      settings.centerLineGroups === CenterLineOptions.Mean;

    const axesGroupsToProcess = axesGroups;

    for (const { groups, axisName } of axesGroupsToProcess) {
      let start = 0;
      for (const group of groups) {
        const allValuesDataGroup = group.data.map((x) => x.value);
        const yAxisValue = isCenterLineGroupsMean
          ? calculateMeanValue(allValuesDataGroup)
          : calculateMedianValue(allValuesDataGroup);

        datasets.datasets.push({
          label: axisName,
          data: [
            {
              y: yAxisValue,
              x: start,
            },
            {
              y: yAxisValue,
              x: group.count + start,
            },
          ],
          showLine: true,
          borderColor:
            DOE_CHART_AXES_COLORS.find((x) => x.axisName === axisName)?.color ??
            colors.red,
          borderWidth: 1.5,
          pointRadius: 0,
        });

        start += group.count;
      }
    }
  }

  return datasets;
};

export const getDOEChartDataGroups = (
  dots: DOEChartDot[],
  settings: GetDOEChartDataArgs['settings'],
) => {
  const result: DOEChartDataGroupsResult = {
    mainGroup: null,
    allGroups: null,
    axesGroups: [],
  };

  if (!dots || !dots.length) {
    return result;
  }

  const xAxes = settings.xAxis.map((x) => x.name);

  const filteredDots = dots
    .filter((d) => {
      const isValidYMinRange =
        settings.yMinAxis !== null ? d.value >= +settings.yMinAxis : true;
      const isValidYMaxRange =
        settings.yMaxAxis !== null ? d.value <= +settings.yMaxAxis : true;

      return isValidYMinRange && isValidYMaxRange;
    })
    .sort(
      (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
    );

  if (filteredDots.length === 0) {
    return result;
  }

  const selectedKeys = xAxes;

  const cumulativeKeys: (keyof DOEChartDot)[][] = [];

  for (let i = 0; i < selectedKeys.length; i++) {
    const lastCumulativeKey = cumulativeKeys[i - 1] ?? [];
    cumulativeKeys.push([...lastCumulativeKey, selectedKeys[i]]);
  }

  const lastCumulativeKey = cumulativeKeys.at(-1) ?? [];

  const mainGroupObj: Record<string, DOEChartDot[]> = {};
  const allGroupsObj: Record<string, Record<string, DOEChartDot[]>> = {};
  let axesGroups: AxisGroupInterface[] = [];

  for (let i = 0; i < filteredDots.length; i++) {
    const currentData = filteredDots[i];

    // mainGroup //
    const uniqueKeyForValue = lastCumulativeKey.reduce(
      (acc, key, i) => acc + (i === 0 ? '' : '//+//') + `${currentData[key]}`,
      '',
    );

    const isExistsInMainGroup = mainGroupObj[uniqueKeyForValue];

    if (isExistsInMainGroup) {
      mainGroupObj[uniqueKeyForValue] = [
        ...mainGroupObj[uniqueKeyForValue],
        currentData,
      ];
    } else {
      mainGroupObj[uniqueKeyForValue] = [currentData];
    }

    result.mainGroup = mainGroupObj;

    // allGroups //
    for (let i_c = 0; i_c < cumulativeKeys.length; i_c++) {
      const currentGroupOfCumulativeKey = cumulativeKeys[i_c];

      const currentGroupOfCumulativeKeyJoin =
        currentGroupOfCumulativeKey.reduce(
          (acc, key, i_r) =>
            acc +
            (i_r === 0 ? '' : '//+//') +
            `${currentGroupOfCumulativeKey[i_r]}`,
          '',
        );

      const uniqueKeyForValue = currentGroupOfCumulativeKey.reduce(
        (acc, key, i) => acc + (i === 0 ? '' : '//+//') + `${currentData[key]}`,
        '',
      );

      const isExistsInGroup =
        allGroupsObj[currentGroupOfCumulativeKeyJoin]?.[
          uniqueKeyForValue as keyof (typeof allGroupsObj)[string]
        ];

      if (isExistsInGroup) {
        // @ts-ignore
        allGroupsObj[currentGroupOfCumulativeKeyJoin][uniqueKeyForValue] = [
          // @ts-ignore
          ...allGroupsObj[currentGroupOfCumulativeKeyJoin][uniqueKeyForValue],
          currentData,
        ];
      } else {
        allGroupsObj[currentGroupOfCumulativeKeyJoin] = {
          ...allGroupsObj[currentGroupOfCumulativeKeyJoin],
          [uniqueKeyForValue]: [currentData],
        };
      }
    }
  }
  const groups = {} as any;

  const values = Object.keys(mainGroupObj).map((x) => x.split('//+//'));

  for (const [index, key] of xAxes.entries()) {
    groups[key] = [];

    for (const [indexV, value] of values.entries()) {
      const currentData = mainGroupObj[value.join('//+//')];

      // index > 0
      if (indexV === 0) {
        const valuesNameCurrent = value.slice(0, index + 1).join('//+//');
        groups[key].push({
          label: valuesNameCurrent,
          count: 1,
          data: [...currentData],
        });
      } else {
        // indexV > 0
        const valuesNameCurrent = value.slice(0, index + 1).join('//+//');

        const valuesNamePrevious = groups[key].at(-1);

        if (valuesNamePrevious?.label === valuesNameCurrent) {
          groups[key].at(-1).count += 1;
          groups[key].at(-1).data.push(...currentData);
        } else {
          groups[key].push({
            label: valuesNameCurrent,
            count: 1,
            data: [...currentData],
          });
        }
      }
    }
  }

  axesGroups = Object.keys(groups).map((key) => ({
    axisName: key,
    groups: groups[key].map((x: any) => ({
      groupName: x.label.split('//+//').at(-1),
      fullName: x.label,
      count: x.count,
      data: x.data,
    })),
  }));

  axesGroups.reverse();

  result.axesGroups = axesGroups;

  return result;
};

export const getDOEChartOptions = ({
  groupCount,
  settings: {
    showTitle,
    chartPadding,
    showLegend,
    chartId,
    yMaxAxis,
    yMinAxis,
    labelBy,
  },
  xGridLinesCount,
}: {
  groupCount: number;
  settings: GetDOEChartDataArgs['settings'];
  xGridLinesCount: number;
}) => {
  const options: ChartOptions = {
    scales: {
      y: {
        min: +yMinAxis,
        max: +yMaxAxis,
        ticks: {
          padding: 10,
          count: 10,
          includeBounds: true,
          autoSkip: false,
          callback(tickValue, index) {
            const value = (+tickValue).toFixed(2);
            if (index % 2 !== 0) {
              return '';
            }
            if (value === '0.0') {
              return '0';
            }
            if (value === '1.0') {
              return '1';
            }
            return value;
          },
        },
        grid: {
          tickWidth: 1,
          color: 'white',
          tickColor: '#e0e0e0',
        },
        title: {
          display: true,
          text: 'value',
          color: 'black',
          font: {
            weight: 600,
            size: 16,
          },
        },
      },
      y1: {
        grid: {
          drawTicks: false,
          display: false,
        },
        ticks: {
          display: false,
        },
        position: 'right',
      },
      x: {
        grid: {
          display: true,
          drawTicks: false,
          tickWidth: 0,
          lineWidth(ctx) {
            if (ctx.index === 0 || ctx.index === xGridLinesCount - 1) {
              return 0;
            }

            return 1;
          },
        },
        ticks: {
          display: false,
          count: xGridLinesCount,
          maxTicksLimit: xGridLinesCount,
          includeBounds: false,
        },
        max: groupCount,
        min: 0,
      },
      x1: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        position: 'top',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: chartPadding,
    },
    plugins: {
      ...{
        htmlLegend: {
          containerID: chartId,
          showTitle,
          showLegend,
        } as any,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: Boolean(labelBy.length),
        backgroundColor: colors.white,
        bodyColor: colors.black,
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8,
        },
        boxPadding: 4,
        borderColor: colors.grey,
        borderWidth: 1,
        filter(e, index, array, data) {
          return !(data.datasets[e.datasetIndex] as any).tooltipHidden;
        },
        callbacks: {
          label: (e) => {
            if (e.dataset?.label) {
              const rawInfo = e.raw as Record<string, any>;

              const label = labelBy.reduce((acc, val, index) => {
                if (index === 0) {
                  acc = ` ${rawInfo[val.name]}`;
                } else {
                  acc = `${acc} | ${rawInfo[val.name]}`;
                }

                return acc;
              }, '');

              return label;
            }

            return '';
          },
        },
      },
    },
  };

  return options;
};

export const getDOEChartData = ({
  initialData,
  settings,
}: GetDOEChartDataArgs) => {
  const { mainGroup, axesGroups } = getDOEChartDataGroups(
    initialData,
    settings,
  );

  const xGridLinesCount = (axesGroups[0]?.groups?.length ?? 0) + 1;

  const datasets = getDOEChartDatasetInfo({
    initialData,
    settings,
    mainGroup,
    axesGroups,
  });
  const options = getDOEChartOptions({
    groupCount: mainGroup ? Object.keys(mainGroup).length : 1,
    settings,
    xGridLinesCount: xGridLinesCount,
  });

  return {
    datasets,
    options,
    axesGroups,
  };
};

const getOrCreateLegendList = (id: string, showTitle: boolean) => {
  const legendContainer = document.getElementById(id)!;

  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.backgroundColor = colors.white;
    listContainer.style.marginTop = showTitle ? '60px' : '40px';
    listContainer.style.padding = '0';
    listContainer.style.minWidth = '140px';
    listContainer.style.height = 'fit-content';

    legendContainer.appendChild(listContainer);
  } else {
    listContainer.style.marginTop = showTitle ? '60px' : '40px';
  }

  return listContainer;
};

const createLiElement = (
  item: LegendItem,
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | Point | [number, number] | BubbleDataPoint | null)[],
    unknown
  >,
  color?: string,
  itemsContainsGroupNames?: LegendItem[],
) => {
  const li = document.createElement('li');
  li.style.cssText = `
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  `;

  li.onclick = () => {
    if (itemsContainsGroupNames) {
      const currentDatasets = itemsContainsGroupNames.filter(
        (x) => x.text === item.text,
      );

      for (const currentDataset of currentDatasets) {
        chart.setDatasetVisibility(
          currentDataset.datasetIndex!,
          !chart.isDatasetVisible(currentDataset.datasetIndex!),
        );
      }
      chart.update();
    } else {
      chart.setDatasetVisibility(
        item.datasetIndex!,
        !chart.isDatasetVisible(item.datasetIndex!),
      );
      chart.update();
    }
  };

  const boxSpan = document.createElement('span');

  boxSpan.style.cssText = `
    background: ${
      item.text === LegendValueEnum.MEAN || item.text === LegendValueEnum.MEDIAN
        ? colors.green
        : color
    };
    display: inline-block;
    height: 2px;
    margin-right: 10px;
    width: 26px;
  `;

  const textContainer = document.createElement('p');

  textContainer.style.cssText = `
    color: #595959;
    margin: 0;
    padding: 6px 0;
    white-space: nowrap;
    font-size: 14px;
    line-height: 17px;
    text-decoration: ${item.hidden ? 'line-through' : ''};
    `;

  const text = document.createTextNode(item.text);
  textContainer.appendChild(text);
  li.appendChild(boxSpan);
  li.appendChild(textContainer);

  return li;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >,
    args: any,
    options: any,
  ) {
    const isCenterLineMedianForGroups =
      args.defaults?.centerLineGroups === CenterLineOptions.Median;

    const ul = getOrCreateLegendList(options.containerID, options.showTitle);
    if (!options.showLegend) {
      return ul.remove();
    }

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items =
      (chart?.options?.plugins?.legend?.labels?.generateLabels &&
        chart.options.plugins.legend.labels.generateLabels(chart)) ??
      [];
    const mean = items.find(
      (x) =>
        x.text === LegendValueEnum.MEAN || x.text === LegendValueEnum.MEDIAN,
    );

    if (mean) {
      const meanEl = createLiElement(mean, chart);
      ul.appendChild(meanEl);
    }

    const itemsContainsGroupNames = items.filter(
      (x) =>
        x.text !== LegendValueEnum.MEAN &&
        x.text !== LegendValueEnum.MEDIAN &&
        availableDOEAxisChoices.find((y) => y.name === x.text),
    );

    if (itemsContainsGroupNames.length > 0) {
      const existedGroupNames: string[] = [];

      const groupMeansEl = document.createElement('div');
      const groupMeansTitle = document.createElement('p');

      groupMeansEl.style.cssText = `
            display: flex;
            flex-direction: column;
          `;
      groupMeansTitle.style.cssText = `
            color: ${colors.black};
            margin: 0;
            padding: 6px 0 3px;
            white-space: nowrap;
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            margin-left: 10px;
            margin-top: 5px;
          `;
      const text = document.createTextNode(
        isCenterLineMedianForGroups ? 'Group medians' : 'Group means',
      );
      groupMeansTitle.appendChild(text);

      groupMeansEl.appendChild(groupMeansTitle);

      for (const item of itemsContainsGroupNames) {
        const isAlreadyExists = existedGroupNames.includes(item.text);

        if (!isAlreadyExists) {
          const createdEl = createLiElement(
            item,
            chart,
            DOE_CHART_AXES_COLORS.find((x) => x.axisName === item.text)?.color,
            itemsContainsGroupNames,
          );

          groupMeansEl.appendChild(createdEl);

          existedGroupNames.push(item.text);
        }
      }

      ul.appendChild(groupMeansEl);
    }
  },
};

export const calculateMeanValue = (activeValues: number[]) => {
  if (activeValues.length === 0) {
    return 0;
  }

  const sumOfActiveValues = activeValues.reduce((acc, val) => (acc += val), 0);
  const countOfActiveValues = activeValues.length;

  const mean =
    sumOfActiveValues === 0 || countOfActiveValues === 0
      ? 0
      : Number((sumOfActiveValues / countOfActiveValues).toFixed(2));

  return mean;
};

export const calculateStDevValue = (activeValues: number[]) => {
  const totalCount = activeValues.length;

  const mean = calculateMeanValue(activeValues);

  const intermediateSum = activeValues
    .map((x) => Math.pow(x - mean, 2))
    .reduce((acc, val) => (acc += val), 0);

  return intermediateSum === 0 || totalCount === 0
    ? 0
    : Math.pow(intermediateSum / totalCount, 0.5);
};

export const calculateMedianValue = (activeValues: number[]) => {
  if (activeValues.length === 0) {
    return 0;
  }
  // START: calculate median value
  const orderedActiveValues = [...activeValues.sort((a, b) => a - b)];
  const isEvenCountOfActiveValues = orderedActiveValues.length % 2 === 0;

  // or on the middle
  const secondValueInTheMiddle =
    orderedActiveValues[Math.floor(orderedActiveValues.length / 2)] ?? 0;

  const firstValueInTheMiddle =
    orderedActiveValues[Math.floor(orderedActiveValues.length / 2) - 1] ?? 0;

  const median =
    orderedActiveValues.length === 0
      ? 0
      : isEvenCountOfActiveValues
        ? secondValueInTheMiddle === 0 || firstValueInTheMiddle === 0
          ? 0
          : (secondValueInTheMiddle + firstValueInTheMiddle) / 2
        : orderedActiveValues[Math.floor(orderedActiveValues.length / 2)];

  // END: calculate median value

  return Number(median.toFixed(2));
};

export const getDOEChartAxesDomains = (dots: DOEChartDot[]) => {
  const domains = {
    yMinAxis: 0,
    yMaxAxis: 0,
  };

  domains.yMaxAxis.toFixed;

  if (!dots || dots.length === 0) {
    return domains;
  }

  const yMinAxis = +dots
    .reduce((acc, val) => (acc = Math.min(acc, val.value)), dots[0].value)
    .toFixed(2);

  const yMaxAxis = +dots
    .reduce((acc, val) => (acc = Math.max(acc, val.value)), dots[0].value)
    .toFixed(2);

  const dotRangeOffset = Number(((yMaxAxis - yMinAxis) * 0.1).toFixed(2));

  domains.yMinAxis = yMinAxis - dotRangeOffset;

  domains.yMaxAxis = yMaxAxis + dotRangeOffset;

  if (domains.yMaxAxis === domains.yMinAxis) {
    domains.yMaxAxis = domains.yMaxAxis + domains.yMaxAxis * 0.01;
  }

  return domains;
};
