import { IconProps } from './types';

const DOEChartIcon = ({
  color = '#000',
  size = { width: 81, height: 65 },
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 81 65"
    fill="none"
  >
    <path
      d="M21 9V42M30 16V25M40 15V32.5M50 22.5V36.5M59 15.5V25M21 47V49.5M30 47V50M39.5 47V50M49.5 47V50M59 47V50M20 53.5H31M39 53.5H60"
      stroke={color}
      strokeWidth="1.5"
    />
    <circle cx="21" cy="21" r="2" fill={color} />
    <circle cx="21" cy="9" r="2" fill={color} />
    <circle cx="21" cy="27" r="2" fill={color} />
    <circle cx="21" cy="33" r="2" fill={color} />
    <circle cx="21" cy="41" r="2" fill={color} />
    <circle cx="30" cy="17" r="2" fill={color} />
    <circle cx="30" cy="24" r="2" fill={color} />
    <circle cx="40" cy="16" r="2" fill={color} />
    <circle cx="40" cy="24" r="2" fill={color} />
    <circle cx="40" cy="31" r="2" fill={color} />
    <circle cx="50" cy="24" r="2" fill={color} />
    <circle cx="50" cy="30" r="2" fill={color} />
    <circle cx="59" cy="17" r="2" fill={color} />
    <circle cx="59" cy="24" r="2" fill={color} />
    <circle cx="50" cy="36" r="2" fill={color} />
  </svg>
);

export default DOEChartIcon;
