import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DateTimePickerField from 'components/FormFields/DateTimePickerField';
import { ScatterSettingsType } from 'modules/visualize/VisualizeReducer';
import React from 'react';
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import { chartNumericFieldValidator } from 'utils/fieldValidators';
import Accordion from './Accordion';

interface AxesSettingsProps {
  isExpanded: boolean;
  register: UseFormRegister<ScatterSettingsType>;
  setValue: UseFormSetValue<ScatterSettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  watch: UseFormWatch<ScatterSettingsType>;
  control: Control<ScatterSettingsType>;
  errors: Partial<FieldErrorsImpl<ScatterSettingsType>>;
  children?: React.ReactNode;
}

const AxesSettings: React.FC<AxesSettingsProps> = ({
  onChange,
  setValue,
  register,
  watch,
  control,
  isExpanded,
  errors,
}) => {
  const showErrorButton =
    (errors.xMinAxis ||
      errors.xMaxAxis ||
      errors.yMinAxis ||
      errors.yMaxAxis) &&
    !isExpanded;

  return (
    <Accordion
      panelSummary={
        <PanelSummary>
          Axes
          {showErrorButton ? <ErrorOutlineIcon color="error" /> : null}
        </PanelSummary>
      }
      panelDetails={
        <div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePickerField
                control={control}
                error={errors.xMinAxis as FieldError}
                name="xMinAxis"
                watch={watch}
                setValue={setValue}
                label="X min"
              />
              <DateTimePickerField
                control={control}
                error={errors.xMaxAxis as FieldError}
                name="xMaxAxis"
                watch={watch}
                setValue={setValue}
                label="X max"
              />
            </LocalizationProvider>

            <SizeInputsWrapper>
              <CustomTextField
                id="yMinAxis"
                variant="filled"
                margin="dense"
                label="Y min"
                fullWidth
                style={{
                  marginRight: 10,
                }}
                error={!!errors.yMinAxis?.message}
                helperText={errors.yMinAxis?.message ?? ''}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('yMinAxis', chartNumericFieldValidator())}
              />
              <CustomTextField
                id="yMaxAxis"
                variant="filled"
                margin="dense"
                label="Y max"
                fullWidth
                error={!!errors.yMaxAxis?.message}
                helperText={errors.yMaxAxis?.message ?? ''}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('yMaxAxis', chartNumericFieldValidator())}
              />
            </SizeInputsWrapper>
          </div>
        </div>
      }
      expanded={isExpanded}
      onChange={onChange}
    />
  );
};

const SizeInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CustomTextField = styled(TextField)`
  margin: 0;

  .MuiFormLabel-root {
    top: -2px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 6px;
  }

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0;
    margin-right: 0;
  }
`;

const PanelSummary = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & svg {
    margin: 0 8px;
  }
`;

export default AxesSettings;
