import colors from '../../theme/patterns/colors';

const EmailActiveIcon = ({
  props,
}: {
  props?: React.SVGProps<SVGSVGElement>;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    {...props}
  >
    <g id="email-check-outline">
      <path
        id="Vector"
        d="M26 38C26 37.32 26.08 36.66 26.18 36H8V16L24 26L40 16V26.18C41.44 26.42 42.78 26.92 44 27.62V12C44 9.8 42.2 8 40 8H8C5.8 8 4 9.8 4 12V36C4 38.2 5.8 40 8 40H26.18C26.08 39.34 26 38.68 26 38ZM40 12L24 22L8 12H40ZM35.5 44.32L30 38.32L32.32 36L35.5 39.18L42.68 32L45 34.82L35.5 44.32Z"
        fill={colors.primary}
      />
    </g>
  </svg>
);

export default EmailActiveIcon;
