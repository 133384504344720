import { Component } from 'react';
import { useLocation } from 'react-router';

export interface RoutedProps {
  location: Location;
}

interface ScrollToTopProps {
  children: React.ReactNode;
  location: Location;
}

export function withRouter<P>(Child: React.ComponentClass<P>) {
  return (props: Omit<P, keyof RoutedProps>) => {
    const location = useLocation();
    return <Child {...(props as P)} location={location} />;
  };
}

class ScrollToTop extends Component<ScrollToTopProps> {
  public componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
