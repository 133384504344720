import { ReactNode } from 'react';
import styled from 'styled-components';

import colors from 'theme/patterns/colors';

interface ModalHeaderProps extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  topLineColor?: string;
}

const ModalHeader = ({
  children,
  topLineColor = colors.primary,
  style,
  ...props
}: ModalHeaderProps) => (
  <Header style={{ borderTopColor: topLineColor, ...style }} {...props}>
    {children}
  </Header>
);

const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 7px 6px 0;
  align-items: center;
  border-top: 3px solid;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 1.5px 1.5px 0 0;
  box-sizing: border-box;

  h6 {
    line-height: 28px;
    color: ${({ theme: { colors } }) => colors.black};
    font-weight: 600 !important;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .modal-header-icon {
    margin: 0 16px;
    height: 24px;
    flex-shrink: 0;

    & svg {
      height: 100%;
      width: 100%;
    }
  }
`;

export default ModalHeader;
