import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles/';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import * as actionTypes from 'modules/GlobalActionsTypes';
import { StrictMode, useEffect, useState } from 'react';
import { HeadProvider } from 'react-head';
import { useLocation } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';

import authRoutes from 'authRoutes';
import Drawer from 'components/Drawer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ModalEventContainer from 'containers/ModalEventContainer';
import ModalModelContainer from 'containers/ModalModelContainer';
import {
  fetchConfiguration,
  fetchModelDefinitions,
  fetchOrganizationsForCurrentUser,
} from 'modules/GlobalActions';
import {
  getConfiguration,
  getModalEventData,
  getStackModalData,
} from 'modules/GlobalSelectors';
import { checkAuth, fetchUserInfo } from 'modules/auth/AuthActions';
import { getAccessToken, isAuthLoading } from 'modules/auth/AuthSelectors';
import { getTheme } from 'theme';
import { isUserInfoLoading } from './modules/auth/AuthSelectors';
import { materialDefaultTheme } from './theme/themes/material';

import {
  Organization,
  OrganizationDataType,
} from 'components/SelectOrganization/SelectOrganizationReducer';
import { getOrganizationsData } from 'components/SelectOrganization/SelectOrganizationSelector';
import ModalBlocksModelContainer from 'containers/ModalBlocksModelContainer';
import { getBlocksModalInfo } from 'modules/explore/ExploreSelectors';
import { useNavigate } from 'react-router-dom';
import TopNavbar from './components/TopNavbar/TopNavbar';
import Routes from './routes';

const { theme, GlobalStyle } = getTheme('base');

const pathListNotRequireAuth = [
  '/auth/confirmforgot',
  '/auth/forgot-password',
  '/auth/password-update-success',
  '/auth/password-reset-sent',
  '/auth/sign-up',
  '/auth/sign-up-invited',
  '/auth/login',
];
const isPathNotRequireAuth = (checkPathname: string) =>
  pathListNotRequireAuth.some((p: string): boolean =>
    checkPathname.includes(p),
  );

const App = () => {
  const dispatch = useAppDispatch();
  const [authIsChecked, setAuthIsChecked] = useState(false);
  const [isConfigFetched, setIsConfigFetched] = useState(false);
  const isAuth = useAppSelector(getAccessToken);
  const isAuthProcessing = useAppSelector(isAuthLoading);
  const isUserInfoFetching = useAppSelector(isUserInfoLoading);
  const { data, activeOrganization }: OrganizationDataType =
    useAppSelector(getOrganizationsData);
  const stackModalData = useAppSelector(getStackModalData);
  const { loading: isConfigFetching, data: configuration } =
    useAppSelector(getConfiguration);
  const { showModals: showBlockModals } = useAppSelector(getBlocksModalInfo);
  const { open: isModalEventModelOpen } = useAppSelector(getModalEventData);
  const { pathname, search } = useLocation();
  const isFeedbackPage = pathname.includes('feedback');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPathNotRequireAuth(pathname) && activeOrganization) {
      const organizationFromUrl = pathname.split('/')[1];
      const activeOrganizationFromUrl = data?.find(
        (org: Organization) =>
          org.namespace === organizationFromUrl &&
          org.namespace !== activeOrganization.namespace,
      );

      if (activeOrganizationFromUrl) {
        dispatch({
          type: actionTypes.UPDATE_USER_SELECTED_ORGANIZATION,
          payload: activeOrganizationFromUrl,
        });
        dispatch({
          type: actionTypes.UPDATE_USER_ACTIVE_ORGANIZATION,
          payload: activeOrganizationFromUrl,
        });
      } else {
        !pathname.startsWith(`/${activeOrganization.namespace}`)
          ? navigate(`/${activeOrganization.namespace}${pathname}${search}`)
          : null;
      }
    }
  }, [activeOrganization, data, dispatch, navigate, pathname, search]);

  useEffect(() => {
    if (!isPathNotRequireAuth(pathname)) {
      dispatch(checkAuth());
      setAuthIsChecked(true);
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (!isConfigFetched && !isConfigFetching) {
      dispatch(fetchConfiguration());
      setIsConfigFetched(true);
    }
  }, [dispatch, isConfigFetched, isConfigFetching]);

  useEffect(() => {
    if (authIsChecked && isAuth && !isAuthProcessing) {
      dispatch(fetchModelDefinitions());
      if (!isUserInfoFetching) {
        dispatch(fetchUserInfo());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authIsChecked, dispatch, isAuth, isAuthProcessing, isFeedbackPage]);

  useEffect(() => {
    if (isAuth && !isAuthProcessing && authIsChecked) {
      if (
        configuration.showOrganizationDropdown ||
        configuration.showOrganizationUrl
      ) {
        dispatch(
          fetchOrganizationsForCurrentUser(configuration.showOrganizationUrl),
        );
      }
    }
  }, [authIsChecked, configuration, dispatch, isAuth, isAuthProcessing]);

  useEffect(() => {
    if (navigator.appVersion.indexOf('Win') !== -1) {
      document.body.classList.add('windows');
    } else if (navigator.appVersion.indexOf('Mac') !== -1) {
      document.body.classList.add('mac');
    }
  }, []);

  return (
    <StrictMode>
      <HeadProvider>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={materialDefaultTheme}>
            <StyledEngineProvider injectFirst>
              <GlobalStyle />
              {!isAuth ? (
                <ScrollToTop>{authRoutes}</ScrollToTop>
              ) : (
                <MainWrapper>
                  <TopNavbar />

                  <Drawer>
                    <ScrollToTop>
                      <Routes
                        basePath={
                          configuration.showOrganizationUrl
                            ? '/:organization/*'
                            : '/*'
                        }
                      />
                      {stackModalData.showModal &&
                        !stackModalData.isModalOpenByQuery && (
                          <ModalModelContainer />
                        )}

                      {showBlockModals && <ModalBlocksModelContainer />}
                    </ScrollToTop>

                    {isModalEventModelOpen && <ModalEventContainer />}
                  </Drawer>
                </MainWrapper>
              )}
            </StyledEngineProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </HeadProvider>
    </StrictMode>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default App;
