import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'teams';

const initialState = {
  teams: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const TeamsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_TEAMS_START: {
      return R.mergeDeepRight(state, { teams: { loading: true } });
    }
    case actionTypes.FETCH_TEAMS_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          teams: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        teams: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_TEAMS_ERROR: {
      return R.mergeDeepRight(state, { teams: { error: payload } });
    }

    case actionTypes.SET_TEAMS_TABLE_FILTER: {
      return R.mergeRight(state, {
        teams: { ...state.teams, filterModel: payload },
      });
    }
    case actionTypes.SET_TEAMS_TABLE_SORT: {
      return R.mergeRight(state, {
        teams: { ...state.teams, sortModel: payload },
      });
    }
    case actionTypes.SET_TEAMS_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        teams: { showCancel: !state.teams.showCancel },
      });
    }

    case actionTypes.SET_TEAMS_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        teams: { selectedNodes: payload },
      });
    }
    case actionTypes.CLEAR_TEAMS_TABLE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default TeamsReducer;
