const LogoFullIconStage = () => (
  <svg
    width="141px"
    height="36px"
    viewBox="0 0 141 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>B3058F4E-7256-4EFB-BAB2-0ACBBD9A4939</title>
    <defs>
      <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
        <stop stopColor="#A9A9A9" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Nav"
        transform="translate(-334.000000, -80.000000)"
        fillRule="nonzero"
      >
        <g
          id="Navs/Main-Expanded-Copy"
          transform="translate(323.000000, 66.000000)"
        >
          <g id="Group-3">
            <g id="Group" transform="translate(0.000000, 14.000000)">
              <g id="Global/Logo" transform="translate(11.000000, 0.000000)">
                <g id="logo-full">
                  <g
                    id="Global/Logo-Text"
                    transform="translate(60.516421, 8.626728)"
                    fill="#000000"
                  >
                    <path
                      d="M7.10279562,12.3973979 L4.6990384,12.3973979 L4.6990384,18.5806452 L0,18.5806452 L0,0.331797235 L9.12390663,0.331797235 C13.1661287,0.331797235 15.4608138,3.0129755 15.4608138,6.40519436 C15.4608138,9.57890704 13.5213578,11.2756135 11.828059,11.877401 L15.5424689,18.5806452 L10.1615811,18.5806452 L7.10279562,12.3973979 Z M8.41404429,4.29893809 L4.6990384,4.29893809 L4.6990384,8.40279456 L8.41344826,8.40279456 C9.6430419,8.40279456 10.6783323,7.60936639 10.6783323,6.35086632 C10.6783323,5.09236626 9.6430419,4.29893809 8.41344826,4.29893809 L8.41404429,4.29893809 Z"
                      id="Shape"
                    ></path>
                    <path
                      d="M17.7971008,14.9028453 C25.6862935,9.16665124 27.1621284,7.90418549 27.1621284,6.09253516 C27.1621284,4.8300694 25.9883719,4.17128746 24.5685436,4.17128746 C22.6297601,4.17128746 21.1008976,4.93966676 19.7901036,6.17518235 L17.195923,3.04656702 C19.1347065,0.905525721 22.0291781,0 24.5685436,0 C28.858415,0 31.9405684,2.41473526 31.9405684,6.09253516 C31.9405684,8.8923584 29.9475657,11.2795446 25.0600914,14.4638569 L32.0770102,14.4638569 L32.0770102,18.5806452 L17.7971008,18.5806452 L17.7971008,14.9028453 Z"
                      id="Path"
                    ></path>
                    <path
                      d="M34.7225368,0.331797235 L42.3787584,0.331797235 C48.1203279,0.331797235 52.2491506,3.77894115 52.2491506,9.44219143 C52.2491506,15.1054417 48.1203279,18.5806452 42.4044144,18.5806452 L34.7225368,18.5806452 L34.7225368,0.331797235 Z M42.3787584,14.4767887 C45.5774027,14.4767887 47.4371629,12.1484442 47.4371629,9.44219143 C47.4371629,6.62429755 45.7414816,4.4356537 42.4056077,4.4356537 L39.4253375,4.4356537 L39.4253375,14.4767887 L42.3787584,14.4767887 Z"
                      id="Shape"
                    ></path>
                    <polygon
                      id="Path"
                      points="55.0600227 0.331797235 57.3748584 0.331797235 57.3748584 18.5806452 55.0600227 18.5806452"
                    ></polygon>
                    <path
                      d="M69.9411099,0 C75.3535043,0 79.0351076,4.05642582 79.0351076,9.4562212 C79.0351076,14.8560166 75.3535043,18.9124424 69.9411099,18.9124424 C64.5011378,18.9124424 60.8471121,14.8560166 60.8471121,9.4562212 C60.8471121,4.05642582 64.5011378,0 69.9411099,0 Z M69.9411099,2.02821291 C65.8200643,2.02821291 63.2103885,5.18028664 63.2103885,9.4562212 C63.2103885,13.702848 65.8200643,16.8842295 69.9411099,16.8842295 C74.0345779,16.8842295 76.6724307,13.7046424 76.6724307,9.4562212 C76.6724307,5.18028664 74.0345779,2.02821291 69.9411099,2.02821291 L69.9411099,2.02821291 Z"
                      id="Shape"
                    ></path>
                  </g>
                  <g id="Global/Logo-Mark-Color">
                    <g id="logo-mark">
                      <path
                        d="M27.4473386,36 C18.5168027,36 20.975513,0 17.3612684,0 C13.7470238,0 16.2057341,36 7.27519819,36 L27.4473386,36 Z"
                        id="Path"
                        fill="url(#linearGradient-1)"
                      ></path>
                      <path
                        d="M27.4473386,36 C18.5168027,36 20.975513,0 17.3612684,0 C13.7470238,0 16.2057341,36 7.27519819,36 L27.4473386,36 Z"
                        id="Path"
                        fill="url(#linearGradient-1)"
                      ></path>
                      <path
                        d="M34.070742,28.6382623 C30.3317048,28.6382623 22.6903876,25.1559006 14.7447094,19.5802866 C7.50581854,14.5002694 1.98306985,9.06428549 0.0905298354,5.47465438 C-0.726880024,10.7398462 4.04722408,18.4063154 12.1388043,24.0844047 C20.6483579,30.0555373 29.9579805,31.8149959 34.391846,28.6280747 C34.2878011,28.6340674 34.1813644,28.6382623 34.070742,28.6382623 Z"
                        id="Path"
                        fill="gray"
                      ></path>
                      <path
                        d="M13.7960411,12.0280704 C13.9459898,10.3599114 14.0828477,8.87265863 14.2197055,7.55019182 C9.02446124,4.54523675 4.32666559,2.65437788 1.76087776,2.65437788 C1.04326642,2.65437788 0.676130269,2.81438741 0.570809201,2.96424708 C0.344696174,3.28784844 0.446447036,4.92137855 3.59001315,8.54010151 C5.97015027,11.2811603 9.37017616,14.3243266 13.3015677,17.2534562 C13.4842432,15.4855897 13.6431173,13.7308584 13.7960411,12.0280704 Z"
                        id="Path"
                        fill="#A9A9A9"
                      ></path>
                      <path
                        d="M32.1255451,21.6061667 C29.4648696,18.5181165 25.529448,15.0431574 20.9988675,11.7788018 C21.0030294,11.8227277 21.0065968,11.8648484 21.0107588,11.9093761 C21.2979334,15.1364247 21.6237547,18.7949098 22.0922714,22.1549395 L22.2111842,23.0123969 C27.1199077,25.803797 31.5071982,27.5391705 33.9550197,27.5391705 C34.6714698,27.5391705 35.0389105,27.3779083 35.1441484,27.2268755 C35.3694883,26.9001391 35.2678178,25.2538208 32.1255451,21.6061667 Z"
                        id="Path"
                        fill="#c0c0c0"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoFullIconStage;
