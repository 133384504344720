import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';
import { mockData } from './mockData';

export const STATE_KEY = 'filesMatrixes';

const initialState = {
  filesMatrixes: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const FilesMatrixReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_FILES_MATRIX_START: {
      return R.mergeDeepRight(state, { filesMatrixes: { loading: true } });
    }
    case actionTypes.FETCH_FILES_MATRIX_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          filesMatrixes: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        filesMatrixes: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),
          loading: false,
        },
      });
    }

    // case actionTypes.FETCH_FILES_MATRIX_ERROR: {
    //   return R.mergeDeepRight(state, { filesMatrixes: { error: payload } });
    // }
    case actionTypes.FETCH_FILES_MATRIX_ERROR: {
      return R.mergeDeepRight(state, {
        filesMatrixes: {
          data: R.propOr([], 'rows', mockData),
          lastRow: R.propOr(0, 'lastRow', mockData),
          loading: false,
        },
      });
    }

    case actionTypes.SET_FILES_MATRIX_TABLE_FILTER: {
      return R.mergeRight(state, {
        filesMatrixes: { ...state.filesMatrixes, filterModel: payload },
      });
    }
    case actionTypes.SET_FILES_MATRIX_TABLE_SORT: {
      return R.mergeRight(state, {
        filesMatrixes: { ...state.filesMatrixes, sortModel: payload },
      });
    }
    case actionTypes.SET_FILES_MATRIX_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        filesMatrixes: { showCancel: !state.filesMatrixes.showCancel },
      });
    }

    case actionTypes.SET_FILES_MATRIX_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        filesMatrixes: { selectedNodes: payload },
      });
    }
    case actionTypes.CLEAR_FILES_MATRIX_TABLE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default FilesMatrixReducer;
