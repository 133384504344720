import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'feedback';

const initialState = {
  loading: false,
  data: [],
  errors: [],
};

const ExperimentsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_FEEDBACK_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.FETCH_FEEDBACK_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }
    case actionTypes.FETCH_FEEDBACK_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }
    case actionTypes.CLEAR_FEEDBACK_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default ExperimentsReducer;
