import { ApiModelName } from 'utils/enum';
import * as R from 'ramda';
import { intervalToDuration } from 'date-fns';

export const enrichWMEAModelDefinitions = (
  modelDefinitions: Record<string, [string, string][]>,
) => ({
  ...modelDefinitions,
  WMEA: [...modelDefinitions[ApiModelName.WMEA], ['elapsed_time', 'int']],
});

export const getEnrichedWMEARows = (rows: Record<string, any>[]) => {
  const enrichedRows = rows.map((r: Record<string, any>) => {
    const measurementStartDatetime = r.datetime;
    const processStartDatetime = r.pro_start_datetime;

    let elapsedTimeInMin = null;

    if (measurementStartDatetime && processStartDatetime) {
      const { seconds, minutes } = intervalToDuration({
        start: new Date(measurementStartDatetime),
        end: new Date(processStartDatetime),
      });

      if (R.isNil(minutes) || R.isNil(seconds)) {
        elapsedTimeInMin = null;

        return;
      }

      elapsedTimeInMin = minutes! + (seconds! > 30 ? 1 : 0);
    }

    return {
      ...r,
      elapsed_time: elapsedTimeInMin,
    };
  });

  return enrichedRows;
};
