import { Dialog } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const TransitionComponent = React.forwardRef<HTMLDivElement, TransitionProps>(
  function FakeTransitionComponent({ children }, ref) {
    return (
      <Dialog open={true} ref={ref} aria-roledescription="dialog modal">
        {children}
      </Dialog>
    );
  },
);

export default TransitionComponent;
