import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';

import { compose } from 'redux';
import { createBrowserHistory, createMemoryHistory } from 'history';

import {
  createRouterMiddleware,
  ReduxRouterState,
} from '@lagunovsky/redux-react-router';

import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import axiosMiddlewareOptions from './middlewares/axiosMiddlewareOptions';
import AppConfig from 'config/AppConfig';
import loggerMiddleware from './middlewares/loggerMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export type State = { router: ReduxRouterState };

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
    __PRELOADED_STATE__: any;
  }
}

export const history = isServer
  ? createMemoryHistory({ initialEntries: ['/'] })
  : createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const initialState = !isServer ? window.__PRELOADED_STATE__ || {} : {};

const sagaMiddleware = createSagaMiddleware();

const axiosClient = axios.create({
  baseURL: AppConfig.apiUrl,
  responseType: 'json',
});

const middlewares = [
  routerMiddleware,
  thunk,
  axiosMiddleware(axiosClient, axiosMiddlewareOptions),
  sagaMiddleware,
];

if (!isServer && typeof jest === 'undefined') {
  middlewares.push(loggerMiddleware);
}

export const store = configureStore({
  reducer: rootReducer(history),
  preloadedState: initialState,
  middleware: [...middlewares],
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const nextRootReducer = require('./rootReducer').default(history);
    store.replaceReducer(nextRootReducer);
  });
}
