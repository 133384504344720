import { ReactNode } from 'react';

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
} from '@mui/material/';
import { withStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';

interface CustomAccordionProps extends Partial<AccordionProps> {
  panelSummary: ReactNode;
  panelDetails: ReactNode;
  expanded: boolean;
  panelSummaryStyle?: Record<string, any>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
}

const CustomAccordion = ({
  panelSummary,
  panelDetails,
  panelSummaryStyle = {},
  expanded,
  onChange,
  ...rest
}: CustomAccordionProps) => {
  return (
    <Accordion square expanded={expanded} onChange={onChange} {...rest}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        style={{ ...panelSummaryStyle }}
      >
        {panelSummary}
      </AccordionSummary>

      <AccordionDetails>{panelDetails}</AccordionDetails>
    </Accordion>
  );
};

const Accordion = withStyles(() => {
  return {
    root: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  };
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: '43px !important',
    height: 43,
    padding: 0,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: -1,
    '&$expanded': {
      minHeight: '43px !important',
      boxShadow: '0 1px 8px 0 rgba(0,0,0,0.2)',
    },
  },
  content: {
    width: '100%',
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    height: '100%',
    width: '100%',
    marginTop: 3,
  },
})(MuiAccordionDetails);

export default CustomAccordion;
