import { IconProps } from './types';

const MeasurementAddIcon = ({
  color = '#000',
  size = { width: 19, height: 15 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 19 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>2CC6C2BC-CAB0-43E9-A48B-6154971294AB</title>
    <g
      id="MPM-Modals"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.87"
    >
      <g
        id="Process-Modal-View-More"
        transform="translate(-343.000000, -837.000000)"
        fill={color}
      >
        <g id="Group-9" transform="translate(124.000000, 64.000000)">
          <g id="Group-14" transform="translate(0.000000, 52.000000)">
            <g id="Group-7" transform="translate(0.000000, 703.000000)">
              <g
                id="Button-Text/Slim/Contained/Default-Copy-4"
                transform="translate(211.000000, 9.000000)"
              >
                <g id="content" transform="translate(8.000000, 4.000000)">
                  <g
                    id="icons/ic/outline-keyboard_arrow_left-24px"
                    transform="translate(0.000000, 5.000000)"
                  >
                    <g
                      id="relative-scale-add"
                      transform="translate(0.750000, 0.000000)"
                    >
                      <path
                        d="M15,8.25 L15,10.5 L17.25,10.5 L17.25,12 L15,12 L15,14.25 L13.5,14.25 L13.5,12 L11.25,12 L11.25,10.5 L13.5,10.5 L13.5,8.25 L15,8.25 Z M13.5,3.13474733e-14 C14.2764706,3.13474733e-14 14.9200692,0.591280277 14.9930999,1.35490332 L15,1.5 L15.0008694,6.81236478 C14.7566891,6.77134974 14.5058378,6.75 14.25,6.75 C13.9945103,6.75 13.7439935,6.77129169 13.5001273,6.81219748 L13.5,1.5 L1.5,1.5 L1.5,10.5 L9.81219748,10.5001273 C9.77129169,10.7439935 9.75,10.9945103 9.75,11.25 C9.75,11.5058378 9.77134974,11.7566891 9.81236478,12.0008694 L1.5,12 C0.720303883,12 0.0795513218,11.4051119 0.00686657806,10.64446 L0,10.5 L0,1.5 C0,0.716470588 0.591280277,0.079100346 1.35490332,0.00682678608 L1.5,3.13474733e-14 L13.5,3.13474733e-14 Z M10.5,7.5 L10.5,9 L9,9 L9,7.5 L10.5,7.5 Z M7.5,4.5 L7.5,6 L6,6 L6,4.5 L7.5,4.5 Z M4.5,4.5 L4.5,6 L3,6 L3,4.5 L4.5,4.5 Z M10.5,4.5 L10.5,6 L9,6 L9,4.5 L10.5,4.5 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MeasurementAddIcon;
