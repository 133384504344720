import * as R from 'ramda';

import { clearTokenData, setTokenData } from 'helpers/localeStorage';
import * as actionTypes from './AuthActionsTypes';

export const STATE_KEY = 'auth';

export type InitialAuthStateType = {
  auth: {
    loading: boolean;
    data: {
      id_token?: string;
      access_token?: string;
    };
    error: boolean;
  };
  signUp: {
    loading: boolean;
    error: string;
    userStatus: string;
    userEmail: string;
  };
  userInfo: {
    loading: boolean;
    data: Record<string, any>;
    error: boolean;
  };
  pwChange: {
    loading: boolean;
    error: boolean;
    message: string;
  };
  pwForgot: {
    loading: boolean;
    error: boolean;
    message: string;
  };
  pwForgotConfirm: {
    loading: boolean;
    error: boolean;
    message: string;
  };
};

const initialState: InitialAuthStateType = {
  auth: {
    loading: false,
    data: {},
    error: false,
  },
  signUp: {
    loading: false,
    error: '',
    userStatus: '',
    userEmail: '',
  },
  userInfo: {
    loading: false,
    data: {},
    error: false,
  },
  pwChange: {
    loading: false,
    error: false,
    message: '',
  },
  pwForgot: {
    loading: false,
    error: false,
    message: '',
  },
  pwForgotConfirm: {
    loading: false,
    error: false,
    message: '',
  },
};

const AuthReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_START: {
      return R.mergeDeepRight(state, {
        userInfo: { loading: true, error: false },
      });
    }
    case actionTypes.FETCH_USER_INFO_SUCCESS: {
      return R.mergeDeepRight(state, {
        userInfo: { data: payload, loading: false, error: false },
      });
    }
    case actionTypes.FETCH_USER_INFO_ERROR: {
      return R.mergeDeepRight(state, {
        userInfo: { loading: false, error: payload, data: {} },
      });
    }

    case actionTypes.LOGIN_START:
    case actionTypes.LOGIN_REFRESH_START: {
      return R.mergeRight(state, {
        auth: { loading: true, error: false, data: {} },
      });
    }
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.LOGIN_REFRESH_SUCCESS: {
      setTokenData(payload.credentials);
      return R.mergeRight(state, {
        auth: {
          loading: false,
          error: false,
          data: payload.credentials,
        },
      });
    }
    case actionTypes.LOGIN_ERROR:
    case actionTypes.LOGIN_REFRESH_ERROR: {
      return R.mergeRight(state, {
        auth: {
          loading: false,
          error: payload,
          data: {},
        },
      });
    }

    case actionTypes.LOGOUT_START: {
      return R.mergeDeepRight(state, {
        auth: {
          loading: true,
        },
      });
    }
    case actionTypes.LOGOUT_SUCCESS: {
      clearTokenData();

      const { shouldKeepError } = payload;

      const stateToReset = initialState;

      if (shouldKeepError) {
        stateToReset.auth = {
          ...stateToReset.auth,
          error: state.auth.error,
        };
      }

      return {
        ...state,
        ...stateToReset,
      };
    }
    case actionTypes.LOGOUT_ERROR: {
      return R.mergeDeepRight(state, {
        auth: {
          loading: false,
          error: payload,
        },
      });
    }

    case actionTypes.AUTH_PWCHANGE_START: {
      return R.mergeRight(state, {
        pwChange: {
          loading: true,
          error: false,
        },
      });
    }
    case actionTypes.AUTH_PWCHANGE_SUCCESS: {
      return R.mergeRight(state, {
        pwChange: {
          message: payload,
          loading: false,
          error: false,
        },
      });
    }
    case actionTypes.AUTH_PWCHANGE_ERROR: {
      return R.mergeRight(state, {
        pwChange: {
          message: '',
          loading: false,
          error: payload,
        },
      });
    }
    case actionTypes.AUTH_PWFORGOT_START: {
      return R.mergeRight(state, {
        pwForgot: {
          loading: true,
          error: false,
        },
      });
    }
    case actionTypes.AUTH_PWFORGOT_SUCCESS: {
      return R.mergeRight(state, {
        pwForgot: {
          message: payload,
          loading: false,
          error: false,
        },
      });
    }
    case actionTypes.AUTH_PWFORGOT_ERROR: {
      return R.mergeRight(state, {
        pwForgot: {
          message: '',
          loading: false,
          error: payload,
        },
      });
    }
    case actionTypes.AUTH_PWFORGOT_CONFIRM_START: {
      return R.mergeRight(state, {
        pwForgotConfirm: {
          loading: true,
          error: false,
        },
      });
    }
    case actionTypes.AUTH_PWFORGOT_CONFIRM_SUCCESS: {
      return R.mergeRight(state, {
        pwForgotConfirm: {
          message: payload,
          loading: false,
          error: false,
        },
      });
    }
    case actionTypes.AUTH_PWFORGOT_CONFIRM_ERROR: {
      return R.mergeRight(state, {
        pwForgotConfirm: {
          message: '',
          loading: false,
          error: payload,
        },
      });
    }
    case actionTypes.AUTH_RESET_FORM: {
      return R.mergeRight(state, {
        pwChange: initialState.pwChange,
        pwForgot: initialState.pwForgot,
        pwForgotConfirm: initialState.pwForgotConfirm,
        auth: { ...state.auth, error: false },
      });
    }
    case actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_START: {
      return R.mergeRight(state, {
        signUp: {
          loading: true,
          error: '',
        },
      });
    }
    case actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS: {
      return R.mergeRight(state, {
        signUp: {
          loading: false,
          error: '',
          userStatus: payload.userStatus,
          userEmail: payload.userEmail,
        },
      });
    }
    case actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR: {
      return R.mergeRight(state, {
        signUp: {
          loading: false,
          error: payload,
          userStatus: state.signUp.userStatus,
          userEmail: state.signUp.userEmail,
        },
      });
    }
    case actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_RESET: {
      return R.mergeRight(state, {
        signUp: {
          loading: false,
          error: '',
          userStatus: '',
          userEmail: '',
        },
      });
    }
    case actionTypes.AUTH_SIGNUP_CHANGE_STEP: {
      return R.mergeRight(state, {
        signUp: {
          userStatus: payload,
        },
      });
    }

    default:
      return state;
  }
};

export default AuthReducer;
