import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { SelectWithModelChoices } from 'components/FormFields';
import { DOESettingsType } from 'modules/visualize/VisualizeReducer';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import { CenterLineOptions } from '.';
import Accordion from './Accordion';

interface ChartSettingsProps {
  isExpanded: boolean;
  control: Control<DOESettingsType>;
  columnChoices: any[];
  setValue: UseFormSetValue<DOESettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  formValues: DOESettingsType;
  children?: React.ReactNode;
}

const ChartSettings: React.FC<ChartSettingsProps> = ({
  isExpanded,
  control,
  columnChoices,
  formValues,
  onChange,
  setValue,
}) => {
  return (
    <Accordion
      panelSummary="Chart"
      panelDetails={
        <div>
          <MultipleOptionsWrapper>
            <SelectWithModelChoices
              choices={columnChoices}
              fieldName="xAxis"
              label="X Axis"
              control={control}
              withoutIcon
              multiple
              notRequired
              disableClearable
            />
          </MultipleOptionsWrapper>
          <div>
            <RadioGroupWrapper>
              <FormControl>
                <CustomFormLabel>Center Line (All Data)</CustomFormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={CenterLineOptions.Mean}
                  row
                  value={formValues.centerLineAll}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, value) =>
                    setValue('centerLineAll', value as CenterLineOptions)
                  }
                >
                  <CustomFormControlLabel
                    value={CenterLineOptions.Mean}
                    control={<Radio />}
                    label={CenterLineOptions.Mean}
                  />
                  <CustomFormControlLabel
                    value={CenterLineOptions.Median}
                    control={<Radio />}
                    label={CenterLineOptions.Median}
                  />
                  <CustomFormControlLabel
                    value={CenterLineOptions.None}
                    control={<Radio />}
                    label={CenterLineOptions.None}
                  />
                </RadioGroup>
              </FormControl>
            </RadioGroupWrapper>
          </div>
          <div>
            <RadioGroupWrapper>
              <FormControl>
                <CustomFormLabel>Center Line (Groups)</CustomFormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={CenterLineOptions.Mean}
                  row
                  value={formValues.centerLineGroups}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, value) =>
                    setValue('centerLineGroups', value as CenterLineOptions)
                  }
                >
                  <CustomFormControlLabel
                    value={CenterLineOptions.Mean}
                    control={<Radio />}
                    label={CenterLineOptions.Mean}
                  />
                  <CustomFormControlLabel
                    value={CenterLineOptions.Median}
                    control={<Radio />}
                    label={CenterLineOptions.Median}
                  />
                  <CustomFormControlLabel
                    value={CenterLineOptions.None}
                    control={<Radio />}
                    label={CenterLineOptions.None}
                  />
                </RadioGroup>
              </FormControl>
            </RadioGroupWrapper>
          </div>

          <SwitchOptionsWrapper>
            <FormGroup>
              <CustomFormControlLabel
                $withGap
                control={<Switch size="small" />}
                label="Show Group Legend"
                onChange={(event, checked) =>
                  setValue('showGroupLegend', checked)
                }
                checked={formValues.showGroupLegend}
              />
              <CustomFormControlLabel
                $withGap
                control={<Switch size="small" />}
                label="Show Mean Diamonds"
                onChange={(event, checked) =>
                  setValue('showMeanDiamonds', checked)
                }
                checked={formValues.showMeanDiamonds}
                disabled
              />
              <CustomFormControlLabel
                $withGap
                control={<Switch size="small" />}
                label="Pooled Variance"
                onChange={(event, checked) =>
                  setValue('pooledVariance', checked)
                }
                checked={formValues.pooledVariance}
                disabled
              />
            </FormGroup>
          </SwitchOptionsWrapper>
        </div>
      }
      expanded={isExpanded}
      onChange={onChange}
    />
  );
};

const AxisOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & div:first-child {
    margin-right: 5px;
  }

  .MuiFilledInput-root {
    padding-top: 12px;
    padding-right: 25px !important;
  }

  .MuiInputLabel-root {
    top: -2px;
  }

  .MuiFilledInput-input {
    padding-bottom: 4px !important;
  }
`;

const MultipleOptionsWrapper = styled(AxisOptionsWrapper)`
  .MuiFilledInput-root {
    min-height: 46px;
    padding-top: 20px;
  }

  .MuiAutocomplete-tag {
    margin: 0 !important;
    margin-bottom: 4px !important;
    margin-right: 2px !important;
    max-height: 25px;
    background-color: ${colors.grey100};
    border: 1px solid ${colors.lightGrey};

    & .MuiChip-label {
      padding-left: 6px;
      padding-right: 8px;
    }

    & .MuiChip-deleteIcon {
      font-size: 18px;
    }
  }
`;

const SwitchOptionsWrapper = styled.div`
  margin: 10px 0;

  .MuiFormGroup-root {
    padding-left: 4px;
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)<{ $withGap?: boolean }>`
  margin-right: 15px;
  margin-top: ${({ $withGap }) => ($withGap ? '3px' : 0)};
  margin-bottom: ${({ $withGap }) => ($withGap ? '3px' : 0)};

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiRadio-root:hover {
    padding: 5px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

const RadioGroupWrapper = styled.div`
  .MuiFormGroup-root {
    padding-left: 4px;
    flex-wrap: nowrap;
  }
`;

const CustomFormLabel = styled(FormLabel)`
  font-size: 12px;
  color: ${colors.black};
  font-weight: 500;
`;

export default ChartSettings;
