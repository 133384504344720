export enum ModelName {
  Material = 'material',
  Process = 'process',
  Measurement = 'measurement',
  Experiment = 'experiment',
  Program = 'program',
  Feedback = 'feedback',
  Message = 'message',
  Directive = 'directive',
  MaterialType = 'materialType',
  ProcessType = 'processType',
  MeasurementType = 'measurementType',
  ControlType = 'controlType',
  User = 'user',
  Team = 'team',
  Site = 'site',
  Instrument = 'instrument',
  Version = 'version',
  Branch = 'branch',
  OrganizationSettings = 'organizationSettings',
  Cell = 'cell',
  FilesMatrix = 'filesMatrix',
}

export enum ModelLabel {
  Materials = 'Materials',
  Processes = 'Processes',
  Measurements = 'Measurements',
  Experiments = 'Experiments',
  DataFiles = 'Data Files',
  MaterialTypes = 'Material Types',
  ProcessTypes = 'Process Types',
  MeasurementTypes = 'Measurement Types',
  ControlTypes = 'Control Types',
  Users = 'Users',
  Teams = 'Teams',
  Sites = 'Sites',
  Instruments = 'Instruments',
  Help = 'Help',
  Version = 'Version',
  Branch = 'Branch',
  Organization = 'Organization Settings',
  Cell = 'Cells',
  FilesMatrix = 'Files',
}

export enum ApiModelName {
  KMAT = 'KMAT',
  KPRO = 'KPRO',
  KMEA = 'KMEA',
  KEXP = 'KEXP',
  KFIL = 'KFIL',
  KMTT = 'KMTT',
  KPRT = 'KPRT',
  KMST = 'KMST',
  KCNT = 'KCNT',
  KUSR = 'KUSR',
  KTEA = 'KTEA',
  KSIT = 'KSIT',
  KINS = 'KINS',
  KBRA = 'KBRA',
  KFID = 'KFID',
  WMAT = 'WMAT',
  WPRO = 'WPRO',
  WMEA = 'WMEA',
  WEXP = 'WEXP',
  WFIL = 'WFIL',
  WMTT = 'WMTT',
  WPRT = 'WPRT',
  WMST = 'WMST',
  WCNT = 'WCNT',
  WUSR = 'WUSR',
  WTEA = 'WTEA',
  WSIT = 'WSIT',
  WINS = 'WINS',
  WBRA = 'WBRA',
  WFID = 'WFID',
  KCEL = 'KCEL',
  WCEL = 'WCEL',
  WFILMTRX = 'WFILMTRX',
  KFILMTRX = 'KFILMTRX',
}

export enum QueueModalType {
  Inactivate = 'inactivate',
  Reactivate = 'reactivate',
  Void = 'void',
  Edit = 'edit',
  Create = 'create',
}

export enum EventName {
  Inactivate = 'inactivate',
  Reactivate = 'reactivate',
  DownloadOriginalFile = 'downloadOriginalFile',
  DownloadCurrentFile = 'downloadCurrentFile',
  PreviewFile = 'previewFile',
}

export enum ExploreTable {
  KBRA = 'KBRA',
  KMAT = 'KMAT',
  KPRO = 'KPRO',
  KMEA = 'KMEA',
}

type ActionTypeProps = {
  [key: string]: string;
};

export const ActionType: ActionTypeProps = {
  material: 'MATERIALS',
  process: 'PROCESSES',
  measurement: 'MEASUREMENTS',
  experiment: 'EXPERIMENTS',
  program: 'FILES',
  feedback: 'FEEDBACK_DATA',
  materialType: 'MATERIAL_TYPES',
  processType: 'PROCESS_TYPES',
  measurementType: 'MEASUREMENT_TYPES',
  controlType: 'CONTROL_TYPES',
  user: 'USERS',
  team: 'TEAMS',
  site: 'SITES',
  instrument: 'INSTRUMENTS',
  cell: 'CELLS',
  filesMatrix: 'FILES_MATRIX',
};

export const StateKey: ActionTypeProps = {
  material: 'materials',
  process: 'processes',
  measurement: 'measurements',
  experiment: 'experiments',
  program: 'files',
  feedback: 'feedback',
  materialType: 'materialTypes',
  processType: 'processTypes',
  measurementType: 'measurementTypes',
  controlType: 'controlTypes',
  user: 'users',
  team: 'teams',
  site: 'sites',
  instrument: 'instruments',
  cell: 'cells',
  filesMatrix: 'filesMatrixes',
};

export const mapApiEndpoint: Record<ModelName, Array<string>> = {
  material: ['KMAT', 'WMAT'],
  process: ['KPRO', 'WPRO'],
  measurement: ['KMEA', 'WMEA'],
  program: ['KFIL', 'WFIL'],
  feedback: ['KFED', 'WFED'],
  site: ['KSIT', 'WSIT'],
  team: ['KTEA', 'WTEA'],
  user: ['KUSR', 'WUSR'],
  experiment: ['KEXP', 'WEXP'],
  instrument: ['KINS', 'WINS'],
  controlType: ['KCNT', 'WCNT'],
  materialType: ['KMTT', 'WMTT'],
  processType: ['KPRT', 'WPRT'],
  measurementType: ['KMST', 'WMST'],
  branch: ['KBRA', 'WBRA'],
  version: [],
  message: [],
  directive: [],
  organizationSettings: [],
  cell: ['KCEL', 'WCEL'],
  filesMatrix: ['KFILMTRX', 'WFILMTRX'],
};

export const CONVERT_DEFINITION_TYPES: Record<string, string> = {
  int: 'number',
  str: 'string',
};

export const API_ENDPOINTS = {
  auth: {
    login: 'v1/auth/login',
    logout: 'v1/auth/logout',
    loginRefresh: 'v1/auth/login_refresh',
    pwchange: 'v1/auth/pwchange',
    pwforgot: 'v1/auth/pwforgot',
    pwforgotconfirm: 'v1/auth/pwforgotconfirm',
    user: 'v1/auth/user',
    organizations: 'v1/auth/organizations',
    /**
     * Signup endpoints group
     */
    signup: {
      /**
       * @method POST
       * @path 'v1/signup/user-status'
       * @param {string} email
       *
       * Example:
       *  input (this is a python dict, should be converted to json)
       *  ```python
       *  data = {
       *    "email": "test@r2dio.com",
       *  }
       *  ```
       *
       *  output (will be a string JSON)
       *  ```json
       *  {
       *    "error": False,
       *    "exists": True,
       *    "confirmed": True,
       *    "has_organization": True
       *  }
       *  ```
       */
      userStatus: 'v1/signup/user-status',

      /**
       * @method POST
       * @path 'v1/signup/send-confirmation-email'
       * @param {string} email
       * @param {string} given_name
       * @param {string} family_name
       *
       * Example:
       *  input (this is a python dict, should be converted to json)
       *  ```python
       *  # input (this is a python dict, should be converted to json)
       *  data = {
       *     "email": "newuser@r2dio.com",
       *     "given_name": "First",
       *     "family_name": "Last",
       *  }
       *  # output (will be a string JSON)
       *  {'error': False, 'message': 'Confirmation email sent to newuser@r2dio.com'}
       *  ```
       */
      sendConfirmationEmail: 'v1/signup/send-confirmation-email',

      /**
       * @method POST
       * @path 'v1/signup/create-user'
       * @param {string} uuid_code
       * @param {string} password
       * Example:
       * ```python
       * # input (this is a python dict, should be converted to json)
       * data = {
       *     # use uuid (or for testing, can use:  "email": "newuser@r2dio.com")
       *     "uuid_code": "uuid-xxxx-xxxx-xxxx",
       *     "passowrd": "V3ryS3cretP@SSWORD",
       * }
       * # output (will be a string JSON)
       * {'error': False, 'message': 'User newuser@r2dio.com confirmed', 'email': 'newuser@r2dio.com'}
       * ```
       */
      createUser: 'v1/signup/create-user',

      /**
       * @method POST
       * @path 'v1/signup/validate-namespace'
       * @param {string} namespace
       * Example:
       * ```python
       * # input (this is a python dict, should be converted to json)
       * data = {
       *   "namespace": "good-namespace",
       * }
       * # output (will be a string JSON)
       * {'error': False, 'message': ''}
       */
      validateNamespace: 'v1/signup/validate-namespace',

      /**
       * @method POST
       * @path 'v1/signup/create-organization'
       * @param {string} namespace
       * @param {string} ns_display
       * @param {string} email
       * @param {boolean} make_default
       * Example:
       * ```python
       * # input (this is a python dict, should be converted to json)
       * data = {
       *   "namespace": "good-namespace",
       *   "ns_display": "The Good Namespace Company",
       *   "email": "theboss@goodnamespace.com",
       *   "make_default": True,
       * }
       * # output (will be a string JSON)
       * {'error': False, 'message': 'Organization good-namespace created.'}
       */
      createOrganization: 'v1/signup/create-organization',
    },
  },
  /**
   * Endpoint to fetch docs for swagger
   * @@path 'v1/specs'
   */
  specs: 'v1/specs',
  /**
   * This module contains dynamic endpoints, you have to specify some parametrs for some endpoints
   */
  explore: {
    /**
     * Need to specify /{model_name}
     * @@path 'v1/explore/table/{model_name}'
     */
    table: 'v1/explore/table',
    /**
     * Need to specify /{model_name}
     * @path 'v1/explore/bfd/{model_name}'
     */
    bfd: 'v1/explore/bfd',
  },
  /**
   * This module contains dynamic endpoints, you have to specify some parametrs for some endpoints
   */
  upload: {
    /**
     * @path 'v1/pfu/upload_urls'
     */
    uploadFile: 'v1/pfu/upload_urls',
    /**
     * Need to specify /{model_name}
     * @path 'v1/pfu/mpm_pfu_file_ids/{model_name}'
     */
    downloadMPM: 'v1/pfu/mpm_pfu_file_ids',
    /**
     * @path 'v1/pfu/download_urls'
     */
    download: 'v1/pfu/download_urls',
  },
  /**
   * Need to specify /{db_id}
   * @path 'v1/pfu/feedback/{db_id}'
   */
  feedback: 'v1/pfu/feedback',
  /**
   * CRUD endpoint groups
   */
  models: {
    /**
     * @method GET
     * @path 'v1/models/definitions'
     */
    getModelDefinitions: 'v1/models/definitions',
    /**
     * Need to specify /{model_name}
     * @method GET
     * @path 'v1/models/choices/{model_name}'
     */
    getChoices: 'v1/models/choices',
    /**
     * Need to specify /{model_name}/{db_id}
     * @method GET
     * @path v1/models/choices/{model_name}/{db_id}
     */
    getEditChoices: 'v1/models/choices',
    /**
     * Need to specify /{model_name}/{db_id}
     * @method GET
     * @path v1/models/view/{model_name}/{db_id}
     */
    getViewModel: 'v1/models/view',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/search/{model_name}
     */
    getTable: 'v1/models/search',
    /**
     * Need to specify /{src_name}/{dst_name}
     * @method POST
     * @path v1/models/related/{src_name}/{dst_name}
     */
    getRelatedTable: 'v1/models/related',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/single/{model_name}
     */
    createModelItem: 'v1/models/single',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/view/{model_name}
     */
    getMultipleViewModel: 'v1/models/view',
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/models/delete_check/{model_name}
     */
    deleteCheck: 'v1/models/delete_check',
    /**
     * Need to specify /{model_name}/{db_id}
     * @method PUT
     * @path v1/models/single/{model_name}/{db_id}
     */
    editModelItem: 'v1/models/single',
    /**
     * Need to specify /{model_name}
     * @method PUT
     * @path v1/models/bulk/{model_name}
     */
    editMultipleModelItem: 'v1/models/bulk',
    /**
     * Need to specify /{model_name}
     * @method PUT
     * @path v1/models/reactivate/{model_name}
     */
    reactivateMultiple: 'v1/models/reactivate',
    /**
     * Need to specify /{model_name}
     * @method DELETE
     * @path v1/models/bulk/{model_name}
     */
    cancelMultiple: 'v1/models/bulk',
  },
  /**
   * This module contains dynamic endpoints, you have to specify some parametrs for some endpoints
   */
  charts: {
    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/chart/scatter/{model_name}
     */
    scatter: 'v1/chart/scatter',

    /**
     * Need to specify /{model_name}
     * @method POST
     * @path v1/chart/histogram/{model_name}
     */
    histogram: 'v1/chart/histogram',
  },
  configuration: 'v1/misc/app-configurations',
  settings: {
    /**
     * @method GET
     * @path 'v1/settings/get-active-users'
     * Example:
     * {
     * "error": false,
     * "message": "",
     * "users": [
     *     {
     *         "id": "8d99eb0f-b5da-4857-8093-7fd6ec9c8773",
     *         "name": "Tom Treynor",
     *         "email": "ttreynor@r2dio.com",
     *         "role_name": "admin"
     *     },
     *     {
     *         "id": "12baacf0-cd2d-464d-9654-a25f8ad3d611",
     *         "name": "Dmitriy Tkalich",
     *         "email": "dtkalich@r2dio.com",
     *         "role_name": "admin"
     *     }
     *   ]
     * }
     */
    getActiveUsers: 'v1/settings/get-active-users',
    /**
     * @method GET
     * @path 'v1/settings/get-active-invitations'
     * Example:
     *  {
     *      "error": false,
     *      "message": "",
     *      "invitations": [
     *          {
     *              "id": "445e818b-95a2-4e7e-9b89-01a9106127e5",
     *              "invitee_name": "Invited User",
     *              "invitee_email": "invited@r2dio.com",
     *              "date_invited": "2024-11-27"
     *          }
     *      ]
     *  }
     */
    getActiveInvitations: 'v1/settings/get-active-invitations',
    /**
     * @method GET
     * @path 'v1/settings/get-org-roles'
     * Example:
     * {
     *    "error": false,
     *    "message": "",
     *    "roles": {
     *        "admin": 1,
     *        "member": 2,
     *        "viewer": 3
     *    }
     * }
     */
    getOrgRoles: 'v1/settings/get-org-roles',
    /**
     * @method POST
     * @path 'v1/settings/create-send-invitations'
     * @param {Array.<{email: string, name: string, role_id: number}>}
     * Example:
     * # input
     *   [
     *       {"email": "lkung+invite1@r2dio.com", "name": "invite1", "role_id": 2},
     *       {"email": "lkung+invite2@r2dio.com", "name": "invite2", "role_id": 2}
     *   ]
     * # output
     *   [
     *     {
     *       "email": "lkung+invite1@r2dio.com",
     *       "name": "invite1",
     *       "role_id": 2,
     *       "invite_id": "544596fd-0513-4c35-9812-eed6169ed7cc"
     *     },
     *     {
     *       "email": "lkung+invite2@r2dio.com",
     *       "name": "invite2",
     *       "role_id": 2,
     *       "invite_id": "1650459f-c328-4273-aaa2-5da7d669d889"
     *     }
     *   ]
     */
    createSendInvitations: 'v1/settings/create-send-invitations',
    /**
     * @method POST
     * @path 'v1/settings/send-invitation-email'
     * @param {string} invite_id
     * Example:
     * ## input:
     * {
     *   "invite_id": "445e818b-95a2-4e7e-9b89-01a9106127e5"
     * }
     * ## output:
     * ### I couldn't get the email to send for unknown reason but here's the callback link that
     * ### would be in the user's inbox:
     * 'https://mic.am880.r2d.io/invite?confirmation_code=445e818b-95a2-4e7e-9b89-01a9106127e5'
     */
    sendInvitationEmail: 'v1/settings/send-invitation-email',
    /**
     * @method POST
     * @path 'v1/settings/delete-invitation'
     * @param {string} invite_id
     * Example:
     * ## input:
     * {
     *   "invite_id": "445e818b-95a2-4e7e-9b89-01a9106127e5"
     * }
     * ## output:
     * {
     *   "error": false,
     *   "message": ""
     * }
     */
    deleteInvitation: 'v1/settings/delete-invitation',
    /**
     * @method POST
     * @path 'v1/settings/delete-user'
     * @param {string} email_address
     * Example:
     * ## input:
     * {
     *   "email_address": "fakeuser@r2dio.com"
     * }
     * ## output:
     * {
     *   "error": true,
     *   "message": "Couldn't delete user: No user found for the organization."
     * }
     */
    deleteUser: 'v1/settings/delete-user',
    /**
     * @method POST
     * @path 'v1/settings/update-user-role'
     * @param {string} email_address
     * Example:
     * ## input:
     * {
     *   "email_address": "lkung@r2dio.com",
     *   "role_name": "admin"
     * }
     * ## output:
     * {
     *   "error": false,
     *   "message": ""
     * }
     */
    updateUserRole: 'v1/settings/update-user-role',
  },
};

export const FILTER_TYPES_FROM_PARAMS = {
  co: 'contains',
  nco: 'notContains',
  eq: 'equals',
  neq: 'notEqual',
  sw: 'startsWith',
  ew: 'endsWith',
  in: 'inSet',
  gt: 'greaterThan',
  ir: 'inRange',
  gte: 'greaterThanOrEqual',
  lte: 'lessThanOrEqual',
  lt: 'lessThan',
};
