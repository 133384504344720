import { IconProps } from './types';

const MpmCurrentIcon = ({
  color = '#000',
  size = { width: 24, height: 24 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons/ic/mpm-current</title>
    <g
      id="icons/ic/mpm-current"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.87"
    >
      <path
        d="M12,6 C15.3137085,6 18,8.6862915 18,12 C18,15.3137085 15.3137085,18 12,18 C9.02728549,18 6.55950437,15.8381232 6.08309386,13.0008069 L0,13 L0,11 L6.08292631,11.0001915 C6.55892549,8.16238395 9.02693683,6 12,6 Z M12,7.16129032 C9.32765444,7.16129032 7.16129032,9.32765444 7.16129032,12 C7.16129032,14.6723456 9.32765444,16.8387097 12,16.8387097 C14.6723456,16.8387097 16.8387097,14.6723456 16.8387097,12 C16.8387097,9.32765444 14.6723456,7.16129032 12,7.16129032 Z M24,11 L24,13 L18,13 L18,11 L24,11 Z"
        id="Combined-Shape"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default MpmCurrentIcon;
