import colors from 'theme/patterns/colors';
import { LegendValueEnum } from '../model/DOELegendModels';

export const DOE_LEGEND_ITEMS = [
  {
    value: LegendValueEnum.MEAN,
    id: LegendValueEnum.MEAN,
    color: colors.green,
  },
  {
    value: LegendValueEnum.MAT_NAME,
    id: LegendValueEnum.MAT_NAME,
    color: colors.info,
  },
  {
    value: LegendValueEnum.PRO_OPERATOR,
    id: LegendValueEnum.PRO_OPERATOR,
    color: colors.process.color,
  },
  {
    value: LegendValueEnum.PRO_NAME,
    id: LegendValueEnum.PRO_NAME,
    color: null,
  },
];

export const Y_PADDING_TOP = 20;
export const TRANSLATE_X = 12;

export const DOE_CHART_MIN_WIDTH = 400;
export const DOE_CHART_MIN_HEIGHT = 250;

export const DOE_CHART_AXES_COLORS = [
  {
    axisName: 'pro_name',
    color: 'rgb(3,167,232)',
  },
  {
    axisName: 'mat_name',
    color: colors.blue,
  },
  {
    axisName: 'pro_operator',
    color: colors.orange,
  },
  {
    axisName: 'pro_type',
    color: 'rgb(156,180,121)',
  },
  {
    axisName: 'mat_type',
    color: 'rgb(171,176,52)',
  },
  {
    axisName: 'type',
    color: 'rgb(232,111,196)',
  },
  {
    axisName: 'is_input',
    color: 'rgb(203,149,67)',
  },
  {
    axisName: 'datetime',
    color: 'rgb(196,165,235)',
  },
  {
    axisName: 'x',
    color: 'rgb(213,121,159)',
  },
  {
    axisName: 'y',
    color: 'rgb(237,159,69)',
  },
  {
    axisName: 'z',
    color: 'rgb(175,165,22)',
  },
  {
    axisName: 'file_name',
    color: 'rgb(246,136,159)',
  },
  {
    axisName: 'current_file_name',
    color: 'rgb(151,209,94)',
  },
  {
    axisName: 'pro_code',
    color: 'rgb(197,185,130)',
  },
  {
    axisName: 'pro_start_datetime',
    color: 'rgb(151,181,16)',
  },
  {
    axisName: 'pro_end_datetime',
    color: 'rgb(226,91,183)',
  },
  {
    axisName: 'pro_duration__min',
    color: 'rgb(170,224,161)',
  },
  {
    axisName: 'pro_site',
    color: 'rgb(118,137,77)',
  },
  {
    axisName: 'pro_experiment',
    color: 'rgb(51,239,88)',
  },
  {
    axisName: 'pro_team',
    color: 'rgb(254,114,112)',
  },
  {
    axisName: 'pro_file_name',
    color: 'rgb(145,116,157)',
  },
  {
    axisName: 'pro_description',
    color: 'rgb(155,120,246)',
  },
  {
    axisName: 'pro_version',
    color: 'rgb(254,220,218)',
  },
  {
    axisName: 'pro_instrument',
    color: 'rgb(110,243,237)',
  },
  {
    axisName: 'mat_maker',
    color: 'rgb(221,219,59)',
  },
  {
    axisName: 'mat_made_datetime',
    color: 'rgb(202,63,94)',
  },
  {
    axisName: 'mat_site',
    color: 'rgb(50,242,99)',
  },
  {
    axisName: 'mat_file_name',
    color: 'rgb(99,231,205)',
  },
  {
    axisName: 'mat_description',
    color: 'rgb(155,204,33)',
  },
  {
    axisName: 'mat_control_type',
    color: 'rgb(182,122,211)',
  },
  {
    axisName: 'mat_description',
    color: 'rgb(84,191,149)',
  },
  {
    axisName: 'mat_description',
    color: 'rgb(212,49,162)',
  },
];
