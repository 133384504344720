export interface Padding {
  left: number;
  right: number;
}

export interface XAxis {
  type: string;
  dataKey: string;
  name: string;
  domain: any[];
  allowDecimals: boolean;
  minTickGap: number;
  tickCount: number;
  interval: string;
  tickSize: number;
  hide: boolean;
  orientation: string;
  width: number;
  height: number;
  mirror: boolean;
  xAxisId: number;
  padding: Padding;
  allowDataOverflow: boolean;
  reversed: boolean;
  allowDuplicatedCategory: boolean;
  axisType: string;
  categoricalDomain: any[];
  originalDomain: any[];
  isCategorical: boolean;
  layout: string;
  realScaleType: string;
  x: number;
  y: number;
  bandSize: number;
}

export interface Padding2 {
  top: number;
  bottom: number;
}

export interface YAxis {
  type: string;
  dataKey: string;
  name: string;
  domain: number[];
  tickCount: number;
  allowDuplicatedCategory: boolean;
  allowDecimals: boolean;
  hide: boolean;
  orientation: string;
  width: number;
  height: number;
  mirror: boolean;
  yAxisId: number;
  padding: Padding2;
  allowDataOverflow: boolean;
  reversed: boolean;
  axisType: string;
  originalDomain: number[];
  isCategorical: boolean;
  layout: string;
  niceTicks: number[];
  realScaleType: string;
  x: number;
  y: number;
  bandSize: number;
}

export interface ZAxis {
  axisType: string;
  zAxisId: number;
  range: number[];
  type: string;
  hide: boolean;
  orientation?: any;
  domain: any[];
  isCategorical: boolean;
  layout: string;
  realScaleType: string;
  bandSize: number;
}

export interface Node {
  x: number;
  y: number;
  z: string;
}

export interface Payload {
  x: any;
  y: number;
  id: number;
}

export interface TooltipPayload {
  name: string;
  unit: string;
  value: number;
  payload: Payload;
  dataKey: string;
}

export interface TooltipPosition {
  x: number;
  y: number;
}

export interface Payload2 {
  x: number;
  y: number;
  id: number;
}

export enum LegendIconEnum {
  Line = 'line',
  Circle = 'circle',
}

export enum LegendValueEnum {
  Measurement = 'Measurement',
  UCL = 'UCL',
  MEAN = 'Mean',
  Median = 'Median',
  LCL = 'LCL',
  Outlier = 'Outlier',
}

export interface ScatterLegendItem {
  value: LegendValueEnum;
  type: LegendIconEnum;
  color: string;
}

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export type ScatterChartAxesBasesType = {
  xAxis: {
    label: string;
    name: string;
  }[];
  yAxis: {
    label: string;
    name: string;
  }[];
};
