import clsx from 'clsx';

import { makeStyles } from '@mui/styles';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material/';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'fixed',
    width: '4px',
    height: '4px',
    top: '37px',
    zIndex: 1201,
    backgroundColor: '#fff !important',
    boxShadow: `0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2)`,
  },
  drawerOpen: {
    left: '247px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    left: '45px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

interface SwitchButtonProps {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}

const SwitchButton = ({
  open,
  handleDrawerClose,
  handleDrawerOpen,
}: SwitchButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(classes.iconButton, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      size="medium"
      onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
    >
      {open ? (
        <ChevronLeft style={{ width: 18, height: 18 }} />
      ) : (
        <ChevronRight style={{ width: 18, height: 18 }} />
      )}
    </IconButton>
  );
};

export default SwitchButton;
