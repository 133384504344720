import {
  ColumnApi,
  ColumnState,
  GridApi,
  IFilterParams,
  SortModelItem,
} from 'ag-grid-community';
import { format } from 'date-fns';
import * as R from 'ramda';

import { ActivityHistoryAction } from 'components/AccordionViewPanel/ActivityHistory';
import csvToJson from 'csvtojson';
import { AppDispatch } from 'hooks/reduxHooks';
import { fetchTable } from 'modules/GlobalActions';
import * as actionTypes from 'modules/GlobalActionsTypes';
import { ModalEventData, StackModalDataType } from 'modules/GlobalReducer';
import { fetchExploreTable } from 'modules/explore/ExploreActions';
import { TableOptionsType } from 'modules/related';
import { fetchRelatedTable } from 'modules/related/RelatedActions';
import { NavigateFunction } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { getExportFileName } from './agGrid';
import { ApiModelName, ExploreTable, ModelName } from './enum';

/**
 * Convert bytes to 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'.
 */
export const formatBytes = (bytes: number, decimals = 1, seperator = '') => {
  if (bytes === 0) return '0 Bytes';
  if (!bytes) return 'n/a';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + seperator + sizes[i]
  );
};

export const getFixedNumber = (number: number, fractionDigits = 3) =>
  +Number(number).toFixed(fractionDigits);

/**
 * Get URL with params.
 */
export const getUrlWithParams = (url: string, params: Record<string, any>) =>
  params
    ? Object.keys(params)
        .reduce((URL, param) => {
          return params[param] ? `${URL}${param}=${params[param]}&` : URL;
        }, `${url}?`)
        .slice(0, -1)
    : url;

/**
 * Capitalize the first letter.
 */
export const getCapitalizeWord = (word: string) =>
  word ? `${word[0].toUpperCase()}${word.slice(1)}` : '';

/**
 * Is the page active in the sidebar
 */
export const isActivePage = (
  pathname: string,
  pageName: string,
  isOrganizationActive: boolean,
) => {
  if (!pathname || !pageName) return 0;

  const isTypesPage = pageName.includes('Type');
  const isRelated = pathname.includes('related');

  return isRelated
    ? getNormalizeModelName(
        pathname.split('/')[isOrganizationActive ? 3 : 2] as
          | ModelName
          | ApiModelName,
      ) === getNormalizeModelName(pageName as ModelName | ApiModelName)
    : isTypesPage
      ? Number(pathname.includes(pageName))
      : Number(pathname.includes(pageName) && !pathname.includes('Type'));
};

/**
 * Get string with capitalized first letter
 */
export const capitalizedFirstLetter = (str?: string) =>
  str ? `${str[0].toUpperCase()}${str.slice(1)}` : '';

/**
 * Get string with capitalized first letter
 */
export const convertSelectOption = (str: string) =>
  str ? `${str[0].toUpperCase()}${str.slice(1)}`.replace('_', ' ') : '';

/**
 * Get formatted date  for API
 */
export const getFormattedDateForAPI = (date: string) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : undefined;

/**
 * Is the name of the "pill" for the search page "Show Voided"
 */
export const isPageWithVoid = (pathname: string): boolean => {
  const regexPageWithVoid = /material|process|measurement|program/;
  return regexPageWithVoid.test(pathname) && !pathname.includes('Type');
};

export const isPageWithReactivate = (pathname: string): boolean => {
  const regexPageWithReactivate =
    /experiment|materialType|processType|measurementType|controlType|site|team|instrument|user/;
  return regexPageWithReactivate.test(pathname);
};

/**
 * This is the files page.
 */
export const isFilesPage = (pathname: string): boolean =>
  pathname ? pathname.includes('program') : false;

/**
 * Get title for explore page.
 */
export const getModelNameInSingPlurForm = (modelName: ModelName, count = 0) => {
  const SingularPluralMap: Record<ModelName, any> = {
    material: {
      singular: 'Material',
      plural: 'Materials',
    },
    process: {
      singular: 'Process',
      plural: 'Processes',
    },
    measurement: {
      singular: 'Measurement',
      plural: 'Measurements',
    },
    experiment: {
      singular: 'Experiment',
      plural: 'Experiments',
    },
    program: {
      singular: 'File',
      plural: 'Files',
    },
    feedback: {
      singular: 'Feedback',
      plural: 'Feedback',
    },
    message: {
      singular: 'Message',
      plural: 'Messages',
    },
    directive: {
      singular: 'Directive',
      plural: 'Directives',
    },
    materialType: {
      singular: 'Material Type',
      plural: 'Material Types',
    },
    processType: {
      singular: 'Process Type',
      plural: 'Process Types',
    },
    measurementType: {
      singular: 'Measurement Type',
      plural: 'Measurement Types',
    },
    controlType: {
      singular: 'Control Type',
      plural: 'Control Types',
    },
    user: {
      singular: 'User',
      plural: 'Users',
    },
    team: {
      singular: 'Team',
      plural: 'Teams',
    },
    site: {
      singular: 'Site',
      plural: 'Sites',
    },
    instrument: {
      singular: 'Instrument',
      plural: 'Instruments',
    },
    version: {
      singular: 'Version',
      plural: 'Version',
    },
    branch: {
      singular: 'Branch',
      plural: 'Branch',
    },
    organizationSettings: {
      singular: 'Organization Settings',
      plural: 'Organization Settings',
    },
    cell: {
      singular: 'Cell',
      plural: 'Cells',
    },
    filesMatrix: {
      singular: 'Files Matrix',
      plural: 'Files Matrixes',
    },
  };

  return count > 1
    ? SingularPluralMap[modelName]?.plural
    : SingularPluralMap[modelName]?.singular;
};

export const isNotConvertToNumber = (value: any) =>
  !Boolean(Number(value)) && Number(value) !== 0;

export const getIds = (x: Array<Record<string, any>> = []) => {
  const arrIds: number[] = [];
  x.reduce((ids, curX) => {
    if (curX?.db_id) {
      arrIds.push(curX.db_id as number);
    }
    return ids;
  }, []);
  return arrIds;
};

/**
 * Return model for multipleView without empty values in array properties
 */
export const parsedModel = (
  model: Record<string, any[]>,
): Record<string, any> =>
  Object.fromEntries(
    Object.entries(model).map((entries) => {
      return [
        entries[0],
        entries[1] && Array.isArray(entries[1])
          ? entries[1].filter(Boolean)
          : entries[1],
      ];
    }),
  );

export const getIdsArrayFromParams = (idsFromParam: string) =>
  idsFromParam && typeof idsFromParam === 'string'
    ? idsFromParam
        .replaceAll('.', ',')
        .split(',')
        .map((x) => +x)
    : [];

type columnHasInSetFilterType = {
  columnApi?: ColumnApi;
  columnName?: string;
  filterParams?: IFilterParams;
};
export const columnHasInSetFilter = ({
  filterParams,
}: columnHasInSetFilterType) =>
  filterParams?.colDef?.filterParams?.filterOptions?.some(
    (f: Record<string, unknown>) => f?.displayKey === 'inSet',
  );

type checkIsIntColumnType = {
  api: GridApi;
  columnName: string;
};
export const checkIsIntColumn = ({ api, columnName }: checkIsIntColumnType) =>
  api
    ?.getColumn(columnName)
    ?.getColDef()
    .filterParams.filterOptions?.some(
      (f: Record<string, string> | string) => f === 'greaterThanOrEqual', // only Int column has this option
    );

export const getNormalizedSSRFilterModel = (api: GridApi) => {
  const filterModel = api.getFilterModel();

  Object.keys(filterModel).forEach((columnName: string) => {
    /**
     * For column with type Int the default was left filterType: 'text'.
     * The presence of the 'inSet' filter indicates that the column with Int data.
     * For such columns we change filterType for API: filterType = 'number'
     */
    const columnFilter = filterModel[columnName];
    const isIntColumn = checkIsIntColumn({ api, columnName });
    const isBooleanFilter =
      api.getColumn(columnName)?.getColDef().filter === 'agSetColumnFilter';

    if (isIntColumn) {
      columnFilter.filterType = 'number';

      // remove the allowed char at the end of the line
      if (
        !columnFilter.operator &&
        (columnFilter?.filter[columnFilter.filter?.length - 1] === ',' ||
          columnFilter?.filter[columnFilter.filter?.length - 1] === '-')
      ) {
        columnFilter.filter = columnFilter.filter?.slice(0, -1);
      }

      if (columnFilter.operator && isIntColumn) {
        if (columnFilter.condition1) {
          columnFilter.condition1.filterType = 'number';
        }
        if (columnFilter.condition2) {
          columnFilter.condition2.filterType = 'number';
        }
      }
    }

    if (isBooleanFilter) {
      columnFilter.values = columnFilter.values.map((v: string) =>
        v === 'null' ? null : v,
      );
    }
  });

  // API does not accept field 'conditions' and throws an 500 error
  // Modify filterModel if it exists in params
  const newFilterModel = filterModel
    ? Object.fromEntries(
        Object.entries(filterModel).map(([columnName, filter]) => {
          if (typeof filter === 'object' && filter !== null) {
            const newFilter = { ...filter };
            if ('conditions' in newFilter) {
              delete newFilter.conditions;
            }
            return [columnName, newFilter];
          }
          return [columnName, filter];
        }),
      )
    : undefined;

  return newFilterModel;
};

export const openNewTab = (pathname: string) => {
  const win = window.open(pathname, '_blank');
  win?.focus();
};

export const closeModal = (
  navigate: NavigateFunction,
  modelName: ModelName,
  organizationNamespace?: string,
) => {
  const pathWithOrg = organizationNamespace
    ? `/${organizationNamespace}/${modelName}`
    : `/${modelName}`;

  navigate(pathWithOrg);
};

export const isNotMultipleElement = (arr: Array<string | number>) =>
  arr?.length <= 1;

export const getDirtyFieldsObj = (
  obj: Record<string, any>,
  dirtyObj: Record<string, any>,
) => {
  const dirtySet = new Set(Object.keys(dirtyObj));
  return Object.keys(obj).reduce(
    (dirtyFieldsObj: Record<string, any>, key: string) => {
      if (dirtySet.has(key)) {
        dirtyFieldsObj[key] = obj[key] || null;
      }
      return dirtyFieldsObj;
    },
    {},
  );
};

/**
 *  After creating / editing a record, if we are on the search page of the same model, we need to update the table
 */
export const refreshPage = (
  pathname: string,
  modalData:
    | ModalEventData
    | StackModalDataType
    | { gridApi: GridApi | null; modelName: ModelName },
) => {
  const { modelName, gridApi } = modalData;
  const isModelNameHasWordType = modelName?.includes('Type');
  const modalOpenOwnSearchPage = isModelNameHasWordType
    ? pathname?.includes(modelName)
    : pathname?.includes(modelName) && !pathname?.includes('Type');
  if (modalOpenOwnSearchPage) {
    return gridApi ? gridApi.refreshServerSide({}) : window.location.reload();
  }
};

/**
 *  Get filter's value from CustomDateFilter for text input
 */
const formatDate = (date: string | null) => (date ? date.split(' ')[0] : '');

export const getDateFilterValue = (filterModel: Record<string, any> | null) => {
  const firstCondition = {
    dateFrom: formatDate(filterModel?.condition1?.dateFrom),
    dateTo: formatDate(filterModel?.condition1?.dateTo),
  };
  const operator = filterModel?.operator;
  const secondCondition = {
    dateFrom: formatDate(filterModel?.condition2?.dateFrom),
    dateTo: formatDate(filterModel?.condition2?.dateTo),
  };
  const cond1DateTo = firstCondition.dateTo ? `/ ${firstCondition.dateTo}` : '';
  const cond2DateTo = secondCondition.dateTo
    ? `/ ${secondCondition.dateTo}`
    : '';

  const isOneRangeCondition = filterModel?.dateFrom && filterModel?.dateTo;

  if (isOneRangeCondition) {
    return `${formatDate(filterModel.dateFrom)} / ${formatDate(
      filterModel.dateTo,
    )}`;
  }

  return `${firstCondition.dateFrom} ${cond1DateTo} ${operator} ${secondCondition.dateFrom} ${cond2DateTo} `;
};

export const getCanceledModelName = (
  model: ModelName | ApiModelName,
): ApiModelName => {
  let currentModel;
  switch (model) {
    case ModelName.Material:
    case ApiModelName.KMAT:
      currentModel = ApiModelName.WMAT;
      break;
    case ModelName.Process:
    case ApiModelName.KPRO:
      currentModel = ApiModelName.WPRO;
      break;
    case ModelName.Measurement:
    case ApiModelName.KMEA:
      currentModel = ApiModelName.WMEA;
      break;
    case ModelName.Experiment:
    case ApiModelName.KEXP:
      currentModel = ApiModelName.WEXP;
      break;
    case ModelName.Program:
    case ApiModelName.KFIL:
      currentModel = ApiModelName.WFIL;
      break;
    case ModelName.MaterialType:
    case ApiModelName.KMTT:
      currentModel = ApiModelName.WMTT;
      break;
    case ModelName.ProcessType:
    case ApiModelName.KPRT:
      currentModel = ApiModelName.WPRT;
      break;
    case ModelName.MeasurementType:
    case ApiModelName.KMST:
      currentModel = ApiModelName.WMST;
      break;
    case ModelName.ControlType:
    case ApiModelName.KCNT:
      currentModel = ApiModelName.WCNT;
      break;
    case ModelName.User:
    case ApiModelName.KUSR:
      currentModel = ApiModelName.WUSR;
      break;
    case ModelName.Team:
    case ApiModelName.KTEA:
      currentModel = ApiModelName.WTEA;
      break;
    case ModelName.Site:
    case ApiModelName.KSIT:
      currentModel = ApiModelName.WSIT;
      break;
    case ModelName.Instrument:
    case ApiModelName.KINS:
      currentModel = ApiModelName.WINS;
      break;
  }
  return currentModel as ApiModelName;
};

export const getNormalizeModelName = (
  model: ModelName | ApiModelName,
): ModelName => {
  let currentModel;
  switch (model) {
    case ModelName.Material:
    case ApiModelName.KMAT:
    case ApiModelName.WMAT:
      currentModel = ModelName.Material;
      break;
    case ModelName.Process:
    case ApiModelName.KPRO:
    case ApiModelName.WPRO:
      currentModel = ModelName.Process;
      break;
    case ModelName.Measurement:
    case ApiModelName.KMEA:
    case ApiModelName.WMEA:
      currentModel = ModelName.Measurement;
      break;
    case ModelName.Experiment:
    case ApiModelName.KEXP:
    case ApiModelName.WEXP:
      currentModel = ModelName.Experiment;
      break;
    case ModelName.Program:
    case ApiModelName.KFIL:
    case ApiModelName.WFIL:
      currentModel = ModelName.Program;
      break;
    case ModelName.MaterialType:
    case ApiModelName.KMTT:
    case ApiModelName.WMTT:
      currentModel = ModelName.MaterialType;
      break;
    case ModelName.ProcessType:
    case ApiModelName.KPRT:
    case ApiModelName.WPRT:
      currentModel = ModelName.ProcessType;
      break;
    case ModelName.MeasurementType:
    case ApiModelName.KMST:
    case ApiModelName.WMST:
      currentModel = ModelName.MeasurementType;
      break;
    case ModelName.ControlType:
    case ApiModelName.KCNT:
    case ApiModelName.WCNT:
      currentModel = ModelName.ControlType;
      break;
    case ModelName.User:
    case ApiModelName.KUSR:
    case ApiModelName.WUSR:
      currentModel = ModelName.User;
      break;
    case ModelName.Team:
    case ApiModelName.KTEA:
    case ApiModelName.WTEA:
      currentModel = ModelName.Team;
      break;
    case ModelName.Site:
    case ApiModelName.KSIT:
    case ApiModelName.WSIT:
      currentModel = ModelName.Site;
      break;
    case ModelName.Instrument:
    case ApiModelName.KINS:
    case ApiModelName.WINS:
      currentModel = ModelName.Instrument;
      break;
  }

  return currentModel as ModelName;
};

export const getApiModelNameFromModelName = (
  model: ModelName,
  canceled?: boolean,
): ApiModelName => {
  let currentModel;
  switch (model) {
    case ModelName.Material:
      currentModel = canceled ? ApiModelName.WMAT : ApiModelName.KMAT;
      break;
    case ModelName.Process:
      currentModel = canceled ? ApiModelName.WPRO : ApiModelName.KPRO;
      break;
    case ModelName.Measurement:
      currentModel = canceled ? ApiModelName.WMEA : ApiModelName.KMEA;
      break;
    case ModelName.Experiment:
      currentModel = canceled ? ApiModelName.WEXP : ApiModelName.KEXP;
      break;
    case ModelName.Program:
      currentModel = canceled ? ApiModelName.WFIL : ApiModelName.KFIL;
      break;
    case ModelName.MaterialType:
      currentModel = canceled ? ApiModelName.WMTT : ApiModelName.KMTT;
      break;
    case ModelName.ProcessType:
      currentModel = canceled ? ApiModelName.WPRT : ApiModelName.KPRT;
      break;
    case ModelName.MeasurementType:
      currentModel = canceled ? ApiModelName.WMST : ApiModelName.KMST;
      break;
    case ModelName.ControlType:
      currentModel = canceled ? ApiModelName.WCNT : ApiModelName.KCNT;
      break;
    case ModelName.User:
      currentModel = canceled ? ApiModelName.WUSR : ApiModelName.KUSR;
      break;
    case ModelName.Team:
      currentModel = canceled ? ApiModelName.WTEA : ApiModelName.KTEA;
      break;
    case ModelName.Site:
      currentModel = canceled ? ApiModelName.WSIT : ApiModelName.KSIT;
      break;
    case ModelName.Instrument:
      currentModel = canceled ? ApiModelName.WINS : ApiModelName.KINS;
      break;
    case ModelName.Cell:
      currentModel = canceled ? ApiModelName.WCEL : ApiModelName.KCEL;
      break;
    case ModelName.FilesMatrix:
      currentModel = canceled ? ApiModelName.WFILMTRX : ApiModelName.KFILMTRX;
      break;

    default:
      currentModel = ApiModelName.KFIL;
  }

  return currentModel;
};

export const getCanceledQueryParams = (search: string) => {
  const url = new URLSearchParams(search);
  const showVoided = url.has('void');
  const showInactivated = url.has('inactive');

  return {
    showVoided,
    showInactivated,
    fetchCanceled: showVoided || showInactivated,
  };
};

export const getActiveModelNameQueryParams = (
  search: string,
): ModelName | null => {
  const url = new URLSearchParams(search);
  const modelName = (url.get('_active') as ModelName) || null;

  return modelName;
};

export const isSearchURLContainsFilters = (
  search: string,
  tableNames?: Array<string>,
): boolean => {
  if (tableNames) {
    search = tableNames
      .map((curTableName: string, index: number) => {
        const regex = new RegExp(
          `(_table=${curTableName}(?:&(?!_table)[^&]+)*)`,
        );
        const filters =
          search
            .match(regex)?.[0]
            .replace(
              new RegExp(`_table=${curTableName}&?`),
              index ? '&' : '?',
            ) ?? '';

        return filters;
      })
      .filter(Boolean)
      .join();
  }

  const url = new URLSearchParams(search);
  const modelName = (url.get('_active') as ModelName) || null;
  const voidedOption = url.get('voided');

  const size = (url as any).size;

  let contains = true;

  if (
    size === 0 ||
    ((modelName || voidedOption) && size === 1) ||
    (size === 2 && modelName && voidedOption)
  ) {
    contains = false;
  }

  return contains;
};

export const getActivityHistoryText = (
  action: ActivityHistoryAction,
  context: string | null,
) =>
  action === ActivityHistoryAction.Created
    ? 'Created'
    : action === ActivityHistoryAction.Reactivated
      ? 'Reactivated'
      : action === ActivityHistoryAction.Voided
        ? `Voided: ${context}`
        : action === ActivityHistoryAction.Inactivated
          ? `Inactivated: ${context}`
          : (context ?? '');

export const addTargetBlankToLinks = (htmlText?: string) =>
  htmlText
    ? htmlText.replace(
        /<(a+)(.*?)(href=("|')https)/g,
        (match: string) =>
          match.substring(0, 2) + ' target="_blank" ' + match.substring(3),
      )
    : '';

export const getLinksToUpdate = (
  links: Record<string, any>,
  initialLinks: Record<string, any>,
) => {
  const linksToCreate = Object.keys(links).reduce(
    (acc: Record<string, string>[], groupName) => {
      const currentLinksGroup = links[groupName];

      currentLinksGroup.forEach((linkInfo: Record<string, string>) => {
        if (linkInfo.temp_id) {
          return acc.push(R.omit(['temp_id'], linkInfo));
        }
      });

      return acc;
    },
    [],
  );

  const isLinksEmpty =
    Object.keys(initialLinks).length === 0 || Object.keys(links).length === 0;
  let linksToDelete: Record<string, any>[] = [];

  if (!isLinksEmpty) {
    linksToDelete = Object.keys(initialLinks).reduce(
      (acc: Record<string, string>[], groupName) => {
        const currentLinksGroup = initialLinks[groupName];

        currentLinksGroup.forEach((linkInfo: Record<string, string>) => {
          if (!links[groupName].find((x: any) => x.db_id === linkInfo.db_id)) {
            return acc.push(linkInfo);
          }
        });

        return acc;
      },
      [],
    );
  }

  return {
    linksToDelete,
    linksToCreate,
  };
};

export const checkIsBlockNameLong = (name: string, isBlockExpanded: boolean) =>
  name.length > (isBlockExpanded ? 23 : 32);

export const getTruncatedNameForBlockModal = (
  name: string,
  isBlockExpanded: boolean,
) => {
  const lengthToTruncate = isBlockExpanded ? 23 : 32;
  const shouldTtruncated = checkIsBlockNameLong(name, isBlockExpanded);
  const lastChars = name.slice(name.length - 5);
  const truncatedName = name.slice(0, lengthToTruncate - 5);

  return shouldTtruncated ? `${truncatedName}...${lastChars}` : name;
};

export const downloadBlob = (
  content: string,
  filename: string,
  contentType: string,
) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // console.log(content);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

export const tableExportHttp = async (
  dispatch: AppDispatch,
  api: GridApi,
  columnApi: ColumnApi,
  modelApi: string,
  modelName: string,
  pathname: string,
  name: string,
  format: string,
  relatedOptions?: {
    sourceModel: ModelName;
    fetchCanceled: boolean;
    idsFromParam: number[];
    tableOptions: TableOptionsType;
  },
  exporeOptions?: {
    material_db_ids: number[];
    process_db_ids: number[];
  },
) => {
  const sortModel = columnApi
    .getColumnState()
    .reduce((resModel: Array<SortModelItem>, { sort, colId }: ColumnState) => {
      if (sort && colId) {
        resModel.push({ colId, sort });
      }
      return resModel;
    }, []);

  const isBfdPage = pathname.includes('bfd');
  const isRelatedPage = pathname.includes('related');

  dispatch(
    isRelatedPage
      ? fetchRelatedTable({
          sourceModel: relatedOptions?.sourceModel as ModelName,
          destModel: modelApi as ModelName,
          fetchCanceled: Boolean(relatedOptions?.fetchCanceled),
          shouldUpdateState: false,
          params: {
            filterModel: getNormalizedSSRFilterModel(api),
            sortModel,
            startRow: 0,
            endRow: 5000,
            return_csv: true,
            db_ids: relatedOptions?.idsFromParam,
            ...relatedOptions?.tableOptions,
          },
        })
      : isBfdPage
        ? fetchExploreTable({
            modelApi: modelApi as any,
            shouldUpdateState: false,
            params: {
              material_db_ids: exporeOptions?.material_db_ids,
              process_db_ids: exporeOptions?.process_db_ids,
              filterModel: getNormalizedSSRFilterModel(api),
              sortModel,
              startRow: 0,
              endRow: 5000,
              return_csv: true,
            },
          })
        : fetchTable({
            params: {
              filterModel: getNormalizedSSRFilterModel(api),
              sortModel,
              startRow: 0,
              endRow: 5000,
              return_csv: true,
            },
            modelApi: modelApi as any,
            modelName,
            shouldUpdateState: false,
          }),
  ).then(async (res) => {
    const data = res?.payload;

    if (data) {
      const csv = data?.data?.csv ? data.data.csv : data.csv;

      const fileName = getExportFileName(
        pathname,
        name,
        '.max5000rows',
        modelApi as ExploreTable,
      );

      if (format === 'csv') {
        downloadBlob(
          csv.substring(csv.indexOf(',db_id')),
          `${fileName}.csv`,
          'text/csv;charset=utf-8;',
        );
      } else {
        const json = await csvToJson().fromString(
          '№' + csv.substring(csv.indexOf(',db_id')),
        );
        const ws = XLSX.utils.json_to_sheet(json);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, name, true);

        const res = XLSX.write(wb, { type: 'array' });

        downloadBlob(
          res,
          `${fileName}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        );
      }
    }
  });
};

export const replaceAmpSign = (value: string | string[]) => {
  const isArray = Array.isArray(value);
  if (isArray) {
    return value.map((x) => (value ? x.replaceAll('&amp;', '&') : value));
  } else {
    return value ? value.replaceAll('&amp;', '&') : value;
  }
};

export const encodeEachParamForUrl = (params: Record<string, any>) => {
  const paramKeys = Object.keys(params);

  if (paramKeys.length === 0) {
    return '';
  }

  return paramKeys.reduce<Record<string, string>>((acc, val) => {
    return {
      ...acc,
      [val]: encodeURI(params[val]),
    };
  }, {});
};

export const transformObjectToSearchParams = (params: Record<string, any>) => {
  const paramKeys = Object.keys(params);

  if (paramKeys.length === 0) {
    return '';
  }

  return paramKeys.reduce((acc, val, i) => {
    if (paramKeys.length === 1 || i === 0) {
      return `${val}=${params[val]}`;
    } else {
      return `${acc}&${val}=${params[val]}`;
    }
  }, '');
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const onOrganizationChanged = (dispatch: AppDispatch) => {
  dispatch({
    type: actionTypes.CLEAR_FILES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_SITES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_INSTRUMENTS_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_TEAMS_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_USERS_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_MATERIAL_TYPES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_PROCESS_TYPES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_MEASUREMENT_TYPES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_CONTROL_TYPES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_MATERIALS_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_PROCESSES_TABLE,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_FEEDBACK_DATA,
    payload: {},
  });
  dispatch({
    type: actionTypes.CLEAR_MEASUREMENTS_TABLE,
    payload: {},
  });
};
