import * as R from 'ramda';

import { ExploreTable } from 'utils/enum';
import {
  BLockModalInfoType,
  BlocksModalInfo,
  STATE_KEY as EXPLORE_STATE_KEY,
  InitialExploreStateType,
} from 'modules/explore/ExploreReducer';

export const getBFD = (
  state: InitialExploreStateType,
): InitialExploreStateType['BFD'] =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'BFD'], state);

export const getOrientingTable = (
  state: InitialExploreStateType,
): InitialExploreStateType['orientingTable'] =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'orientingTable'], state);

export const getActivesControlsOfDiagram = (
  state: InitialExploreStateType,
): InitialExploreStateType['activesControlsOfDiagram'] =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'activesControlsOfDiagram'], state);

export const getExploreTables = (
  state: InitialExploreStateType,
): InitialExploreStateType['tables'] =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'tables'], state);

export const getBlocksModalInfo = (
  state: InitialExploreStateType,
): BlocksModalInfo =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'blocksModalInfo'], state);

export const getBlockModalInfo = (
  state: InitialExploreStateType,
): BLockModalInfoType =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'blockModalInfo'], state);

export const getExploreTable = (
  state: InitialExploreStateType,
  modelApi?: ExploreTable,
): InitialExploreStateType['tables'][ExploreTable] =>
  R.pathOr('', [EXPLORE_STATE_KEY, 'tables', [modelApi]], state);
