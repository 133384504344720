export const getExpirationDate = (expiresIn: number) =>
  expiresIn ? new Date(new Date().getTime() + expiresIn * 1000).toString() : '';

export const isTokenExpired = (expirationTokenDate: string | null): boolean =>
  expirationTokenDate
    ? new Date(expirationTokenDate).getTime() <= new Date().getTime()
    : true;

export const getSanitizedUserPermissionList = (permString: string) => {
  return permString.split(',').map((perm) => {
    const sanitizedStr = perm
      .split('')
      .filter((char) => {
        return /^[A-Za-z]+$/.test(char);
      }, '')
      .join('');
    return sanitizedStr;
  });
};
