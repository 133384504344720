import loadable from '@loadable/component';
import { Outlet, Route, Routes } from 'react-router-dom';
import Preloader from 'components/Preloader/Preloader';
import ConfiguredRoute from 'components/ConfiguredRoute';

const AccountSettingsPage = loadable(
  () =>
    import(
      './modules/landing/pages/AccountSettingsPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const OrganizationSettingsPage = loadable(
  () =>
    import(
      './modules/landing/pages/OrganizationSettingsPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const ModalModelContainer = loadable(
  () => import('./containers/ModalModelContainer' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const ModalModelContainerWrapper = loadable(
  () =>
    import(
      './containers/ModalModelContainerWrapper' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const LandingPage = loadable(
  () =>
    import('./modules/landing/pages/LandingPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const FeedbackPage = loadable(
  () =>
    import('./modules/landing/pages/FeedbackPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const VersionPage = loadable(
  () =>
    import('./modules/landing/pages/VersionPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const MaterialsPage = loadable(
  () =>
    import('./modules/landing/pages/MaterialsPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const ExperimentsPage = loadable(
  () =>
    import(
      './modules/landing/pages/ExperimentsPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const ExplorePage = loadable(
  () =>
    import('./modules/landing/pages/ExplorePage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const RelatedPage = loadable(
  () =>
    import('./modules/landing/pages/RelatedPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const ProcessesPage = loadable(
  () =>
    import('./modules/landing/pages/ProcessesPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const MeasurementsPage = loadable(
  () =>
    import(
      './modules/landing/pages/MeasurementsPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const MaterialTypesPage = loadable(
  () =>
    import(
      './modules/landing/pages/MaterialTypesPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const ProcessTypesPage = loadable(
  () =>
    import(
      './modules/landing/pages/ProcessTypesPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const MeasurementTypesPage = loadable(
  () =>
    import(
      './modules/landing/pages/MeasurementTypesPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const ControlTypesPage = loadable(
  () =>
    import(
      './modules/landing/pages/ControlTypesPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const UsersPage = loadable(
  () => import('./modules/landing/pages/UsersPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const TeamsPage = loadable(
  () => import('./modules/landing/pages/TeamsPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const SitesPage = loadable(
  () => import('./modules/landing/pages/SitesPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const InstrumentsPage = loadable(
  () =>
    import(
      './modules/landing/pages/InstrumentsPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const ApiDocsPage = loadable(
  () => import('./modules/landing/pages/ApiDocsPage'),
  {
    fallback: <Preloader />,
  },
);

const DataFilesPage = loadable(
  () =>
    import('./modules/landing/pages/DataFilesPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const CancellationPage = loadable(
  () =>
    import(
      './modules/landing/pages/CancellationPage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const ReactivatePage = loadable(
  () =>
    import(
      './modules/landing/pages/ReactivatePage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const OriginalFile = loadable(
  () =>
    import(
      './modules/landing/pages/OriginalFilePage' /* webpackPrefetch: true */
    ),
  { fallback: <Preloader /> },
);

const NotFoundPage = loadable(
  () =>
    import('./modules/landing/pages/NotFoundPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const ChangePasswordPage = loadable(
  () => import('./modules/auth/pages/ChangePasswordPage'),
  {
    fallback: <Preloader />,
  },
);
const ForgotPasswordPage = loadable(
  () => import('./modules/auth/pages/ForgotPasswordPage'),
  {
    fallback: <Preloader />,
  },
);
const PasswordResetSentPage = loadable(
  () => import('./modules/auth/pages/PasswordResetSentPage'),
  {
    fallback: <Preloader />,
  },
);
const UpdatePasswordPage = loadable(
  () => import('./modules/auth/pages/UpdatePasswordPage'),
  {
    fallback: <Preloader />,
  },
);

const PreviewFilePage = loadable(() => import('./modules/previewFile'), {
  fallback: <Preloader />,
});

const CellsPage = loadable(
  () => import('./modules/landing/pages/CellsPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);
const FilesPage = loadable(
  () => import('./modules/landing/pages/FilesPage' /* webpackPrefetch: true */),
  { fallback: <Preloader /> },
);

const RoutesWrapper: React.FC<{ basePath: string }> = ({ basePath }) => (
  <Routes>
    <Route
      path={basePath}
      element={
        <div>
          <Outlet />
        </div>
      }
    >
      <Route path="" element={<LandingPage />} />
      <Route path="bfd/:model/:ids" element={<ExplorePage />} />
      <Route path="docs" element={<ApiDocsPage />} />
      <Route path="related/:source/:dest/:ids" element={<RelatedPage />} />

      <Route path=":model/cancel/:ids" element={<CancellationPage />} />
      <Route path=":model/uncancel/:ids" element={<ReactivatePage />} />
      <Route path=":model/original-file/:ids" element={<OriginalFile />} />

      <Route path=":action/:modelName" element={<ModalModelContainerWrapper />}>
        <Route path=":id" element={<ModalModelContainer />} />
      </Route>

      <Route
        path="bfd/:action/:modelName"
        element={<ModalModelContainer isFromBfd />}
      >
        <Route path=":id" element={<ModalModelContainer />} />
      </Route>

      <Route path="account-settings" element={<AccountSettingsPage />} />
      <Route
        path="organization-settings"
        element={
          <ConfiguredRoute conditionField="showOrgSettings" isToSatisfy={false}>
            <OrganizationSettingsPage />
          </ConfiguredRoute>
        }
      />

      <Route path="feedback/:id" element={<FeedbackPage />} />
      <Route path="version" element={<VersionPage />} />

      <Route path="material" element={<MaterialsPage />} />
      <Route path="measurement" element={<MeasurementsPage />} />
      <Route path="process" element={<ProcessesPage />} />

      <Route path="experiment" element={<ExperimentsPage />} />
      <Route path="controlType" element={<ControlTypesPage />} />
      <Route path="user" element={<UsersPage />} />
      <Route path="team" element={<TeamsPage />} />
      <Route path="site" element={<SitesPage />} />
      <Route path="instrument" element={<InstrumentsPage />} />
      <Route path="measurementType" element={<MeasurementTypesPage />} />
      <Route path="processType" element={<ProcessTypesPage />} />
      <Route path="materialType" element={<MaterialTypesPage />} />
      <Route path="cell" element={<CellsPage />} />
      <Route path="filesMatrix" element={<FilesPage />} />

      <Route path="program" element={<DataFilesPage />} />

      <Route path="auth/change-password" element={<ChangePasswordPage />} />
      <Route path="auth/forgot-password" element={<ForgotPasswordPage />} />
      <Route
        path="auth/password-reset-sent"
        element={<PasswordResetSentPage />}
      />
      <Route path="auth/confirmforgot" element={<UpdatePasswordPage />} />
      <Route path="program/file" element={<PreviewFilePage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default RoutesWrapper;
