import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { store } from 'store/configureStore';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<any, undefined, AnyAction> &
  Dispatch<AnyAction>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<any> = useSelector;
