import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const activityHistoryIcons = {
  Created: {
    icon: <AddCircleOutlineIcon color="success" />,
    color: '#5cb85c',
  },
  Updated: {
    icon: <EditIcon color="warning" />,
    color: '#f0ad4e',
  },
  Reactivated: {
    icon: <AddCircleOutlineIcon color="success" />,
    color: '#5cb85c',
  },
  'Seen in': {
    icon: <FindInPageIcon color="primary" />,
    color: '#0275d8',
  },
  Voided: {
    icon: <HighlightOffIcon color="error" />,
    color: '#d9534f',
  },
  Inactivated: {
    icon: <HighlightOffIcon color="error" />,
    color: '#d9534f',
  },
};
