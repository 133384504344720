import { IconProps } from './types';

const MpmParentIcon = ({
  color = '#000',
  size = { width: 24, height: 24 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icons/ic/mpm-parent</title>
    <g
      id="icons/ic/mpm-parent"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      fillOpacity="0.87"
    >
      <path
        d="M12,6 C15.3137085,6 18,8.6862915 18,12 C18,15.3137085 15.3137085,18 12,18 C9.77955047,18 7.84081563,16.793836 6.80325037,15.000963 L8.20413192,15.0010641 C9.09038608,16.1205152 10.4613214,16.8387097 12,16.8387097 C14.6723456,16.8387097 16.8387097,14.6723456 16.8387097,12 C16.8387097,9.32765444 14.6723456,7.16129032 12,7.16129032 C10.4608643,7.16129032 9.08957152,7.87991159 8.20334216,8.99993372 L6.80267039,9.00003939 C7.84009304,7.20661355 9.77913667,6 12,6 Z M11,8 L15,12 L11,16 L11,13 L0,13 L0,11 L11,11 L11,8 Z"
        id="Combined-Shape"
        fill={color}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default MpmParentIcon;
