import loadable from '@loadable/component';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { Modal } from 'components/Modal';
import { OrganizationDataType } from 'components/SelectOrganization/SelectOrganizationReducer';
import { getOrganizationsData } from 'components/SelectOrganization/SelectOrganizationSelector';
import {
  clearAllStackModalData,
  clearCreatedStateModelItem,
  clearQueueModalState,
  clearStackModalData,
  setStackModalData,
} from 'modules/GlobalActions';
import { ModalAction } from 'modules/GlobalReducer';
import {
  getModelItem,
  getQueueModalInfo,
  getStackModalData,
} from 'modules/GlobalSelectors';
import { clearBlockModalData } from 'modules/explore/ExploreActions';
import { getBlockModalInfo } from 'modules/explore/ExploreSelectors';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ModelName } from 'utils/enum';
import {
  capitalizedFirstLetter,
  getIdsArrayFromParams,
  refreshPage,
} from 'utils/utils';

const Material = loadable(() => import('modules/materials/Material'));
const Process = loadable(() => import('modules/processes/Process'));
const Measurement = loadable(() => import('modules/measurements/Measurement'));
const Experiment = loadable(() => import('modules/experiments/Experiment'));
const File = loadable(() => import('modules/files/File'));
const MaterialType = loadable(
  () => import('modules/materialTypes/MaterialType'),
);
const ProcessType = loadable(() => import('modules/processTypes/ProcessType'));
const MeasurementType = loadable(
  () => import('modules/measurementTypes/MeasurementType'),
);
const ControlType = loadable(() => import('modules/controlTypes/ControlType'));
const User = loadable(() => import('modules/users/User'));
const Team = loadable(() => import('modules/teams/Team'));
const Site = loadable(() => import('modules/sites/Site'));
const Instrument = loadable(() => import('modules/instruments/Instrument'));

export type HandleCloseModalArgs = {
  resetStackInfo?: boolean;
  afterCreateItem?: boolean;
  isFromBfd?: boolean;
};

export type StackModalTypeProps = {
  isView: boolean;
  isEdit: boolean;
  isCreate: boolean;
  isMultipleView: boolean;
  isLoading: boolean;
  error: string;
  isReadyToRender: boolean;
  isLastStackModal: boolean;
  showEditQueueModal: boolean;
  currentModalInfo: {
    modelName: ModelName;
    currentModelIds: number[];
    modalData: Record<string, any>;
    action: ModalAction;
    isMultiple?: boolean;
    isFromBlockBFD?: boolean;
  };
  showQueueModal: boolean;
  handleCloseModal: (args?: HandleCloseModalArgs) => void;
  handleClickInfoIcon: (ids: number[], modelName: string) => void;
  handleClickEdit: () => void;
  updateStackModalData: () => void;
  updateStackModalDataAfterEdit: () => void;
};

export type ModalModelContainerProps = {
  isFromBfd?: boolean;
};

const ModalModelContainer: React.FC<ModalModelContainerProps> = ({
  isFromBfd,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname }: { pathname: string } = useLocation();
  const { activeOrganization }: OrganizationDataType =
    useAppSelector(getOrganizationsData);
  const {
    action: actionFromParams,
    id: idFromParams = '',
    modelName: modeNameFromParams,
  } = useParams<{
    action: ModalAction;
    id: string;
    modelName: ModelName;
  }>();

  const stackModalData = useAppSelector(getStackModalData);
  const queueModalInfo = useAppSelector(getQueueModalInfo);
  const blockModalData = useAppSelector(getBlockModalInfo);

  const { data: modelItemId, error: creationError } =
    useAppSelector(getModelItem);

  const idsFromParams = useMemo(
    () => getIdsArrayFromParams(idFromParams),
    [idFromParams],
  );
  const isModalOpenByQuery = !!(actionFromParams || idsFromParams.length);

  const currentModelName = isModalOpenByQuery
    ? stackModalData.isLastStackModal
      ? modeNameFromParams
      : stackModalData.modelName
    : stackModalData.modelName;

  const currentModalInfo =
    stackModalData.modalInfo[stackModalData.modalInfo.length - 1];

  const isLoading = stackModalData.loading;
  const isView = stackModalData.currentAction === ModalAction.View;
  const isEdit = stackModalData.currentAction === ModalAction.Edit;
  const isCreate = stackModalData.currentAction === ModalAction.Create;
  const isMultipleView = currentModalInfo?.currentModelIds.length > 1;
  const isReadyToRender =
    !!currentModalInfo?.modelName &&
    currentModalInfo.modelName === currentModelName;
  const error = stackModalData.error;

  const handleCloseModal = useCallback(
    (args?: HandleCloseModalArgs) => {
      if (queueModalInfo.showModal) {
        dispatch(clearQueueModalState());
      }

      if (
        stackModalData.isLastStackModal ||
        args?.resetStackInfo ||
        queueModalInfo.afterCreating
      ) {
        if (isModalOpenByQuery) {
          if (isFromBfd) {
            const path = activeOrganization
              ? `/${activeOrganization.namespace}/bfd/${modeNameFromParams}/${idFromParams}`
              : `/bfd/${modeNameFromParams}/${idFromParams}`;
            navigate(path);
          } else {
            const path = activeOrganization
              ? `/${activeOrganization.namespace}/${stackModalData.modelName}`
              : `/${stackModalData.modelName}`;
            navigate(path);
          }
        }
        (modelItemId ||
          args?.afterCreateItem ||
          currentModalInfo?.action === ModalAction.QueueEdit) &&
          refreshPage(pathname, stackModalData);
        dispatch(clearAllStackModalData());

        if (blockModalData.error || blockModalData.modelName) {
          dispatch(clearBlockModalData());
        }

        (creationError || modelItemId) &&
          dispatch(clearCreatedStateModelItem());
      } else {
        dispatch(clearStackModalData());
        (creationError || modelItemId) &&
          dispatch(clearCreatedStateModelItem());
      }
    },
    [
      queueModalInfo.showModal,
      queueModalInfo.afterCreating,
      stackModalData,
      dispatch,
      isModalOpenByQuery,
      modelItemId,
      currentModalInfo?.action,
      pathname,
      blockModalData.error,
      blockModalData.modelName,
      creationError,
      isFromBfd,
      activeOrganization,
      modeNameFromParams,
      idFromParams,
      navigate,
    ],
  );

  const handleClickInfoIcon = useCallback(
    (ids: number[], modelName: string) => {
      dispatch(
        setStackModalData({
          modelName,
          modelIds: ids,
          currentAction: ModalAction.View,
        }),
      );
    },
    [dispatch],
  );

  const handleClickEdit = useCallback(
    () =>
      currentModelName &&
      dispatch(
        setStackModalData({
          modelName: currentModelName,
          modelIds: currentModalInfo?.currentModelIds,
          currentAction: ModalAction.Edit,
        }),
      ),
    [currentModalInfo?.currentModelIds, dispatch, currentModelName],
  );

  const updateStackModalData = useCallback(() => {
    dispatch(
      clearStackModalData({ deep: stackModalData.isLastStackModal ? 1 : 2 }),
    );
    if (queueModalInfo.showModal) {
      dispatch(clearQueueModalState());
    }
    currentModelName &&
      dispatch(
        setStackModalData({
          modelName: currentModelName,
          modelIds: currentModalInfo?.currentModelIds,
          currentAction: ModalAction.View,
        }),
      );
  }, [
    currentModalInfo?.currentModelIds,
    currentModelName,
    stackModalData.isLastStackModal,
    queueModalInfo.showModal,
    dispatch,
  ]);

  const updateStackModalDataAfterEdit = useCallback(() => {
    if (currentModalInfo?.action === ModalAction.QueueEdit) {
      refreshPage(pathname, stackModalData);
    }

    dispatch(clearStackModalData({ deep: 1 }));

    if (queueModalInfo.showModal) {
      dispatch(clearQueueModalState());
    }
  }, [
    queueModalInfo.showModal,
    currentModalInfo?.action,
    stackModalData,
    pathname,
    dispatch,
  ]);

  const showModal = capitalizedFirstLetter(currentModelName) in ModelName;

  const stackModalProps = useMemo(
    () => ({
      isView,
      isEdit,
      isCreate,
      error,
      currentModalInfo,
      isLoading,
      isMultipleView,
      isReadyToRender,
      isLastStackModal: stackModalData.isLastStackModal,
      showQueueModal: queueModalInfo.showModal,
      showEditQueueModal:
        stackModalData.currentAction === ModalAction.QueueEdit,
      handleCloseModal,
      handleClickInfoIcon,
      handleClickEdit,
      updateStackModalData,
      updateStackModalDataAfterEdit,
    }),
    [
      isView,
      isEdit,
      isCreate,
      error,
      currentModalInfo,
      isLoading,
      isMultipleView,
      isReadyToRender,
      stackModalData.isLastStackModal,
      stackModalData.currentAction,
      queueModalInfo.showModal,
      handleCloseModal,
      handleClickInfoIcon,
      handleClickEdit,
      updateStackModalData,
      updateStackModalDataAfterEdit,
    ],
  );

  const currentModal =
    currentModelName === ModelName.Material ? (
      <Material {...stackModalProps} />
    ) : currentModelName === ModelName.Process ? (
      <Process {...stackModalProps} />
    ) : currentModelName === ModelName.Measurement ? (
      <Measurement {...stackModalProps} />
    ) : currentModelName === ModelName.Program ||
      currentModelName === ModelName.Feedback ? (
      <File {...stackModalProps} />
    ) : currentModelName === ModelName.MaterialType ? (
      <MaterialType {...stackModalProps} />
    ) : currentModelName === ModelName.Experiment ? (
      <Experiment {...stackModalProps} />
    ) : currentModelName === ModelName.User ? (
      <User {...stackModalProps} />
    ) : currentModelName === ModelName.ProcessType ? (
      <ProcessType {...stackModalProps} />
    ) : currentModelName === ModelName.MeasurementType ? (
      <MeasurementType {...stackModalProps} />
    ) : currentModelName === ModelName.ControlType ? (
      <ControlType {...stackModalProps} />
    ) : currentModelName === ModelName.Team ? (
      <Team {...stackModalProps} />
    ) : currentModelName === ModelName.Site ? (
      <Site {...stackModalProps} />
    ) : currentModelName === ModelName.Instrument ? (
      <Instrument {...stackModalProps} />
    ) : null;

  const addPaperStyles =
    currentModelName &&
    [ModelName.Material, ModelName.Measurement, ModelName.Process].includes(
      currentModelName,
    ) &&
    !isCreate;

  let modalProps = {};
  if (addPaperStyles) {
    modalProps = {
      ...modalProps,
      paperStyles: {
        width: 380,
      },
    };
  }
  const isCreateNewFile =
    (currentModelName === ModelName.Measurement ||
      currentModelName === ModelName.Material ||
      currentModelName === ModelName.Process ||
      currentModelName === ModelName.Program) &&
    currentModalInfo?.action === ModalAction.Create;

  if (isCreateNewFile || queueModalInfo.showModal) {
    modalProps = {
      ...modalProps,
      contentWithScroll: false,
    };
  }

  const shouldFetchStackModalData = useRef<boolean>(true);

  useEffect(() => {
    if (isModalOpenByQuery && showModal) {
      if ((isEdit || isView) && !idsFromParams.length) {
        const path = activeOrganization
          ? `/${activeOrganization.namespace}/${currentModelName}`
          : `/${currentModelName}`;
        return navigate(path);
      }
      currentModelName &&
        shouldFetchStackModalData.current &&
        dispatch(
          setStackModalData({
            modelName: currentModelName,
            modelIds: isCreate ? [] : idsFromParams,
            currentAction: actionFromParams,
            isModalOpenByQuery: true,
          }),
        );
      shouldFetchStackModalData.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={stackModalData.showModal}
      handleClose={() => {
        if (queueModalInfo.showModal && !queueModalInfo.afterCreating) {
          return updateStackModalData();
        }
        return handleCloseModal();
      }}
      {...modalProps}
    >
      <ModalWrapper
        autoHeight={
          queueModalInfo.showModal ||
          isCreateNewFile ||
          stackModalData.currentAction === ModalAction.QueueEdit
        }
        data-id="block-modal"
      >
        {currentModal}
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div<{ autoHeight: boolean }>`
  height: ${({ autoHeight }) => (autoHeight ? 'inherit' : '95vh')};
  display: flex;
  flex-direction: column;
`;

export default ModalModelContainer;
