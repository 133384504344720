import { IconProps } from './types';

const MaterialTypesIcon = ({
  color = '#000',
  size = { width: 22, height: 23 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 22 23"
    version="1.1"
  >
    <g
      id="Main"
      stroke="none"
      strokeWidth="1"
      fill="#fffff"
      fillRule="evenodd"
      fillOpacity="0.87"
    >
      <g id="Nav" transform="translate(-339.000000, -408.000000)" fill={color}>
        <g
          id="Navs/Main-Expanded-Copy"
          transform="translate(323.000000, 66.000000)"
        >
          <g id="Group-3">
            <g id="Group" transform="translate(0.000000, 8.000000)">
              <g
                id="Lists/Default/Default/★-+-Text-Copy-9"
                transform="translate(0.000000, 321.500000)"
              >
                <g
                  id="icons/ic/grade-default"
                  transform="translate(16.000000, 13.000000)"
                >
                  <path
                    d="M22,2 L22,16 C22,19.2383969 19.4344251,21.8775718 16.2249383,21.9958615 L16,22 L3,22 L3,20 L16,20 C18.1421954,20 19.8910789,18.3160315 19.9951047,16.1996403 L20,16 L20,2 L22,2 Z M15,0 C16.6568542,0 18,1.34314575 18,3 L18,3 L18,15 C18,16.6568542 16.6568542,18 15,18 L15,18 L3,18 C1.34314575,18 0,16.6568542 0,15 L0,15 L0,3 C0,1.34314575 1.34314575,0 3,0 L3,0 Z M15,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,3 L2,15 C2,15.5522847 2.44771525,16 3,16 L3,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,15 L16,3 C16,2.44771525 15.5522847,2 15,2 L15,2 Z M9,11 C10.1045695,11 11,11.8954305 11,13 C11,14.1045695 10.1045695,15 9,15 C7.8954305,15 7,14.1045695 7,13 C7,11.8954305 7.8954305,11 9,11 Z M5,7 C6.1045695,7 7,7.8954305 7,9 C7,10.1045695 6.1045695,11 5,11 C3.8954305,11 3,10.1045695 3,9 C3,7.8954305 3.8954305,7 5,7 Z M13,7 C14.1045695,7 15,7.8954305 15,9 C15,10.1045695 14.1045695,11 13,11 C11.8954305,11 11,10.1045695 11,9 C11,7.8954305 11.8954305,7 13,7 Z M9,3 C10.1045695,3 11,3.8954305 11,5 C11,6.1045695 10.1045695,7 9,7 C7.8954305,7 7,6.1045695 7,5 C7,3.8954305 7.8954305,3 9,3 Z"
                    id="material-types"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MaterialTypesIcon;
