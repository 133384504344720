export type HistogramChartAxesBasesType = {
  column_name: {
    label: string;
    name: string;
  }[];
};

export enum HistogramChartTypeEnum {
  CONTINIOUS = 'CONTINIOUS',
  CATEGORICAL = 'CATEGORICAL',
}
