import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'materials';

const initialState = {
  materials: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const MaterialsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_MATERIALS_START: {
      return R.mergeDeepRight(state, { materials: { loading: true } });
    }
    case actionTypes.FETCH_MATERIALS_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          materials: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        materials: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_MATERIALS_ERROR: {
      return R.mergeDeepRight(state, { materials: { error: payload } });
    }

    case actionTypes.SET_MATERIALS_TABLE_FILTER: {
      return R.mergeRight(state, {
        materials: { ...state.materials, filterModel: payload },
      });
    }
    case actionTypes.SET_MATERIALS_TABLE_SORT: {
      return R.mergeRight(state, {
        materials: { ...state.materials, sortModel: payload },
      });
    }
    case actionTypes.SET_MATERIALS_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        materials: { showCancel: !state.materials.showCancel },
      });
    }

    case actionTypes.SET_MATERIALS_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        materials: { selectedNodes: payload },
      });
    }
    case actionTypes.CLEAR_MATERIALS_TABLE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default MaterialsReducer;
