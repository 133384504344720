import { ReactNode } from 'react';
import styled from 'styled-components';

interface ModalFooterProps extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
}

const ModalFooter = ({ children, ...props }: ModalFooterProps) => (
  <Footer {...props}>{children}</Footer>
);

const Footer = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid #e0e0e0;
  align-self: flex-end;
`;

export default ModalFooter;
