import axios from 'axios';
import axiosInstance from 'config/AxiosConfig';
import { Dispatch } from 'redux';
import {
  API_ENDPOINTS,
  ApiModelName,
  ModelName,
  mapApiEndpoint,
} from 'utils/enum';
import {
  capitalizedFirstLetter,
  getApiModelNameFromModelName,
} from 'utils/utils';
import * as actionTypes from './VisualizeActionsTypes';
import { DOEChartResponse } from './VisualizeComponents/DOEChart/model/DOEChartResponse';
import { HistogramChartTypeEnum } from './VisualizeComponents/HistogramChart/models/histogramChartModels';
import { HistogramChartResponse } from './VisualizeComponents/HistogramChart/models/histogramChartResponseModel';
import { getHistogramChartTypeByColumnName } from './VisualizeComponents/HistogramChart/utils/utils';
import { ScatterChartAxesBasesType } from './VisualizeComponents/ScatterChart/models/scatterChartModels';
import { ScatterChartResponse } from './VisualizeComponents/ScatterChart/models/scatterChartResponseModel';
import {
  ChartInfoType,
  ChartTypeEnum,
  VisualizePanelsTypeEnum,
} from './VisualizeReducer';
import { mockedChartData } from './VisualizeComponents/ScatterForMatrixChart/ScatterForMatrixSettings/mockedChartData';

export type ScatterInitialChartSettings = {
  x_column_name: string;
  y_column_name: string;
  label_by: string[];
  group_by: string | null;
  color_by: string | null;
  center_mean: boolean;
};
export type ScatterForMatrixInitialChartSettings = {
  x_column_name: string;
  y_column_name: string[];
  label_by: string[];
  group_by: string | null;
};

export type HistogramInitialChartSettings = {
  column_name: string;
  color_by: string | null;
  label_by: string[];
};

export type DOEInitialChartSettings = {
  lastRow: number;
};

export type FilterModelType<T> = {
  count_only?: boolean;
  endRow?: number;
  startRow?: number;
  filterModel: Record<string, any>;
  sortModel?: Record<string, any>;
  chartSettings: T;
};

export type CreateNewChartArgs<T, C> = {
  panelType: VisualizePanelsTypeEnum;
  chartType: ChartTypeEnum;
  modelName: ModelName;
  axesBases?: C;
  modelDefinitions: Array<Array<string>>;
  chartId: string;
  modelApiToFetch: string;
  filter: FilterModelType<T>;
  hasChartInitialized?: boolean; // this is used to define should we fetch from BE data or take the existed one
};

export type RefetchChartArgs<T> = {
  filter: FilterModelType<T>;
  modelName: ModelName;
  chartId: string;
  panelType: VisualizePanelsTypeEnum;
  shouldUpdateSettings?: boolean;
  showCancel: boolean;
};

export type FetchChartRawData<T> = {
  filter: FilterModelType<T>;
  modelName: ModelName;
  canceled?: boolean;
};

export type SetActiveVisualizeHeaderTabArgs = {
  panelType: VisualizePanelsTypeEnum;
  activeTabId: string;
  modelName: ModelName;
};

export type DeleteVisualChartArgs = {
  panelType: VisualizePanelsTypeEnum;
  modelName: ModelName;
  chartId: string;
};

export type UpdateChartSettingsArgs = {
  panelType: VisualizePanelsTypeEnum;
  chartId: string;
  updatedSettings: ChartInfoType['settings'];
  modelName: ModelName;
  shouldResetDataSet?: boolean;
};

export type OnAddNewChartTabClickArgs = {
  panelType: VisualizePanelsTypeEnum;
  modelName: ModelName;
};

export type onDownloadChartClickArgs = {
  chartId: string;
  modelName: ModelName;
};

export type onChartCreatingErrorPayload = {
  panelType: VisualizePanelsTypeEnum;
  chartId: string;
  error: string;
  modelName: ModelName;
};

export type onChartCreatingSuccessPayload<T> = {
  panelType: VisualizePanelsTypeEnum;
  chartId: string;
  chartData: T;
  modelName: ModelName;
  axesBases?: ScatterChartAxesBasesType;
  chartType: ChartTypeEnum;
};

export type HistogramChartResponseWithType = HistogramChartResponse & {
  histogramChartType: HistogramChartTypeEnum;
};

export type onRefetChartStartPayload = {
  chartId: string;
  panelType: VisualizePanelsTypeEnum;
  modelName: ModelName;
};

export type onFetchChartRawDataStartPayload = {
  modelName: ModelName;
};

export type onFetchChartRawDataSuccessPayload = {
  modelName: ModelName;
  rawData: Record<string, any>[];
};

export type onFetchChartRawDataErrorPayload = {
  modelName: ModelName;
  error: string;
};

export type onRefetchChartErrorPayload = onRefetChartStartPayload & {
  error: string;
};

export type onRefetchScatterChartSuccessPayload = onRefetChartStartPayload & {
  chartData: ScatterChartResponse;
  shouldUpdateSettings?: boolean;
};

export type onRefetchHistogramChartSuccessPayload = onRefetChartStartPayload & {
  chartData: HistogramChartResponse;
  histogramChartType: HistogramChartTypeEnum;
};

export type onRefetchDOEChartSuccessPayload = onRefetChartStartPayload & {
  chartData: DOEChartResponse;
};

export const splitVisualizeDetailsDisplay =
  (payload: { modelName: ModelName }) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.SPLIT_VISUALIZE_DETAILS_DISPLAY, payload });

export const createNewChart =
  <T, C>(payload: CreateNewChartArgs<T, C>) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CREATE_NEW_CHART, payload });

    if (payload.chartType === ChartTypeEnum.Scatter) {
      try {
        const { data: chartData } =
          await axiosInstance.post<ScatterChartResponse>(
            `/${API_ENDPOINTS.charts.scatter}/${capitalizedFirstLetter(
              payload.modelApiToFetch,
            )}`,
            payload.filter,
          );

        const successPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          chartData,
          axesBases: payload.axesBases,
          chartType: payload.chartType,
          modelName: payload.modelName,
        };

        dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_SUCCESS,
          payload: successPayload,
        });
      } catch (err) {
        const isAxiosError = axios.isAxiosError(err);

        const errorPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          modelName: payload.modelName,
          error: isAxiosError
            ? (err.response?.data as any)?.['error']
            : (err.message ?? 'Too many data points to visualize'),
        };
        dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_ERROR,
          payload: errorPayload,
        });
      }
    }

    if (payload.chartType === ChartTypeEnum.Histogram) {
      try {
        const { data: chartData } =
          await axiosInstance.post<HistogramChartResponse>(
            `/${API_ENDPOINTS.charts.histogram}/${capitalizedFirstLetter(
              payload.modelApiToFetch,
            )}`,
            payload.filter,
          );

        const histogramChartType = getHistogramChartTypeByColumnName(
          payload.modelDefinitions,
          (payload.filter.chartSettings as any)['column_name'],
        );

        const successPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          chartData: {
            ...chartData,
            histogramChartType,
          },
          axesBases: payload.axesBases,
          chartType: payload.chartType,
          modelName: payload.modelName,
        };
        dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_SUCCESS,
          payload: successPayload,
        });
      } catch (err) {
        const errorPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          modelName: payload.modelName,
          error:
            err?.response?.data?.error ??
            err.message ??
            'Too many data points to visualize',
        };
        dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_ERROR,
          payload: errorPayload,
        });
      }
    }

    if (payload.chartType === ChartTypeEnum.DOE) {
      if (
        (payload.filter.chartSettings as DOEInitialChartSettings).lastRow > 5000
      ) {
        const errorPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          modelName: payload.modelName,
          error: 'Too many data points to visualize',
        };
        return dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_ERROR,
          payload: errorPayload,
        });
      }

      if (!payload.hasChartInitialized) {
        try {
          const { data } = await axiosInstance.post(
            `/${API_ENDPOINTS.models.getTable}/${payload.modelApiToFetch}`,
            {
              filterModel: payload.filter.filterModel,
              sortModel: payload.filter.sortModel,
              endRow: 4000,
              startRow: 0,
            },
          );

          const successPayload = {
            panelType: payload.panelType,
            chartId: payload.chartId,
            chartData: {
              dots: data.rows,
            },
            chartType: payload.chartType,
            modelName: payload.modelName,
          };

          dispatch({
            type: actionTypes.ON_CREATE_NEW_CHART_SUCCESS,
            payload: successPayload,
          });
        } catch (err) {
          const errorPayload = {
            panelType: payload.panelType,
            chartId: payload.chartId,
            modelName: payload.modelName,
            error:
              err?.response?.data?.error ??
              err.message ??
              'Too many data points to visualize',
          };
          dispatch({
            type: actionTypes.ON_CREATE_NEW_CHART_ERROR,
            payload: errorPayload,
          });
        }
      }
    }

    if (payload.chartType === ChartTypeEnum.ScatterForMatrix) {
      try {
        // const { data: chartData } =
        //   await axiosInstance.post<ScatterChartResponse>(
        //     `/${API_ENDPOINTS.charts.scatter}/${capitalizedFirstLetter(
        //       payload.modelApiToFetch,
        //     )}`,
        //     payload.filter,
        //   );

        const successPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          chartData: mockedChartData,
          axesBases: payload.axesBases,
          chartType: payload.chartType,
          modelName: payload.modelName,
        };
        dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_SUCCESS,
          payload: successPayload,
        });
      } catch (err) {
        const isAxiosError = axios.isAxiosError(err);

        const errorPayload = {
          panelType: payload.panelType,
          chartId: payload.chartId,
          modelName: payload.modelName,
          error: isAxiosError
            ? (err.response?.data as any)?.['error']
            : (err.message ?? 'Too many data points to visualize'),
        };
        dispatch({
          type: actionTypes.ON_CREATE_NEW_CHART_ERROR,
          payload: errorPayload,
        });
      }
    }
  };

export const refetchHistogramChart =
  ({
    filter,
    modelName,
    chartId,
    panelType,
    histogramChartType,
    showCancel,
  }: RefetchChartArgs<HistogramInitialChartSettings> & {
    histogramChartType: HistogramChartTypeEnum;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.ON_REFETCH_HISTOGRAM_CHART_START,
      payload: {
        chartId,
        panelType,
        modelName,
      },
    });

    const modelApi = mapApiEndpoint[modelName][Number(showCancel)];

    try {
      const { data: chartData } =
        await axiosInstance.post<ScatterChartResponse>(
          `/${API_ENDPOINTS.charts.histogram}/${capitalizedFirstLetter(
            modelApi,
          )}`,
          filter,
        );
      dispatch({
        type: actionTypes.ON_REFETCH_HISTOGRAM_CHART_SUCCESS,
        payload: {
          chartData,
          chartId,
          panelType,
          histogramChartType,
          modelName,
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.ON_REFETCH_HISTOGRAM_CHART_ERROR,
        payload: {
          chartId,
          panelType,
          modelName,
          error:
            err.response?.data?.error ??
            err.message ??
            'Too many data points to visualize',
        },
      });
    }
  };

export const refetchScatterChart =
  ({
    filter,
    modelName,
    chartId,
    panelType,
    shouldUpdateSettings,
    showCancel,
  }: RefetchChartArgs<ScatterInitialChartSettings>) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.ON_REFETCH_SCATTER_CHART_START,
      payload: {
        chartId,
        panelType,
        modelName,
      },
    });
    const modelApi = mapApiEndpoint[modelName][Number(showCancel)];

    try {
      const { data: chartData } =
        await axiosInstance.post<ScatterChartResponse>(
          `/${API_ENDPOINTS.charts.scatter}/${capitalizedFirstLetter(
            modelApi,
          )}`,
          filter,
        );
      dispatch({
        type: actionTypes.ON_REFETCH_SCATTER_CHART_SUCCESS,
        payload: {
          chartData,
          chartId,
          panelType,
          modelName,
          shouldUpdateSettings,
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.ON_REFETCH_SCATTER_CHART_ERROR,
        payload: {
          chartId,
          panelType,
          modelName,
          error:
            err.response?.data?.error ??
            err.message ??
            'Too many data points to visualize',
        },
      });
    }
  };

export const refetchDOEChart =
  ({
    filter,
    modelName,
    chartId,
    showCancel,
    panelType,
  }: RefetchChartArgs<any>) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.ON_REFETCH_DOE_CHART_START,
      payload: {
        chartId,
        panelType,
        modelName,
      },
    });

    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.getTable}/${
          showCancel ? ApiModelName.WMEA : ApiModelName.KMEA
        }`,
        {
          filterModel: filter.filterModel,
          sortModel: filter.sortModel,
          endRow: 4000,
          startRow: 0,
        },
      );

      dispatch({
        type: actionTypes.ON_REFETCH_DOE_CHART_SUCCESS,
        payload: {
          chartData: {
            dots: data.rows,
          },
          chartId,
          panelType,
          modelName,
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.ON_REFETCH_DOE_CHART_ERROR,
        payload: {
          chartId,
          panelType,
          modelName,
          error:
            err.response?.data?.error ??
            err.message ??
            'Too many data points to visualize',
        },
      });
    }
  };

export const fetchChartRawData =
  ({ filter, modelName }: FetchChartRawData<any>) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.ON_FETCH_CHART_RAW_DATA_START,
      payload: {
        modelName,
      },
    });

    const apiModelName = getApiModelNameFromModelName(modelName);

    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.getTable}/${apiModelName}`,
        {
          filterModel: filter.filterModel,
          sortModel: filter.sortModel,
          endRow: 4000,
          startRow: 0,
        },
      );

      const payload: onFetchChartRawDataSuccessPayload = {
        modelName,
        rawData: data.rows,
      };

      dispatch({
        type: actionTypes.ON_FETCH_CHART_RAW_DATA_SUCCESS,
        payload: payload,
      });
    } catch (err) {
      const payload: onFetchChartRawDataErrorPayload = {
        modelName,
        error: err.response?.data?.error ?? err.message,
      };

      dispatch({
        type: actionTypes.ON_FETCH_CHART_RAW_DATA_ERROR,
        payload,
      });
    }
  };

export const setActiveVisualizeHeaderTab =
  (payload: SetActiveVisualizeHeaderTabArgs) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.SET_ACTIVE_VISUALIZE_HEADER_TAB, payload });

export const deleteVisualizeChart =
  (payload: DeleteVisualChartArgs) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.DELETE_VISUALIZE_CHART, payload });
export const updateChartSettings =
  (payload: UpdateChartSettingsArgs) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.UPDATE_CHART_SETTINGS, payload });

export const onAddNewChartTabClick =
  (payload: OnAddNewChartTabClickArgs) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.ON_ADD_NEW_CHART_TAB_CLICK, payload });

export const onDownloadChartClick =
  (payload: onDownloadChartClickArgs) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.ON_DOWNLOAD_CHART_CLICK, payload });

export const onDownloadChartFinished =
  (payload: { modelName: ModelName }) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.ON_DOWNLOAD_CHART_FINISHED, payload });
