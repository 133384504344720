import { useCallback, useRef } from 'react';

const useSingleAndDoubleClicks = ({
  onClick,
  onDoubleClick,
}: {
  onClick: (e: React.MouseEvent) => void;
  onDoubleClick: (e: React.MouseEvent) => void;
}) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const cancelPendingClick = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [timer]);

  const handleClick = useCallback(
    (data: any) => {
      // We only cache the most recent click event, so cancel any pending clicks
      cancelPendingClick();
      timer.current = setTimeout(() => {
        timer.current = null;
        onClick(data);
      }, 300);
    },
    [timer, cancelPendingClick, onClick],
  );

  const handleDoubleClick = useCallback(
    (data: any) => {
      cancelPendingClick();
      onDoubleClick(data);
    },
    [cancelPendingClick, onDoubleClick],
  );

  return { handleClick, handleDoubleClick };
};

export default useSingleAndDoubleClicks;
