import Rainbow from 'rainbowvis.js';
import colors from 'theme/patterns/colors';
import { ScatterSettingsType } from '../../../VisualizeReducer';
import { CenterLineOptions } from '../ScatterSettings';
import { SCATTER_STATISTIC_VALUE } from '../constants/scatterStatisticValue';
import { Dot } from '../models/scatterChartResponseModel';

export const getStatisticValueColor = (
  valueType: SCATTER_STATISTIC_VALUE,
  value: number,
) => {
  if (valueType === SCATTER_STATISTIC_VALUE.STABILITY) {
    const MAX = 2;
    const MIN = 1.33;

    if (value >= MAX) {
      return colors.red;
    }

    if (value <= MIN) {
      return colors.green;
    }

    const myRainbow = new Rainbow();
    myRainbow.setSpectrum('green', 'orange', 'red');
    myRainbow.setNumberRange(MIN, MAX);
    return `#${myRainbow.colorAt(value)}`;
  }

  if (
    valueType === SCATTER_STATISTIC_VALUE.PERFORMANCE ||
    valueType === SCATTER_STATISTIC_VALUE.CAPABILITY
  ) {
    const MAX = 3;
    const MIN = 1;

    if (value >= MAX) {
      return colors.green;
    }

    if (value <= MIN) {
      return colors.red;
    }

    const myRainbow = new Rainbow();
    myRainbow.setSpectrum('red', 'orange', 'green');
    myRainbow.setNumberRange(MIN, MAX);
    return `#${myRainbow.colorAt(value)}`;
  }
};

export const calculateControlChartData = (
  chartDataDots: Dot[],
  settings: ScatterSettingsType,
) => {
  const isCenterLineMean = settings.centerLine === CenterLineOptions.Mean;

  const initialResponse = {
    center_mean: isCenterLineMean,
    color_by: null,
    domain: {
      x: [settings.xMinAxis, settings.xMaxAxis],
      y: [settings.yMinAxis, settings.yMaxAxis],
    },
    dots: [] as Dot[],
    group_by: null,
    label_by: [],
    lines: {
      center: {
        y: 0,
      },
      UCL: {
        y: 0,
      },
      LCL: {
        y: 0,
      },
    },
    statistics: {
      total_variation: 0,
      repeatability: 0,
      stability: 0,
      capability: 0,
      center: 0,
      lower_control_limit: 0,
      upper_control_limit: 0,
      performance: 0,
    },
  };

  if (!chartDataDots.length) {
    return initialResponse;
  }

  const filtedDotsByDomains = chartDataDots
    .filter((d) => {
      const xDate = new Date(d.x).getTime();
      const fromRangeDate =
        initialResponse.domain.x[0] !== null
          ? new Date(initialResponse.domain.x[0]).getTime()
          : null;
      const toRangeDate =
        initialResponse.domain.x[1] !== null
          ? new Date(initialResponse.domain.x[1]).getTime()
          : null;

      const isValidByFromRangeDate =
        fromRangeDate !== null ? xDate >= fromRangeDate : true;
      const isValidByToRangeDate =
        toRangeDate !== null ? xDate <= toRangeDate : true;
      const isValidByYMinAxis =
        settings.yMinAxis !== null ? d.y >= settings.yMinAxis : true;
      const isValidByYMaxAxis =
        settings.yMinAxis !== null ? d.y <= settings.yMaxAxis : true;

      return (
        isValidByFromRangeDate &&
        isValidByToRangeDate &&
        isValidByYMinAxis &&
        isValidByYMaxAxis
      );
    })
    .map((x) => ({
      ...x,
      groups: [],
    }));
  if (!filtedDotsByDomains.length) {
    return initialResponse;
  }

  /** START calculating control lines */
  const E2_N2 = 2.66;
  const d2_N2 = 1.128;

  const dots = filtedDotsByDomains.filter((d) => d.y !== null);
  const orderedDotsByValue = [...dots].sort((a, b) => a.y - b.y);
  const sortedDotsByDateMeasure = [...dots];
  const dotsCount = dots.length;
  const isEvenCountOfDots = dotsCount % 2 === 0;

  const secondValueInTheMiddle =
    orderedDotsByValue[Math.floor(orderedDotsByValue.length / 2)]?.y ?? 0;

  const firstValueInTheMiddle =
    orderedDotsByValue[Math.floor(orderedDotsByValue.length / 2) - 1]?.y ?? 0;

  const mean =
    dotsCount > 0
      ? dots.reduce((acc, val) => (acc += val.y), 0) / orderedDotsByValue.length
      : 0;

  const median =
    orderedDotsByValue.length === 0
      ? 0
      : isEvenCountOfDots
        ? secondValueInTheMiddle === 0 || firstValueInTheMiddle === 0
          ? 0
          : (secondValueInTheMiddle + firstValueInTheMiddle) / 2
        : orderedDotsByValue[Math.floor(orderedDotsByValue.length / 2)]?.y;

  if (isCenterLineMean) {
    initialResponse.lines.center.y = mean;
  } else {
    initialResponse.lines.center.y = median;
  }

  const diffs = sortedDotsByDateMeasure.reduce((acc, val, i) => {
    if (i === 0) {
      return acc;
    }

    acc += Math.abs(val.y - sortedDotsByDateMeasure[i - 1].y);

    return acc;
  }, 0);

  const mrMean = diffs / (dots.length - 1);

  initialResponse.lines.UCL.y = initialResponse.lines.center.y + E2_N2 * mrMean;
  initialResponse.lines.LCL.y = initialResponse.lines.center.y - E2_N2 * mrMean;
  /** END calculating control lines */

  /** START calculating statistics */
  const intermediateSum = dots
    .map((x) => Math.pow(x.y - mean, 2))
    .reduce((acc, val) => (acc += val), 0);

  const stdev =
    intermediateSum === 0 || dotsCount === 0
      ? 0
      : Math.pow(intermediateSum / (dotsCount - 1), 0.5);

  initialResponse.statistics.total_variation = stdev;
  initialResponse.statistics.center = initialResponse.lines.center.y;
  initialResponse.statistics.upper_control_limit = initialResponse.lines.UCL.y;
  initialResponse.statistics.lower_control_limit = initialResponse.lines.LCL.y;
  initialResponse.statistics.performance = 3;
  initialResponse.statistics.repeatability = mrMean === 0 ? 0 : mrMean / d2_N2;
  initialResponse.statistics.stability = mrMean === 0 ? 0 : stdev / mrMean;
  initialResponse.statistics.capability =
    mrMean === 0 ? 0 : (stdev * 3) / mrMean;
  /** END calculating statistics*/

  /** START calculating domain limits */
  const max = dots.reduce((acc, val) => Math.max(acc, val.y), dots[0]?.y ?? 0);
  const min = dots.reduce((acc, val) => Math.min(acc, val.y), dots[0]?.y ?? 0);
  const dot_range_offset = (max - min) * 0.1;
  const line_range_offset =
    (initialResponse.lines.UCL.y - initialResponse.lines.LCL.y) * 0.1;
  const yMin = Math.min(
    min - dot_range_offset,
    initialResponse.lines.LCL.y - line_range_offset,
  );
  const yMax = Math.max(
    max + dot_range_offset,
    initialResponse.lines.UCL.y + line_range_offset,
  );
  const xRange = dots
    .map((x) => new Date(x.x))
    .sort((a, b) => a.getTime() - b.getTime());
  const xMin = xRange[0];
  const xMax = xRange.at(-1) ?? xRange[0];

  initialResponse.domain.x = [xMin, xMax];
  initialResponse.domain.y = [yMin, yMax];

  /** END calculating domain limits */

  initialResponse.dots = calculateZone9ForDots(
    filtedDotsByDomains,
    initialResponse.lines.center.y,
  );

  initialResponse.dots = initialResponse.dots.map((x) => {
    if (
      x.y > initialResponse.lines.UCL.y ||
      x.y < initialResponse.lines.LCL.y
    ) {
      return {
        ...x,
        groups: [...x.groups, 'Beyond limits'],
      };
    } else {
      return x;
    }
  });

  return initialResponse;
};

function calculateZone9ForDots(dots: Dot[], center: number): Dot[] {
  const windows = [];
  const windowSize = 9;

  for (let i = 0; i < dots.length - (windowSize - 1); i++) {
    const window = dots.slice(i, i + windowSize);
    windows.push(window);
  }

  let calculatedDotsWithZone9Groups: Dot[] = dots;

  for (let i = 0; i < windows.length; i++) {
    const currentWindow = windows[i];
    const isZone9Group =
      currentWindow.every((x) => x.y < center) ||
      currentWindow.every((x) => x.y > center);

    if (isZone9Group) {
      for (let i = 0; i < currentWindow.length; i++) {
        const currentDot = currentWindow[i];

        calculatedDotsWithZone9Groups = calculatedDotsWithZone9Groups.map(
          (x) => {
            const isCurrent = x.meta.id === currentDot.meta.id;

            if (isCurrent) {
              return {
                ...x,
                groups: [...new Set([...currentDot.groups, 'Zone9'])],
              };
            } else {
              return x;
            }
          },
        );
      }
    }
  }

  return calculatedDotsWithZone9Groups;
}
