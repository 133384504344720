import styled from 'styled-components';

import colors from 'theme/patterns/colors';

const MixIcon = () => (
  <Wrap>
    {['material', 'process', 'material'].map((dashName, i) => (
      <Dash
        key={i}
        style={{
          backgroundColor: colors[dashName].color,
        }}
      />
    ))}
  </Wrap>
);

const Wrap = styled.div`
  display: flex;
  width: 16px;
  justify-content: space-between;
`;

const Dash = styled.div`
  width: 4px;
  height: 13px;
`;

export default MixIcon;
