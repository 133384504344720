import { IconProps } from './types';

const Types = ({
  color = '#fff',
  size = { width: 19, height: 20 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 19 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>213ECDAB-E036-4760-89AF-E46B6662DF16</title>
    <g
      id="Explore"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Explore-Experiment"
        transform="translate(-1565.000000, -169.000000)"
        fill={color}
      >
        <g
          id="★-Button/Default/Default-Copy-4"
          transform="translate(1563.500000, 167.000000)"
        >
          <g id="ic/visibility" transform="translate(2.000000, 2.000000)">
            <path
              d="M18.3333333,2.5 L18.3333333,14.1666667 C18.3333333,16.8653308 16.1953543,19.0646431 13.5207819,19.1632179 L13.3333333,19.1666667 L2.5,19.1666667 L2.5,17.5 L13.3333333,17.5 C15.1184962,17.5 16.5758991,16.0966929 16.6625872,14.3330336 L16.6666667,14.1666667 L16.6666667,2.5 L18.3333333,2.5 Z M12.5,0.833333333 C13.8807119,0.833333333 15,1.95262146 15,3.33333333 L15,3.33333333 L15,13.3333333 C15,14.7140452 13.8807119,15.8333333 12.5,15.8333333 L12.5,15.8333333 L2.5,15.8333333 C1.11928813,15.8333333 0,14.7140452 0,13.3333333 L0,13.3333333 L0,3.33333333 C0,1.95262146 1.11928813,0.833333333 2.5,0.833333333 L2.5,0.833333333 Z M12.5,2.5 L2.5,2.5 C2.03976271,2.5 1.66666667,2.87309604 1.66666667,3.33333333 L1.66666667,3.33333333 L1.66666667,13.3333333 C1.66666667,13.7935706 2.03976271,14.1666667 2.5,14.1666667 L2.5,14.1666667 L12.5,14.1666667 C12.9602373,14.1666667 13.3333333,13.7935706 13.3333333,13.3333333 L13.3333333,13.3333333 L13.3333333,3.33333333 C13.3333333,2.87309604 12.9602373,2.5 12.5,2.5 L12.5,2.5 Z"
              id="types"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Types;
