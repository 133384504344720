import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

import {
  clearTokenData,
  getAccessToken,
  getExpirationDateToken,
  getOrgNamespace,
  getRefreshToken,
  setTokenData,
} from 'helpers/localeStorage';
import { isTokenExpired } from 'modules/auth/utils';
import { API_ENDPOINTS } from 'utils/enum';
import AppConfig from './AppConfig';

const instance = axios.create({
  baseURL: AppConfig.apiUrl,
});

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    if (config.baseURL === AppConfig.apiUrl) {
      const token = getAccessToken();
      const namespace = getOrgNamespace();
      if (token && namespace) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token} Namespace ${namespace}`,
        } as AxiosRequestHeaders;
      } else if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        } as AxiosRequestHeaders;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      ((error?.response.status === 400 ||
        error?.response.status === 401 ||
        error?.response.status === 500) &&
        (error?.request?.responseURL as string)?.includes(
          '/auth/login_refresh',
        )) ||
      !error // Temporary workaround CORS. CORS error has not error.response
    ) {
      clearTokenData();
      if (window) {
        return (window.location.href = '/auth/login');
      }

      return Promise.reject(error);
    }

    const expirationTokenDate = getExpirationDateToken();
    const refresh_token = getRefreshToken();
    const access_token = getAccessToken();

    if (
      !(error?.request?.responseURL as string)?.includes('/auth/login') &&
      !(error?.request?.responseURL as string)?.includes('/signup') &&
      ((isTokenExpired(expirationTokenDate) &&
        refresh_token &&
        !originalRequest._retry) ||
        !access_token ||
        (!error.response && !originalRequest._retry)) // Temporary workaround CORS. CORS error has not error.response
    ) {
      originalRequest._retry = true;
      const { data: refreshData, status } = await instance.post(
        API_ENDPOINTS.auth.loginRefresh,
        {
          refresh_token,
        },
      );

      if (!refreshData.error && status === 200 && refreshData.credentials) {
        setTokenData(refreshData.credentials);
        originalRequest.headers['Authorization'] = 'Bearer ' + getAccessToken();
        return axios(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
