import { ScatterForMatrixChartResponse } from '../model/ScatterForMatrixChart.interfaces';

export const mockedChartData: ScatterForMatrixChartResponse = {
  null: {
    dots: [
      // /// MOCKED DATA FOR MULTIPLE DIFF AXES ///
      // ///1 dataset///
      // {
      //   x: '2024-11-09 11:50:19.670000',
      //   y: 104,
      //   meta: {
      //     id: 1,
      //     axis: 'col_e',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-10 11:50:19.670000',
      //   y: 107,
      //   meta: {
      //     id: 2,
      //     axis: 'col_e',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-11 11:50:19.670000',
      //   y: 107,
      //   meta: {
      //     id: 3,
      //     axis: 'col_e',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-12 11:50:19.670000',
      //   y: 107,
      //   meta: {
      //     id: 4,
      //     axis: 'col_e',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-13 11:50:19.670000',
      //   y: 107,
      //   meta: {
      //     id: 5,
      //     axis: 'col_e',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-14 11:50:19.670000',
      //   y: 109,
      //   meta: {
      //     id: 6,
      //     axis: 'col_e',
      //   },
      //   groups: [],
      // },
      // ///2 dataset////
      // {
      //   x: '2024-11-09 11:50:19.670000',
      //   y: 103,
      //   meta: {
      //     id: 7,
      //     axis: 'col_f',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-10 11:50:19.670000',
      //   y: 106,
      //   meta: {
      //     id: 8,
      //     axis: 'col_f',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-11 11:50:19.670000',
      //   y: 106,
      //   meta: {
      //     id: 9,
      //     axis: 'col_f',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-12 11:50:19.670000',
      //   y: 106,
      //   meta: {
      //     id: 10,
      //     axis: 'col_f',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-13 11:50:19.670000',
      //   y: 106,
      //   meta: {
      //     id: 11,
      //     axis: 'col_f',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-14 11:50:19.670000',
      //   y: 108,
      //   meta: {
      //     id: 12,
      //     axis: 'col_f',
      //   },
      //   groups: [],
      // },
      // ///3 dataset////
      // {
      //   x: '2024-11-09 11:50:19.670000',
      //   y: 102,
      //   meta: {
      //     id: 13,
      //     axis: 'col_g',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-10 11:50:19.670000',
      //   y: 105,
      //   meta: {
      //     id: 14,
      //     axis: 'col_g',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-11 11:50:19.670000',
      //   y: 105,
      //   meta: {
      //     id: 15,
      //     axis: 'col_g',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-12 11:50:19.670000',
      //   y: 105,
      //   meta: {
      //     id: 16,
      //     axis: 'col_g',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-13 11:50:19.670000',
      //   y: 105,
      //   meta: {
      //     id: 17,
      //     axis: 'col_g',
      //   },
      //   groups: [],
      // },
      // {
      //   x: '2024-11-14 11:50:19.670000',
      //   y: 107,
      //   meta: {
      //     id: 18,
      //     axis: 'col_g',
      //   },
      //   groups: [],
      // },
      // / MOCKED DATA FOR MULTIPLE SAME AXiS ///
      ///col_e Axis
      ///1 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 104,
        meta: {
          id: 1,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 107,
        meta: {
          id: 2,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 107,
        meta: {
          id: 3,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 107,
        meta: {
          id: 4,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 107,
        meta: {
          id: 5,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 109,
        meta: {
          id: 6,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      ///2 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 103,
        meta: {
          id: 7,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 106,
        meta: {
          id: 8,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 106,
        meta: {
          id: 9,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 106,
        meta: {
          id: 10,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 106,
        meta: {
          id: 11,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 108,
        meta: {
          id: 12,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      ///3 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 102,
        meta: {
          id: 13,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 105,
        meta: {
          id: 14,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 105,
        meta: {
          id: 15,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 105,
        meta: {
          id: 16,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 105,
        meta: {
          id: 17,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 107,
        meta: {
          id: 18,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      ///4 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 101,
        meta: {
          id: 19,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 104,
        meta: {
          id: 20,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 104,
        meta: {
          id: 21,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 104,
        meta: {
          id: 22,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 104,
        meta: {
          id: 23,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 106,
        meta: {
          id: 24,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      ///5 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 100,
        meta: {
          id: 25,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 103,
        meta: {
          id: 26,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 103,
        meta: {
          id: 27,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 103,
        meta: {
          id: 28,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 103,
        meta: {
          id: 29,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 105,
        meta: {
          id: 30,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_e',
        },
        groups: [],
      },
      ///6 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 99,
        meta: {
          id: 31,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 102,
        meta: {
          id: 32,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 102,
        meta: {
          id: 33,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 102,
        meta: {
          id: 34,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 102,
        meta: {
          id: 35,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 104,
        meta: {
          id: 36,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_e',
        },
        groups: [],
      },
      ///col_f Axis
      ///7 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 90,
        meta: {
          id: 37,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 93,
        meta: {
          id: 38,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 93,
        meta: {
          id: 39,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 93,
        meta: {
          id: 40,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 93,
        meta: {
          id: 41,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 95,
        meta: {
          id: 42,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      ///8 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 89,
        meta: {
          id: 43,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 92,
        meta: {
          id: 44,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 92,
        meta: {
          id: 45,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 92,
        meta: {
          id: 46,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 92,
        meta: {
          id: 47,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 94,
        meta: {
          id: 48,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      ///9 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 88,
        meta: {
          id: 49,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 91,
        meta: {
          id: 50,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 91,
        meta: {
          id: 51,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 91,
        meta: {
          id: 52,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 91,
        meta: {
          id: 53,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 93,
        meta: {
          id: 54,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      ///10 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 87,
        meta: {
          id: 55,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 90,
        meta: {
          id: 56,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 90,
        meta: {
          id: 57,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 90,
        meta: {
          id: 58,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 90,
        meta: {
          id: 59,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 92,
        meta: {
          id: 60,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      ///11 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 86,
        meta: {
          id: 61,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 89,
        meta: {
          id: 62,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 89,
        meta: {
          id: 63,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 89,
        meta: {
          id: 64,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 89,
        meta: {
          id: 65,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 91,
        meta: {
          id: 66,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_f',
        },
        groups: [],
      },
      ///12 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 85,
        meta: {
          id: 67,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 88,
        meta: {
          id: 68,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 88,
        meta: {
          id: 69,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 88,
        meta: {
          id: 70,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 88,
        meta: {
          id: 71,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 90,
        meta: {
          id: 72,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_f',
        },
        groups: [],
      },
      ///13 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 70,
        meta: {
          id: 73,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 73,
        meta: {
          id: 74,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 73,
        meta: {
          id: 75,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 73,
        meta: {
          id: 76,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 73,
        meta: {
          id: 77,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 75,
        meta: {
          id: 78,
          fileName: 'Filename 1 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      ///14 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 69,
        meta: {
          id: 79,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 72,
        meta: {
          id: 80,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 72,
        meta: {
          id: 81,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 72,
        meta: {
          id: 82,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 72,
        meta: {
          id: 83,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 74,
        meta: {
          id: 84,
          fileName: 'Filename 1 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      ///15 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 68,
        meta: {
          id: 85,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 71,
        meta: {
          id: 86,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 71,
        meta: {
          id: 87,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 71,
        meta: {
          id: 88,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 71,
        meta: {
          id: 89,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 73,
        meta: {
          id: 90,
          fileName: 'Filename 2 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      ///16 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 67,
        meta: {
          id: 91,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 70,
        meta: {
          id: 92,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 70,
        meta: {
          id: 93,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 70,
        meta: {
          id: 94,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 70,
        meta: {
          id: 95,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 72,
        meta: {
          id: 96,
          fileName: 'Filename 2 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      ///17 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 66,
        meta: {
          id: 97,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 69,
        meta: {
          id: 98,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 69,
        meta: {
          id: 99,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 69,
        meta: {
          id: 100,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 69,
        meta: {
          id: 101,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 71,
        meta: {
          id: 102,
          fileName: 'Filename 3 - Sheet Name 1',
          axis: 'col_g',
        },
        groups: [],
      },
      ///18 dataset////
      {
        x: '2024-11-09 11:50:19.670000',
        y: 65,
        meta: {
          id: 103,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-10 11:50:19.670000',
        y: 68,
        meta: {
          id: 104,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-11 11:50:19.670000',
        y: 68,
        meta: {
          id: 105,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-12 11:50:19.670000',
        y: 68,
        meta: {
          id: 106,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-13 11:50:19.670000',
        y: 68,
        meta: {
          id: 107,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
      {
        x: '2024-11-14 11:50:19.670000',
        y: 70,
        meta: {
          id: 108,
          fileName: 'Filename 3 - Sheet Name 2',
          axis: 'col_g',
        },
        groups: [],
      },
    ],
    domain: {
      x: ['2022-01-25 11:17:29.730000', '2022-01-25 11:17:29.730000'],
      y: [-770.45, 1106.4],
    },
    group_by: null,
    label_by: [],
    x_column_name: 'datetime',
    y_column_name: ['col_e, col_f, col_g'],
    x_tick_count: 8,
    y_tick_count: 5,
    statistics: [
      {
        label: 'x datetime',
        data: {
          'Mean x': 1.573,
          'Stdev x': 1.573,
          'CV x': 1.573,
        },
      },
      {
        label: 'y: col_e',
        data: [
          {
            label: 'Filename 1 - Sheet Name 1',
            data: {
              N: 1.573,
              'Mean y': 1.573,
              'Stdev y': 1.573,
              CV: 1.573,
            },
          },
          {
            label: 'Filename 1 - Sheet Name 1',
            data: {
              N: 1.573,
              'Mean y': 1.573,
              'Stdev y': 1.573,
              CV: 1.573,
            },
          },
          {
            label: 'Filename 2 - Sheet Name 1',
            data: {
              N: 1.573,
              'Mean y': 1.573,
              'Stdev y': 1.573,
              CV: 1.573,
            },
          },
          {
            label: 'Filename 2 - Sheet Name 2',
            data: {
              N: 1.573,
              'Mean y': 1.573,
              'Stdev y': 1.573,
              CV: 1.573,
            },
          },
          {
            label: 'Filename 3 - Sheet Name 1',
            data: {
              N: 1.573,
              'Mean y': 1.573,
              'Stdev y': 1.573,
              CV: 1.573,
            },
          },
          {
            label: 'Filename 3 - Sheet Name 2',
            data: {
              N: 1.573,
              'Mean y': 1.573,
              'Stdev y': 1.573,
              CV: 1.573,
            },
          },
        ],
      },
      {
        label: 'y: col_e',
        data: {
          'Fit equation': 1.573,
          RSME: 1.573,
          'CV fit': 1.573,
          R2: 1.774,
          'N outliers': 2,
        },
      },
    ],
  },
};
