import styled from 'styled-components';

import { Chip, ChipProps } from '@mui/material/';

const ModalHeaderChip = (props: ChipProps) => (
  <ChipStyled {...props} size="small" />
);

const ChipStyled = styled(Chip)`
  margin-left: 5px;
  font-size: 12px;

  .MuiChip-label {
    color: #767676;
    line-height: 22px;
  }
`;

export default ModalHeaderChip;
