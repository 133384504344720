import { IconProps } from './types';

const DOEChartIcon = ({
  color = '#000',
  size = { width: 81, height: 65 },
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 81 65"
    fill="none"
  >
    <path
      d="M18 45V54H45V45H18ZM18 45V36M18 36H40.5M18 36V26.5M40.5 36V44.5M40.5 36H50V26.5H36M18 26.5V9H27.25V18M18 26.5H36M36 26.5V18H27.25M18.5 18H27.25M55.5 9H59.75M64 9H59.75M59.75 9V20M59.75 20H63.5V30.5M59.75 20H56.5V30.5M56.5 30.5V41H60M56.5 30.5H63.5M63.5 30.5V41H60M60 41V54H55H64.5"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

export default DOEChartIcon;
