import { IconProps } from './types';

const ControlTypesIcon = ({
  color = '#000',
  size = { width: 22, height: 23 },
}: IconProps) => (
  <svg
    width={size.width}
    height={size.height}
    viewBox="0 0 23 21"
    version="1.1"
  >
    <g id="Main" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Nav"
        transform="translate(-339.000000, -553.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g
          id="Navs/Main-Expanded-Copy"
          transform="translate(323.000000, 66.000000)"
        >
          <g id="Group-3">
            <g id="Group" transform="translate(0.000000, 8.000000)">
              <g
                id="Lists/Default/Default/★-+-Text-Copy-13"
                transform="translate(0.000000, 465.500000)"
              >
                <g
                  id="icons/ic/grade-default"
                  transform="translate(16.000000, 14.000000)"
                >
                  <path
                    d="M6,20 L0.983054174,20 L0.98263321,18 L6,18 C7.43649679,18 8.46601883,15.3880371 9.14584247,10.2465047 L9.26946382,9.25634383 C9.30725613,8.93816717 9.3454667,8.60295314 9.38622021,8.23392517 L9.70486886,5.29329024 C10.1738279,1.136782 10.4798073,0.0500107961 11.8968741,0.00171127445 L12,0 C13.4356814,0 13.7836468,0.9435215 14.2291081,4.72016658 L14.6537643,8.59230318 C14.6930586,8.94065259 14.7305182,9.26078025 14.7682678,9.56946271 L14.8061927,9.87495146 C15.4693965,15.1368929 16.4809349,17.8712265 17.8980844,17.995559 L18,18 L23,18 L23,20 L18,20 C14.9117627,20 13.6492666,16.6895463 12.8218914,10.1250485 L12.7447027,9.49398816 L12.3004285,5.45879946 C12.2157494,4.72475043 12.1393486,4.14025067 12.062506,3.6562765 L12,3.284 L11.9704791,3.4548707 C11.8925529,3.91573237 11.8163487,4.47201513 11.7328792,5.17424328 L11.3333864,8.8186678 C11.2801699,9.29038201 11.2301245,9.71234764 11.1781086,10.1250485 L11.078679,10.8823261 C10.2658405,16.8203891 9.01925908,19.8740174 6.17956569,19.9961821 L6,20 Z"
                    id="control-types"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ControlTypesIcon;
