import { IRowNode } from 'ag-grid-community';

import axios from 'config/AxiosConfig';
import { API_ENDPOINTS, ApiModelName, ModelName } from 'utils/enum';
import {
  capitalizedFirstLetter,
  getCanceledModelName,
  getNormalizeModelName,
} from 'utils/utils';
import * as actionTypes from './RelatedActionsTypes';

interface fetchHeaderTableProps {
  params?: Record<string, any>;
  modelApi: string;
}

export const fetchOrientingTable =
  ({ params = {}, modelApi }: fetchHeaderTableProps) =>
  async (dispatch: any) => {
    dispatch({ type: actionTypes.FETCH_ORIENTING_TABLE_START });
    try {
      const { data } = await axios.post(
        `/${API_ENDPOINTS.models.getTable}/${capitalizedFirstLetter(modelApi)}`,
        params,
      );

      return dispatch({
        type: actionTypes.FETCH_ORIENTING_TABLE_SUCCESS,
        payload: data.rows,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_ORIENTING_TABLE_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  };

interface fetchRelatedTableProps {
  sourceModel: ModelName;
  destModel: ModelName;
  params: Record<string, any>;
  fetchCanceled: boolean;
  shouldUpdateState?: boolean;
}

export const fetchRelatedTable =
  ({
    sourceModel,
    destModel,
    params = {},
    fetchCanceled,
    shouldUpdateState = true,
  }: fetchRelatedTableProps) =>
  async (dispatch: any) => {
    const normalizedSourceModel = fetchCanceled
      ? getCanceledModelName(sourceModel)
      : capitalizedFirstLetter(sourceModel);
    const normalizedDestModel = fetchCanceled
      ? getCanceledModelName(destModel)
      : capitalizedFirstLetter(destModel);

    dispatch({
      type: actionTypes.FETCH_RELATED_TABLE_START,
      payload: { destModel },
    });

    try {
      const { data } = await axios.post(
        `/${API_ENDPOINTS.models.getRelatedTable}/${normalizedSourceModel}/${normalizedDestModel}`,
        params,
      );
      data.lastRow = data.rows?.length > 0 ? data.lastRow + 1 : 0;

      if (data?.error) {
        return dispatch({
          type: actionTypes.FETCH_RELATED_TABLE_ERROR,
          payload: { destModel, error: data.error },
        });
      }
      return dispatch({
        type: actionTypes.FETCH_RELATED_TABLE_SUCCESS,
        payload: {
          destModel,
          data,
          shouldUpdateState,
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_RELATED_TABLE_ERROR,
        payload: { destModel, error: err.message },
      });
      console.error(err.message);
    }
  };

export const setRelatedTableSelectedNodes = (
  destModel: ModelName | ApiModelName,
  nodes: IRowNode[],
) => ({
  type: `SET_RELATED_TABLE_SELECTED_NODES`,
  payload: { destModel: getNormalizeModelName(destModel), nodes },
});
