import { IconProps } from './types';

const LeftPanelIcon = ({
  color = '#000',
  size = { width: 22, height: 18 },
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 22 18"
    fill="none"
  >
    <path
      d="M1 2V16C1 16.5523 1.44772 17 2 17H20C20.5523 17 21 16.5523 21 16V2C21 1.44772 20.5523 1 20 1H2C1.44772 1 1 1.44772 1 2Z"
      fill={color}
      stroke={color}
      strokeWidth={1}
    />
    <path d="M3 3V15H11V3H3Z" strokeWidth={1} fill="white" stroke="white" />
  </svg>
);

export default LeftPanelIcon;
