import { IconProps } from './types';

const ScatterChartIcon = ({
  color = '#000',
  size = { width: 81, height: 65 },
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size.width}
    height={size.height}
    viewBox="0 0 81 65"
    fill="none"
  >
    <path d="M17 25.5H21" stroke={color} />
    <path d="M17 24H21" stroke={color} strokeWidth="2" />
    <path d="M17 26H21" stroke={color} />
    <path d="M17 24H21" stroke={color} strokeWidth="2" />
    <path d="M25 26H29" stroke={color} />
    <path d="M25 24H29" stroke={color} strokeWidth="2" />
    <path d="M33 26H37" stroke={color} />
    <path d="M33 24H37" stroke={color} strokeWidth="2" />
    <path d="M41 26H45" stroke={color} />
    <path d="M41 24H45" stroke={color} strokeWidth="2" />
    <path d="M49 26H53" stroke={color} />
    <path d="M49 24H53" stroke={color} strokeWidth="2" />
    <path d="M57 26H61" stroke={color} />
    <path d="M57 24H61" stroke={color} strokeWidth="2" />
    <path d="M17 47H21" stroke={color} />
    <path d="M17 46H21" stroke={color} />
    <path d="M17 48H21" stroke={color} />
    <path d="M25 47H29" stroke={color} />
    <path d="M25 46H29" stroke={color} />
    <path d="M25 48H29" stroke={color} />
    <path d="M33 47H37" stroke={color} />
    <path d="M33 46H37" stroke={color} />
    <path d="M33 48H37" stroke={color} />
    <path d="M41 47H45" stroke={color} />
    <path d="M41 46H45" stroke={color} />
    <path d="M41 48H45" stroke={color} />
    <path d="M49 47H53" stroke={color} />
    <path d="M49 46H53" stroke={color} />
    <path d="M49 48H53" stroke={color} />
    <path d="M57 47H61" stroke={color} />
    <path d="M57 46H61" stroke={color} />
    <path d="M57 48H61" stroke={color} />
    <circle cx="22" cy="30" r="2" fill={color} />
    <circle cx="31" cy="38" r="2" fill={color} />
    <circle cx="41" cy="31" r="2" fill={color} />
    <circle cx="60" cy="35" r="2" fill={color} />
    <circle cx="51" cy="15" r="2" fill={color} />
    <circle cx="51" cy="15" r="5.25" stroke={color} strokeWidth="1.5" />
  </svg>
);

export default ScatterChartIcon;
