import { AddOutlined } from '@mui/icons-material';

interface AddButtonProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AddButton = ({ onClick }: AddButtonProps) => (
  <div
    className="add-button"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
  >
    <AddOutlined
      style={{
        padding: 3,
        border: '1px solid #c9c9c9',
        borderRadius: 4,
        fontSize: 28,
        marginRight: 10,
        color: 'rgba(0,0,0,0.7)',
      }}
    />
  </div>
);

export default AddButton;
