import { createTheme } from '@mui/material//styles/';
import colors from '../patterns/colors';

import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
export const materialDefaultTheme = createTheme({
  typography: {
    fontFamily: `"Proxima Nova", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.green,
    },
    warning: {
      main: colors.orange,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
        color: 'inherit',
      },
      styleOverrides: {
        sizeSmall: {
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
});
